import React, {useEffect, useState,useContext} from 'react'

import { Button, Form,Row, Col} from "react-bootstrap";
import VideoContext from '../../context/video/videoContext';
import UserContext from '../../context/user/userContext';
import SessionContext from '../../context/session/sessionContext';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';

const VideoForm = ({video, setShowForm }) => {


    const videoContext = useContext(VideoContext);
    const {addVideo,updateVideo,videos} = videoContext;

    const [videoData, setVideoData] = useState({
        
        title : '',
        vimeoID: '',
        session: null,
        delegate: null,
        client: null,
        sessions: [],
        clients: [],
        delegates: []
     })

    const {title, vimeoID, sessions , clients, delegates } = videoData || {}; 

    const [clientValue, setClientValue] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);

    const [delegateValue, setDelegateValue] = useState(null);
    const [delegateOptions, setDelegateOptions] = useState([]);

    const [sessionValue, setSessionValue] = useState(null);
    const [sessionOptions, setSessionOptions] = useState([]);

    const [delegatesList, setDelegatesList] = useState([]);
    const [clientsList, setClientsList] = useState([]);

    const [sessionsList, setSessionsList] = useState([]);

    const userContext = useContext(UserContext);
    const {getUsers, users} = userContext;

    const sessionContext = useContext(SessionContext);
    const {getSessions, sessions:OriginalSessions} = sessionContext;

    useEffect( () =>{
        if(video){
            setVideoData(video);
        }

        if(users===null || !users.length){
            getUsers();
        }
        if(OriginalSessions===null || !OriginalSessions.length){
            getSessions();
        }
        // eslint-disable-next-line
    },[])

    useEffect( () =>{

        if(users && users.length){
            setClientOptions( users.filter(user => user.role===3).map(user => ({value: user._id, label: `${user.name} ${user.lastName}`})));
            setDelegateOptions( users.filter(user => user.role===4).map(user => ({value: user._id, label: `${user.name} ${user.lastName}`})));

            let list =users.filter(user => user.role===4);
            list.forEach(el=>{
                if(typeof el === 'object'){
                    el.fullName= `${el.name} ${el.lastName}`;
                }
             })
            setDelegatesList(list);

            list = users.filter(user => user.role===3);
            list.forEach(el=>{
                if(typeof el === 'object'){
                    el.fullName= `${el.name} ${el.lastName}`;
                }
             });
             setClientsList(list);

            list = OriginalSessions.map(session => ({ _id : session._id, title: session.title}));
            setSessionsList(list);
        }
   
    
    },[users])

    useEffect( () =>{

        if(OriginalSessions && OriginalSessions.length){
            setSessionOptions(OriginalSessions.map(session => ({value: session._id, label:`${session.title}: ${session.description}`})));          
        }
    
    },[OriginalSessions])

    useEffect( () =>{

        if(video){
            setClientValue({value: video.client?._id, label: `${video.client?.name} ${video.client?.lastName}`});
            setDelegateValue({value: video.delegate?._id, label: `${video.delegate?.name} ${video.delegate?.lastName} - ${video.delegate?.email}`});
        }
        if(video && video.session){
            setSessionValue({value:video.session._id,label:`${video.session.title} ${video.session.description}`});
        }

        if(clients && clients.length){
            clients.forEach(el=>{
                if(typeof el === 'object'){
                    el.fullName= `${el.name} ${el.lastName}`;
                }
             })
        }
        if(delegates && delegates.length){
            delegates.forEach(el=>{
                if(typeof el === 'object'){
                    el.fullName= `${el.name} ${el.lastName} - ${el.email}`;
                }
             })
        }
    
    },[video,videos,clients,delegates,videoContext])

    const onChange = e => {
        setVideoData({...videoData, [e.target.name] :e.target.value });
    }

    const clearForm = () => {
        if(video){
            setVideoData(video);
        }
        else{
            setVideoData({
                title : '',
                vimeoID: '',
                session: null,
                delegate: null,
                client: null,
                sessions: [],
                delegates: [],
                clients: []
            });
        }
    }

    const onSubmit = e =>{
        e.preventDefault();       

        if(video){
            updateVideo(videoData);
        }
        else{
            addVideo(videoData);
            clearForm();
        }

            if(setShowForm !== null){
                setShowForm(false);
            }
      
    }

    const handleSelectClient = (selectedOption) => {
        setClientValue(selectedOption);
        setVideoData({...videoData, client:selectedOption.value });
    }

    const handleSelectDelegate = (selectedOption) => {
        setDelegateValue(selectedOption);
        setVideoData({...videoData, delegate:selectedOption.value });
    }

    const handleSelectSession = (selectedOption) => {
        setSessionValue(selectedOption);
        setVideoData({...videoData, session:selectedOption.value });
    }


    const onDelegatesSelect = (delegates)=>{
        setVideoData({...videoData, delegates: delegates });
    }
    const onClientsSelect = (clients)=>{
        setVideoData({...videoData, clients: clients });
    }

    const onSessionsSelect = (sessions)=>{
        setVideoData({...videoData, sessions: sessions });
    }

    return(
        <Form onSubmit={onSubmit}>
            <Row className='py-2'>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title" name="title" minLength="2" value ={title} onChange={onChange} required/>
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>VimeoID</Form.Label>
                        <Form.Control type="text" placeholder="Vimeo ID" name="vimeoID" minLength="5" value ={vimeoID} onChange={onChange} required/>
                    </Form.Group>
                </Col>
               
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <Form.Group  className="mb-3">
                    <Form.Label>Clients</Form.Label>
                    <Multiselect
                        options={clientsList} // Options to display in the dropdown
                        selectedValues={clients} // Preselected value to persist in dropdown
                        onSelect={onClientsSelect} // Function will trigger on select event
                        onRemove={onClientsSelect} // Function will trigger on remove event
                        displayValue="fullName" // Property name to display in the dropdown options
                    />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group  className="mb-3">
                        <Form.Label>Delegates</Form.Label>
                        <Multiselect
                            options={delegatesList} // Options to display in the dropdown
                            selectedValues={delegates} // Preselected value to persist in dropdown
                            onSelect={onDelegatesSelect} // Function will trigger on select event
                            onRemove={onDelegatesSelect} // Function will trigger on remove event
                            displayValue="fullName" // Property name to display in the dropdown options
                        />
                    </Form.Group>
                </Col>           
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3 form-dropdown">
                        <Form.Label>Sessions</Form.Label>
                        <Multiselect
                            options={sessionsList} // Options to display in the dropdown
                            selectedValues={sessions} // Preselected value to persist in dropdown
                            onSelect={onSessionsSelect} // Function will trigger on select event
                            onRemove={onSessionsSelect} // Function will trigger on remove event
                            displayValue="title" // Property name to display in the dropdown options
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group className="mb-3 d-flex">
                <Button variant="primary" type="submit">
                    Submit
                </Button>
                &nbsp;
                <Button variant="primary" onClick={()=>setShowForm(false)}>
                    Close
                </Button>
                
            </Form.Group>
        </Form>
    )
}

export default VideoForm;