import React,{useReducer} from "react";
import axios from "axios";
import sessionContext from "./sessionContext";
import sessionReducer from './sessionReducer';

import {
    ADD_SESSION,
    DELETE_SESSION,
    SET_CURRENT_SESSION,
    CLEAR_CURRENT_SESSION,
    UPDATE_SESSION,
    FILTER_SESSIONS,
    SESSION_ERROR,
    GET_SESSIONS,
    FILTER_SESSION_STATUS,
    CLEAR_STATUS_FILTER,
    SET_VIEW_FILTERED,
    CLEAR_VIEW_FILTERED,
    APPLY_FILTERS,
    VIEW_ALL_SESSIONS,
    VIEW_OWN_SESSIONS,
    CLONE_SESSION,
    CLEAR_SESSIONS

} from '../types';


const SessionState = props => {
    const initialState ={
        sessions:null,
        current: null,
        viewState: 'all',
        client: null,
        filtered: null,
        xFiltered: null,
        error: null,
        loading: false,
        viewFeedback: false,
        currentStatus: 'all',
        statuses: [{value:'requested',label:'Requested'},{value:'draft',label:'Draft'},{value:'pending',label:'Pending approval'}, {value:'approved',label:'Approved'},{value:'feedbackPending',label:'Feedback pending'}, {value:'feedbackApproved',label:'Feedback approved'}],
        currentFeedbackStatus: 'all',
        sessionStatuses: ['approved'],
        sessionStatusesAdmin: ['requested','draft','pending', 'approved'],
        sessionStatusesClient: ['requested','pending', 'approved'],
        feedbackStatuses: ['feedbackPending', 'feedbackApproved'],
        viewFiltered: false,
        filters: []
    }

    const[state,dispatch] = useReducer(sessionReducer, initialState);

    const getSessions = async () => {
        try {
            const res = await axios.get('/api/sessions');
            dispatch({type: GET_SESSIONS, payload: res.data});
        } catch (err) {
            dispatch({type: SESSION_ERROR, payload: err.response.msg})
        }
    }

    const getSession = async (id) => {

        try {
            const res = await axios.get(`/api/sessions/${id}`);
            dispatch({type: SET_CURRENT_SESSION, payload: res.data});
        } catch (err) {
            dispatch({type: SESSION_ERROR, payload: err.response.msg})
        }
    }

    const addSession = async session => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/sessions', session, config);
            dispatch({type: ADD_SESSION, payload: res.data});

        } catch (err) {
            dispatch({type: SESSION_ERROR, payload: err.response.msg})
        }
    }

    const updateSession = async session => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/sessions/${session._id}`, session, config);
            dispatch({type: UPDATE_SESSION, payload: res.data});

        } catch (err) {

            dispatch({type: SESSION_ERROR, payload: err.response.msg});
        }
    }

    const updateSessionAndEmail = async session => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/sessions/update/${session._id}`, session, config);
            dispatch({type: UPDATE_SESSION, payload: res.data});
        } catch (err) {
            dispatch({type: SESSION_ERROR, payload: err.response.msg});
        }
    }

    const updateSessionStatus = async session => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/sessions/status/${session._id}`, session, config);
            dispatch({type: UPDATE_SESSION, payload: res.data});
        } catch (err) {
            dispatch({type: SESSION_ERROR, payload: err.response.msg});
        }
    }

    const deleteSession = async id => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.delete(`/api/sessions/${id}`, config);
            dispatch({type: DELETE_SESSION, payload: id});
        } catch (err) {
            dispatch({type: SESSION_ERROR, payload: err.response.msg});
        }
    }

    const cloneSession = async id => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post(`/api/sessions/clone/${id}`, config);
            dispatch({type: CLONE_SESSION, payload: res.data});
        } catch (err) {
            dispatch({type: SESSION_ERROR, payload: err.response.msg})
        }
    }

    // set current contact
    const setCurrent = session => {
        dispatch({type: SET_CURRENT_SESSION, payload: session});
    }
    
    // set current contact
    const clearSession = () => {
        dispatch({type: CLEAR_CURRENT_SESSION});
    }

    // clear all sessions on log out
    const clearAllSessions = () => {
        dispatch({type: CLEAR_SESSIONS});
    }

    const setStatus = (newStatus) =>{
        dispatch({type:FILTER_SESSION_STATUS, payload: newStatus});
    }

    const clearStatus = () =>{
        dispatch({type: CLEAR_STATUS_FILTER});
        dispatch({type: APPLY_FILTERS});
    }

    const setViewFiltered = () =>{
        dispatch({type: SET_VIEW_FILTERED});
    }

    const clearViewFiltered = () =>{
        dispatch({type: CLEAR_VIEW_FILTERED});
    }

    const filterSessions = text => {
        dispatch({type: FILTER_SESSIONS, payload: text});
    }

    const viewOwnSessions = () => {
        dispatch({type: VIEW_OWN_SESSIONS});
    }

    const viewAllSessions = () => {
        dispatch({type: VIEW_ALL_SESSIONS});
    }

    return (
        <sessionContext.Provider value ={{
            sessions: state.sessions,
            current: state.current,
            filtered: state.filtered,
            error: state.error,
            loading: state.loading,
            currentStatus: state.currentStatus,
            statuses: state.statuses,
            sessionStatuses: state.sessionStatuses,
            feedbackStatuses: state.feedbackStatuses,
            sessionStatusesClient: state.sessionStatusesClient,
            sessionStatusesAdmin: state.sessionStatusesAdmin,
            xFiltered: state.xFiltered,
            viewFeedback: state.viewFeedback,
            filters: state.filters,
            getSession,
            getSessions,
            setCurrent,
            clearSession,
            addSession,
            updateSession,
            setStatus,
            clearStatus,
            setViewFiltered,
            clearViewFiltered,
            filterSessions,
            deleteSession,
            cloneSession,
            updateSessionAndEmail,
            clearAllSessions,
            updateSessionStatus
            }}>
            {props.children}
        </sessionContext.Provider>
    )
}


export default SessionState;