import React,{useReducer} from "react";
import axios from "axios";
import serviceContext from "./serviceContext";
import serviceReducer from './serviceReducer';

import {

    ADD_SERVICE,
    GET_SERVICES,
    UPDATE_SERVICE,
    SERVICE_ERROR,
    DELETE_SERVICE

} from '../types';


const ServiceState = props => {
    const initialState ={
        services:[],
    }

    const[state,dispatch] = useReducer(serviceReducer, initialState);



        const getServices = async () => {

            try {
                const res = await axios.get('/api/service/');
                dispatch({type: GET_SERVICES, payload: res.data});
            } catch (err) {
                dispatch({type: SERVICE_ERROR, payload: err.response.msg})
            }
        }

        const updateService = async service => {

            const config = {
                Headers: {
                    'Content-Type': 'application/json'
                }
            }
    
            try {
                const res = await axios.put(`/api/service/${service._id}`, service, config);
                dispatch({type: UPDATE_SERVICE, payload: res.data});
    
            } catch (err) {
    
                dispatch({type: SERVICE_ERROR, payload: err.response.msg})
            }
        }

         // Add service
        const addService = async service => {

            const config = {
                Headers: {
                    'Content-Type': 'application/json'
                }
            }

            try {
                const res = await axios.post('/api/service', service, config);
                dispatch({type: ADD_SERVICE, payload: res.data});

            } catch (err) {
                dispatch({type: SERVICE_ERROR, payload: err.response.msg})
            }
        }

        const deleteService = async id => {
            try {
                await axios.delete(`/api/service/${id}`);
                dispatch({type: DELETE_SERVICE, payload: id});
    
            } catch (err) {
                dispatch({type: SERVICE_ERROR, payload: err.response.msg})
            }
        }

        return (
            <serviceContext.Provider value ={{
                services: state.services,
                addService,
                getServices,
                updateService,
                deleteService
                }}>
                {props.children}
            </serviceContext.Provider>
        )
    }
    
    
    export default ServiceState;