import React, {useEffect, useState,useContext} from 'react'
import { Button, Form,Row, Col} from "react-bootstrap";
import VideoContext from '../../context/video/videoContext';

const VideoForm = ({newVideo, video, session, setShowForm }) => {

    const videoContext = useContext(VideoContext);
    const {addVideo,updateVideo} = videoContext;
    
    const [videoData, setVideoData] = useState({    
        title : '',
        vimeoID: '',
        session: session._id
     })

    const {title, vimeoID} = videoData;

    useEffect(() =>{
        if(!newVideo){
            setVideoData(video);
        }
        // eslint-disable-next-line
    },[])

    const onSubmit = e =>{
        e.preventDefault();
       
        if(newVideo){
            addVideo(videoData);
            clearForm();
            if(setShowForm !== null){
                setShowForm(false);
            }
        }
        else{
            updateVideo(videoData);
            if(setShowForm !== null){
                setShowForm(false);
            }
        }
    }

    const onChange = e => {
        setVideoData({...videoData, [e.target.name] :e.target.value });
    }

    const clearForm = () => {
        setVideoData({
            title : '',
            vimeoID: '',
            session: session._id,
            client: session.client || null,
            delegate: session.delegate || null
        })
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title" name="title" value ={title} onChange={onChange} className="w-50"/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>VimeoID</Form.Label>
                        <Form.Control type="text" placeholder="Vimeo ID" name="vimeoID" value ={vimeoID} onChange={onChange} className="w-50"/>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="mb-3">
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form.Group>
        </Form>
    )
}

export default VideoForm