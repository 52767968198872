import React, {useContext, useEffect, useState,useRef} from 'react';
import {Button, Row, Col} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';

import BookingContext from '../../context/booking/bookingContext';

import BookingItem from './BookingItem';

const Bookings = ({isAdmin}) => {

    const bookingContext = useContext(BookingContext);

    const {bookings, getBookings} = bookingContext;

    let navigate = useNavigate();

    const newBooking = (e)=>{
        e.preventDefault();
        //clearBooking();
        navigate('/bookings/new');
    }


    useEffect(() =>{
        //authContext.loadUser();

        if(bookings=== null || !bookings.length){
            getBookings();
        } 
        // eslint-disable-next-line
    },[])



  return (
    <>
        <Row className='pb-1 border-bottom mb-4'>
            <Col xs="6">
                <h1>Bookings</h1>
            </Col>
            <Col xs="6"  className='d-flex align-items-end justify-content-end'>
                { isAdmin && <Button onClick={newBooking} >Add Booking</Button> }
            </Col>
        </Row>

        <Row className='pb-1 mb-5'>

                {bookings && bookings.length ?
                    bookings.map(booking => 
                        <BookingItem key={booking._id} booking={booking}/>
                    )
                    :
                    <div>
                        No bookings found
                    </div>
                }
        </Row>
        
    </>
  )

}

export default Bookings