import React,{useContext, useEffect, useState} from 'react';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import { useNavigate } from 'react-router-dom';
import Alerts from '../layout/Alerts';

import { Container, Button, Form,Row, Col} from "react-bootstrap";
import logo from '../../img/logo.svg';
import logoWhite from '../../img/logo-white.svg';
import loginBg from '../../img/login-bg.jpg';

const Login = () => {

    const alertContext = useContext(AlertContext);

    const {setAlert} = alertContext;
    const authContext = useContext(AuthContext);
    const{login, error, isAuthenticated, clearErrors} = authContext;

    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated){
            navigate('/');
        }
        if(error === 'Invalid Credentials'){
            //do something
            setAlert(error, 'danger');
            clearErrors();
            //console.log('Invalid Credentials error handler');
            
        }
        // eslint-disable-next-line
    },[error,isAuthenticated])

    const [user, setUser] = useState({

        email: '',
        password: '',

    });

    const {email, password} = user;

    const onChange = e => setUser({...user, [e.target.name]: e.target.value});

    const onSubmit = e =>{
        e.preventDefault();
        if(email === '' || password=== ''){
            setAlert('Please fill in all fields','danger');
        }else{
            login({email,password});
        }
    }

    // const bgStyle = {
    //     backgroundImage: `url(${loginBg})`,
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: 'cover',
    //     position: 'fixed',
    //     top:0,
    //     right:0,
    //     width: '55vw',
    //     height: '100vh',
    // }

    return (
    <>
        <div className='d-none d-md-block login-background'></div>
        <Container>

            <h1 className='h1 mt-5 font-size-0'>
                <img src={logo} className='logo' /> Bladonmore portal
            </h1>
        </Container>
        <Container>
            <Row>
                <Col md="5" className='d-flex flex-column justify-content-center h-100 form-container pe-5'>
                    <div className='alerts'>
                        <Alerts />
                    </div>
                    <h2 className='mb-4 login-h2'>World-class communications learning</h2>
                    <p>
                        All your coaching needs, in one hub. This is a password-protected area for Bladonmore coaching clients to access learning materials, review films of practice sessions and receive feedback.
                    </p>

                    <Form onSubmit={onSubmit}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">                                
                                    <Form.Label  htmlFor='email' hidden>Email Address</Form.Label>
                                    <Form.Control type='text' name='email' values={email} onChange={onChange} className="login-input" placeholder='Email or Username'/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor='password' hidden>Password</Form.Label> 
                                    <Form.Control type='password' name='password' values={password} onChange={onChange} className="login-input" placeholder='Password'/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group >
                            <Button variant="primary" type="submit" className="w-100 btn btn-yellow login-button">Log in</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default Login