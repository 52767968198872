import React,{useContext, useState} from 'react';
import PropTypes from 'prop-types';

import FileContext from '../../context/file/fileContext';
import moment from "moment";
import {Col, Row} from "react-bootstrap";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import FileEditForm from './FileEditForm';

const FileItem = ({file, isAdmin}) => {

    const {name, path, url,date} = file;
    const fileContext = useContext(FileContext);
    const {deleteFile} = fileContext;

    const [showForm,setShowForm] = useState(false);

    const onDelete= e => {
        e.preventDefault();
        deleteFile(file._id);

        return;
    }

    const onEdit= e => {
        e.preventDefault();
        setShowForm(!showForm);
        return;
    }
   

    const getExt = (filename) => {
        let ext = '';
        let chunks = filename.split('.');
        if(chunks && chunks.length >1){
            ext = chunks[chunks.length-1];
        }
        return ext.toUpperCase();
    }
  
        return (
            <>
          
            <Row className="row-border table-row position-relative">
                <Col xs={2} className="py-2">
                    {moment(date).format('DD MMMM YYYY')}
                </Col>
                <a href={url} className="col-10 py-2 d-flex" >
                <Col md={2} >
                    {getExt(name)}
                </Col>
                <Col md={8} className='pe-4'>
                    <b>{name}</b>
                </Col>
                </a>
            {isAdmin &&
                <div className="edit-btns text-end">
                    <button className='btn btn-edit-inline px-2' onClick={(e) => onEdit(e)} data-tooltip-id="edit-tooltip" data-tooltip-content="Edit File"> Edit file</button>
                    <Tooltip id="edit-tooltip" />
                    

                    <button className='btn btn-del px-2 m-2 d-inline-block'  onClick={(e) => { if (window.confirm('Are you sure you wish to delete this file?')) onDelete(e)}} data-tooltip-id="del-tooltip" data-tooltip-content="Delete File"> Delete File</button>
                    <Tooltip id="del-tooltip" />
                </div>
                
            }
        </Row>
        {isAdmin && showForm && <FileEditForm file={file} setShowForm={setShowForm}/>}
        </>
        )
    
}

FileItem.propTypes = {
    file: PropTypes.object.isRequired,
}

export default FileItem