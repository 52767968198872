import React,{useContext, useState} from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../context/user/userContext';

import {useNavigate} from 'react-router-dom';

import {Row, Col} from "react-bootstrap";
import ROLES from '../../utils/roles.js';
import UserForm from './UserForm';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import '../../styles/users.scss';

const UserItem = ({user,isSuperAdmin}) => {

    let navigate = useNavigate(); 
    const userContext = useContext(UserContext);
    const {setCurrent, updateUser, clearCurrent, deleteUser, softDeleteUser } =userContext;
    const {name,lastName,email,role, organisation,image} = user;
    const title = organisation?.title || 'no organisation';

    const onDelete = (e)=>{

        e.preventDefault();
        e.stopPropagation();
        let userData = {_id : user._id, deleted :true};
        updateUser(userData);
        softDeleteUser(user._id);
    }

    const viewUser = (e)=>{

        e.preventDefault();
        setCurrent(user);
        navigate('/user');
    }

    const editUser = (e) =>{
        e.preventDefault();
        setCurrent(user);
        navigate('/user-edit');
    }

    const [showForm, setShowForm] = useState(false);
   
    const toggleForm= () =>{
        setShowForm(!showForm);
    }
    const closeForm = () =>{
        setShowForm(false);
    }

    return (

        <Col xs="12" className={`py-2 border-bottom-bold${showForm? '': ' user-inner--hover'}`}>
            <Row onClick={toggleForm}>
                <div className='col-12 col-md-3'>
                    {name}{' '}{lastName}
                </div>
                <div className='col-12 col-md-3'>
                    {email}
                </div>
                <div className='col-12 col-md-3'>
                    {title}
                </div>
                <div className='col-12 col-md-1'>
                    {ROLES[role]}
                </div>
                
                <div className='col-12 col-md-2 text-end'>
                    {/* {role <=2 && 
                        (<>
                            <button className='btn btn-star px-2 m-2 d-inline-block' data-tooltip-id="star-tooltip" data-tooltip-content="Admin user"> Admin</button>
                            <Tooltip id="star-tooltip" />
                        </>)/}*/
                    }
                    {(role >2 || isSuperAdmin )&& 
                        (<>
                            <button className='btn btn-del px-2 m-2 d-inline-block' onClick={(e) => { if (window.confirm('Are you sure you wish to delete this user?')) onDelete(e)}} data-tooltip-id="del-tooltip" data-tooltip-content="Delete User"> Delete User</button>
                            <Tooltip id="del-tooltip" />
                        </>)
                    }
                    <span className={showForm ? 'chevron-down align-top' : 'chevron-up align-top'}></span>
                </div>
            </Row>
        
            {showForm && (            
                (image && image.url) ?
                    <Row>
                        <div className='col-12 col-md-10 py-4'>
                            <UserForm user={user} closeForm={closeForm} />
                        </div>
                        <div className='col-6 col-md-2 py-4'><img src={image.url} />
                        </div>
                    </Row>
                    :
                    <Row>
                        <div className='col-12 py-4'>
                            <UserForm user={user} closeForm={closeForm} />
                        </div>
                    </Row>                
                )
            }
        </Col>
    )
}

UserItem.propTypes = {
    user: PropTypes.object.isRequired,
}

export default UserItem