import {
    ADD_USER,
    DELETE_USER,
    SET_CURRENT_USER,
    CLEAR_USER,
    UPDATE_USER,
    FILTER_USERS,
    CLEAR_USERS_FILTER,
    USER_ERROR,
    GET_USERS,
    FILTER_USER_ROLES,
    CLEAR_ROLES_FILTER,
    CLEAR_ERRORS
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                preFiltered: action.payload,
                submitted: false,
                loading: false
            }
        
        case SET_CURRENT_USER:
            return {
                ...state,
                current: action.payload,
                submitted: false,
                userLoading: false
            }

        case FILTER_USERS:
            return {
                ...state,
                filtered: state.preFiltered.filter(user => {
                    const regex = new RegExp(`${action.payload}`,'gi');
                    return user.name.match(regex) || user.email.match(regex);
                })
            }

        case FILTER_USER_ROLES:
            return {
                ...state,
                preFiltered: state.users.filter(user => (user.role === action.payload))
            }

        case CLEAR_USERS_FILTER:
            return {
                ...state,
                filtered: null
            }

        case CLEAR_USER:
            return {
                ...state,
                current: null,
                submitted: false
            }

        case USER_ERROR:
            return{
                ...state,
                error: action.payload,
                submitted: false
            }

        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }

        case ADD_USER:
            return{
                ...state,
                submitted: true,
                users: [action.payload, ...state.users],
                preFiltered: [action.payload, ...state.users]
            }

        case UPDATE_USER:
            return{
                ...state,
                users: state.users.map(user => user._id === action.payload._id ? action.payload : user),
                preFiltered: state.users.map(user => user._id === action.payload._id ? action.payload : user),
                submitted: true
            }

        case CLEAR_ROLES_FILTER:
            return{
                ...state,
                preFiltered: state.users
            }

        case DELETE_USER:
            return{
                ...state,
                users: state.users.filter(user => user._id !== action.payload),
                preFiltered: state.preFiltered.filter(user => user._id !== action.payload),
                current: null
            }

        default:
            return state;
    }
}