import React, {useState, useContext,useEffect, useMemo}  from 'react';
import moment from "moment";
import PropTypes from 'prop-types';
import {useParams, useNavigate } from "react-router-dom";
import SessionContext from '../../context/session/sessionContext';
import UserContext from '../../context/user/userContext';
import AlertContext from '../../context/alert/alertContext';
import { Button, Form,Row, Col} from "react-bootstrap";
import ROLES from '../../utils/roles.js';

import AuthContext from '../../context/auth/authContext';

import {Link} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const SessionForm = ({newSession}) => {

    //const { id } = useParams();

    let navigate = useNavigate();

    const params = useParams();

    const sessionContext = useContext(SessionContext);    
    const userContext = useContext(UserContext);
    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);

    const {current, getSession, addSession, updateSession} = sessionContext;
    const {getUsers, users} = userContext;
    const {setAlert} = alertContext;

    const [navToSession, setNavToSession] = useState(false);
    const [requestType, setRequestType] = useState('other');

    const [session, setSession] = useState({
       title : '',
       description: '',
       trainers: [],
       status: 'requested',
       client: null,
       delegate: null,
       startDate: new Date(),
       agenda: 'Request: Not specified.'
    })
    
    const {title, description,agenda} = session || {};
    const {user} = authContext;

    useEffect( () =>{
        if(users===null || !users.length){
            getUsers();
        }

        if(newSession) return;

        getSession(params.id);
        // eslint-disable-next-line
    },[])

    useEffect( () =>{

        if(!newSession && current !== null){
            setSession({
                ...current,
                startDate: moment(current.startDate).format("YYYY-MM-DD")
            });
        }

    },[sessionContext])

    useEffect( () =>{

        if(navToSession){
          
            if(current && current._id){
                setAlert('Thank you for your request.','success');
                navigate(`/sessions/session/${current._id}`);
            }
        }
    },[navToSession,current])

    const onRequestChange = e =>{
        setRequestType(e.target.value);
        setSession({
            ...session,
            agenda: `Request type: ${e.target.value}`
        });
    }

    const onSubmit = e =>{
        e.preventDefault();
   
        if(newSession){
            addSession(session);
       
            setNavToSession(true);
        }
        else{
            updateSession(session);
            navigate(`/sessions/session/${params.id}`);
        }
    }

    const onChange = e => {
        setSession({...session, [e.target.name] :e.target.value, client:user._id });
    }

    return (        
        <>
            <Form onSubmit={onSubmit}>
                <Row>
                    <Col xs="12">
                        <Form.Group className="mb-3">
                            <Form.Label>Client</Form.Label>
                            <Form.Control type="text" placeholder="Client" name="title" value ={title} onChange={onChange} required/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control  as="textarea" rows={3}  placeholder="Description" name="description" value ={description} onChange={onChange} />
                        </Form.Group>
                    </Col>
                    <Form.Group className="mb-3 col-6">
                        <Form.Label>Request type</Form.Label>
                        <Form.Select aria-label="Status" name="status" onChange={e=>onRequestChange(e)} >
                            <option>{requestType}</option>
                            <option value='Media'> Media</option>
                            <option value='Presentation'> Presentation</option>
                            <option value='Blended'> Blended</option>
                            <option value='other'> other</option>
                        </Form.Select>
                    </Form.Group>         
                </Row>
                  
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>

            <div>
                <Link to={`/sessions/sessions`} className="btn btn-primary btn-sm  my-1">
                    Cancel
                </Link>
            </div>
        </>
    )
}

export default SessionForm;