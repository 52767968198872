import React, { useContext,useEffect} from 'react';
import AuthContext from '../../context/auth/authContext';
import SessionContext from '../../context/session/sessionContext';
import VideoContext from '../../context/video/videoContext';

const VideoPreview = ({session}) => {

    const sessionContext = useContext(SessionContext);
    const videoContext = useContext(VideoContext);
    const {getAllVideos, videos} = videoContext;
    const authContext = useContext(AuthContext);

    useEffect(() =>{
      authContext.loadUser();
      getAllVideos();
      
    },[session,sessionContext])

    return (
      <div className="sticky-row">
          <h4 className='pb-2'>Videos</h4>
              {videos !==null ? (
                  videos.length ?
                  videos.filter(video => ((video.session && video.session._id === session._id )
                  || (video.sessions && 
                        (video.sessions.filter(mySession =>(mySession === session._id)).length ||
                          video.sessions.filter(mySession =>(mySession._id === session._id)).length 
                        )
                      )
                    ) 
                  )
                  .reverse().map(video => 
                        <div key={video._id} className='mb-2 video-container'>
                            <iframe key={video._id} title={video._id} width="100%" height="100%" src={`https://player.vimeo.com/video/${video.vimeoID}`} allow="autoplay; encrypted-media" allowFullScreen=""></iframe>
                            <p>{video.title} </p>
                        </div>
                    )
                    :
                    <>No videos</>
              ):
                <div>Loading</div>
              }
      </div>

  )
}

export default VideoPreview