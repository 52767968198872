import React from 'react';
import PropTypes from 'prop-types';

import {Col} from "react-bootstrap";

const TeamItem = ({user}) => {

    return (
        <Col xs='6' md='4' className='text-black'>
            {user.image && <img src={user.image.url} className='aspect-ratio'/>}
            <div className='bg-yellow p-2 mb-3'>
                <h6>{user.name} {user.lastName}</h6>
                <div className='job-title'>{user.jobTitle && user.jobTitle }&nbsp;</div>
            </div>
        </Col>
    )

}

TeamItem.propTypes = {
    user: PropTypes.object.isRequired,
}

export default TeamItem