import React,{useContext, useState, useEffect} from 'react';
import AuthContext from '../../context/auth/authContext';
import PropTypes from 'prop-types';
import TemplateContext from '../../context/template/templateContext';
import {Button, Form,Row, Col} from "react-bootstrap";

const TemplateForm = ({newTemplate,template, closeForm}) => {

    const templateContext = useContext(TemplateContext);
    const {addTemplate,updateTemplate} = templateContext;

    const authContext = useContext(AuthContext);
    const {user} = authContext;

    const [templateData, setTemplateData] = useState({        
        name : '',
        body: '',
        subject: '',
    })

    const {name, body, subject} = templateData; 

    const [showPreview, SetShowPreview] = useState(false);

    const tagReplace = (template) =>template.replaceAll('<~NAME~>',user.name).replaceAll('<~LASTNAME~>',user.lastName).replaceAll('<~SESSION~>','https://portal.bladonmorecoaching.com/sessions/portal-id').replaceAll('\n','<br/>');

    const onChange = e =>{
        e.preventDefault();
        setTemplateData({...templateData, [e.target.name] :e.target.value });
    }

    const onSubmit = e =>{
        e.preventDefault();
        if(newTemplate){
            addTemplate(templateData);
            clearForm();
        }
        else{
            updateTemplate(templateData)
            closeForm();
        }
    }
    
    const clearForm = () => {
        setTemplateData({
            name : '',
            body: '',
            subject: ''
        })
    }

    const togglePreview = (e) =>{
        e.preventDefault();
        SetShowPreview(!showPreview);
    }

    useEffect(() =>{
        if(!newTemplate){
            setTemplateData(template);
        }
        // eslint-disable-next-line
    },[])

    return (
        <div>
        <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3">
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" name="name" value ={name} onChange={onChange} className="w-50" required/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control type="text" placeholder="Subject" name="subject" value ={subject} onChange={onChange} className="" required/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Body</Form.Label>
                        <Form.Control  as="textarea" rows={5}  placeholder="body text" name="body" value ={body} onChange={onChange} required/>
                    </Form.Group>
                </Col>
            </Row>
            <Button variant="primary" type="submit">Submit</Button>
            &nbsp;
            <Button variant="primary" type="" onClick={closeForm}>Cancel</Button>
            &nbsp;
            <Button variant="primary" type="" onClick={togglePreview}>
                {showPreview ? 'Hide Preview' : 'Preview'}
            </Button>
        </Form.Group>
        </Form>
        {showPreview &&
            <div className='template-preview my-5 border'>
                <div className='m-3'>{tagReplace(subject)}</div>
                <div className='m-3'
               
                dangerouslySetInnerHTML={{__html: tagReplace(body)}}
                />
            </div>
        }
        
        </div>
    )

}

TemplateForm.propTypes = {
    template: PropTypes.object.isRequired,
}

export default TemplateForm