import React,{useContext, useEffect, useState} from 'react';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import {useParams, useNavigate } from "react-router-dom";
import Alerts from '../layout/Alerts';

import { Container, Button, Form,Row, Col} from "react-bootstrap";
import logo from '../../img/logo.svg';


import RecoveryEmailForm from './RecoveryEmailForm';


const RecoveryCode = () => {

    const alertContext = useContext(AlertContext);


    const params = useParams();

    const {setAlert} = alertContext;
    const authContext = useContext(AuthContext);
    const{login, error, isAuthenticated, clearErrors} = authContext;

    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated){
            navigate('/');
        }
        if(error === 'Invalid Credentials'){

            setAlert(error, 'danger');
            clearErrors();
            
        }
        // eslint-disable-next-line
    },[error,isAuthenticated])

 

    const [user, setUser] = useState({
        email: ''
    });

    const [showRecoveryForm, setShowRecoveryForm]= useState(false);


    const [passwordSucess, setPasswordSuccess] = useState(true);

    useEffect( () =>{

        if(params.code){
            setShowRecoveryForm(true);
        }
        // eslint-disable-next-line
    },[])



    return (
    <>
        <div className='d-none d-md-block'></div>
        <Container>

            <h1 className='h1 mt-5 font-size-0'>
                <img src={logo} className='logo' /> Bladonmore portal
            </h1>
        </Container>
        <Container>
            <Row className='justify-content-center'>
                <Col md="6" className='d-flex flex-column justify-content-center h-100 form-container pe-5'>
                    <div className='alerts'>
                        <Alerts />
                    </div>
                    {passwordSucess ? 
                        <>
                        <p>Your password has been reset</p>
                        <p>

                        Please login <a className="text-primary" href='/'>here</a>
                        </p>
                        </>
                        :
                        <h2 className='mb-4 login-h2'>you code is 123456789</h2>
                    }

                    
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default RecoveryCode