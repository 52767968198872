import React, {useContext,useEffect} from 'react';
import SessionContext from '../../context/session/sessionContext';
import SessionSidebarItem from './SessionSidebarItem';
import {useNavigate} from 'react-router-dom';
import moment from "moment";

const SessionSidebar = () => {
    
    const sessionContext = useContext(SessionContext);
    const {sessions, getSessions, loading, setCurrent, sessionStatuses, currentStatus,setStatus, getSession} = sessionContext;

    const truncate = (str) => {return str.length > 28 ? str.substring(0, 28) + "..." : str; }

    let navigate = useNavigate();

    useEffect(() =>{
        if(sessions === null || !sessions.length){
            getSessions();
        }
    },[sessionContext])

    const onClick = (e,session) => {
        e.preventDefault();
        setCurrent(session);
        navigate(`/sessions/session/${session._id}`);
        getSession(session._id);
    }
    const changeStatus = (e, status) => {
        e.preventDefault();
        if(status !==currentStatus){
            setStatus(status);
        }
    }

    if(sessions !== null && sessions.length === 0 && !loading){
        return (
            <p className='small bg-blue-alt'>No Sessions</p>
        )
    }

    return(
        <>
            <div>                
                <a className={`d-block px-3 py-1 position-relative session-sidebar-link ${currentStatus === 'all'? ' session-sidebar-link--active':''}`} href="#" onClick={e=>changeStatus(e,'all')}> 
                    <span className='status-menu'>All</span>
                </a>
                {currentStatus === 'all' && sessions && (sessions.slice(0,5).map(
                    session=> {
                        let dt = moment(session.date);
                        return(
                            <a href="#" key={session._id} onClick={e=>onClick(e,session)}>
                                <div className='px-3 pb-1 bg-blue-alt small'>
                                    <span>
                                    <div className='d-block fw-bold'>{dt.format('DD.MM.YY')}</div>
                                    {truncate(session.title)}
                                    </span>
                                </div>
                            </a>
                        )
                    }
                ))}
            </div>
            {sessionStatuses && (
                sessionStatuses.map(status => <SessionSidebarItem key={status} status ={status}/>)
                )
            }
        </>
    )
}

export default SessionSidebar