import {
    GET_BOOKING,
    GET_BOOKINGS,
    ADD_BOOKING,
    DELETE_BOOKING,
    SET_CURRENT_BOOKING,
    UPDATE_BOOKING,
    BOOKING_ERROR
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_BOOKING:
            return {
                ...state,
                currentBooking: action.payload
            }

        case GET_BOOKINGS:
            return {
                ...state,
                bookings: action.payload,
                loading: false
            }
        case ADD_BOOKING:
            return{
                ...state,
                bookings: [action.payload, ...state.bookings]
            }
            
        case DELETE_BOOKING:
            return{
                ...state,
                bookings: state.bookings.filter(booking => booking._id !== action.payload)
            }
        case BOOKING_ERROR:
            return{
                ...state,
                error: action.payload,
            }
        case UPDATE_BOOKING:

            return{
                ...state,
                bookings: state.bookings.map(booking => booking._id === action.payload._id ? action.payload : booking ),
                currentBooking: action.payload
            }
        // case UPDATE_BOOKING:
        //     return{
        //         ...state,
        //         locked: true
        //     }
        default:
            return state;
    }
}