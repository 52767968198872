import React,{useReducer} from "react";
import axios from "axios";

import userContext from "./userContext";
import userReducer from './userReducer';

import {

    GET_USERS,
    USERS_ERROR,
    ADD_USER,
    CLEAR_USER,
    USER_ERROR,
    SET_CURRENT_USER,
    UPDATE_USER,
    CLEAR_USERS_FILTER,
    FILTER_USERS,
    FILTER_USER_ROLES,
    CLEAR_ROLES_FILTER,
    DELETE_USER
   
} from '../types';


const UserState = props => {
    const initialState ={
        users: null,
        current: null,
        preFiltered: null,
        filtered: null,
        error: null,
        loading: true,
        userLoading: true,
        submitted: false,
        filters:[]
    }

    const[state,dispatch] = useReducer(userReducer, initialState);

    const getUsers = async () => {

        try {

            const res = await axios.get('/api/users');
            dispatch({type: GET_USERS, payload: res.data});
        } catch (err) {
            dispatch({type: USERS_ERROR, payload: err.response.msg})
        }
    }

    const addUser = async user => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/users/new', user, config);
            dispatch({type: ADD_USER, payload: res.data});
        } catch (err) {
            dispatch({type: USER_ERROR, payload: err.response.msg})
        }
    }

    const updateUser = async user => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/users/${user._id}`, user, config);
            dispatch({type: UPDATE_USER, payload: res.data});
        } catch (err) {
            dispatch({type: USER_ERROR, payload: err.response.msg})
        }
    }

    const setCurrent = user => {
        dispatch({type: SET_CURRENT_USER, payload: user});
    }

    const clearUser = () => {
        dispatch({type: CLEAR_USER});
    }

    const getUserById = async id => {

        try {
            const res = await axios.get(`/api/users/getUser/${id}`);
            dispatch({type: SET_CURRENT_USER, payload: res.data});
            return res;
        } catch (err) {
            dispatch({type: USER_ERROR, payload: err.response.msg})
        }
    }

    const filterUsers = text => {
        dispatch({type: FILTER_USERS, payload: text});
    }

    const clearFilter = () => {
        dispatch({type: CLEAR_USERS_FILTER});
    }

    const filterUserRoles = role => {
        dispatch({type: FILTER_USER_ROLES, payload: parseInt(role)});
    }

    const clearRoleFilter = () => {
        dispatch({type: CLEAR_ROLES_FILTER});
    }

    const softDeleteUser = async id => {
        dispatch({type: DELETE_USER, payload: id});
    }

    const deleteUser = async id => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }
    
        try {
            const res = await axios.delete(`/api/uses/${id}`, config);
            dispatch({type: DELETE_USER, payload: id});
        } catch (err) {    
            dispatch({type: USER_ERROR, payload: err.response.msg})
        }
    }

    return (
        <userContext.Provider value ={{
            users: state.users,
            current: state.current,
            filtered: state.filtered,
            error: state.error,
            loading: state.loading,
            submitted: state.submitted,
            preFiltered: state.preFiltered,
            addUser,
            updateUser,
            getUsers,
            setCurrent,
            getUserById,
            filterUsers,
            clearFilter,
            clearUser,
            filterUserRoles,
            clearRoleFilter,
            deleteUser,
            softDeleteUser
            }}>
            {props.children}
        </userContext.Provider>
    )
}

export default UserState;