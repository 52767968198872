import React,{useReducer} from "react";
import axios from "axios";

import FileContext from "./fileContext";
import fileReducer from './fileReducer';

import {
    GET_IMAGES,
    GET_FILES,
    FILE_ERROR,
    ADD_FILE,
    ADD_IMAGE,
    DELETE_FILE,
    DELETE_IMAGE,
    UPDATE_FILE
} from '../types';

const FileState = props => {

    const initialState ={
        files: null,
        current: null,
        images: null,
        filtered: null,
        error: null,
        loading: true,
        submitted: false,
    }

    const[state,dispatch] = useReducer(fileReducer, initialState);

    const getFiles = async () => {

        try {
            const res = await axios.get('/api/files');
            dispatch({type: GET_FILES, payload: res.data});
        } catch (err) {
            dispatch({type: FILE_ERROR, payload: err.response.msg})
        }
    }

    // Add file
    const addFile = async formData => {

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        try {
            const res = await axios.post('/api/files', formData, config);
            dispatch({type: ADD_FILE, payload: res.data});
        } catch (err) {
            dispatch({type: FILE_ERROR, payload: err.response.msg})
        }
    }
  
     // Add image
     const addImage = async formData => {
        console.log(formData);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        try {
            const res = await axios.post('/api/files/image', formData, config);
            dispatch({type: ADD_IMAGE, payload: res.data});
        } catch (err) {
            dispatch({type: FILE_ERROR, payload: err.response.msg})
        }
    }

    const getImages = async () => {

        try {
            const res = await axios.get('/api/files/images');
            dispatch({type: GET_IMAGES, payload: res.data});
        } catch (err) {
            dispatch({type: FILE_ERROR, payload: err.response.msg})
        }
    }

    const deleteFile = async id => {

        try {
            await axios.delete(`/api/files/${id}`);
            dispatch({type: DELETE_FILE, payload: id});
        } catch (err) {
            dispatch({type: FILE_ERROR, payload: err.response.msg})
        }
    }

    const deleteImage = async id => {

        try {
            await axios.delete(`/api/files/${id}`);
            dispatch({type: DELETE_IMAGE, payload: id});
        } catch (err) {
            dispatch({type: FILE_ERROR, payload: err.response.msg})
        }
    }

    const updateFile = async file => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/files/${file._id}`, file, config);
            dispatch({type: UPDATE_FILE, payload: res.data});
        } catch (err) {
            dispatch({type: FILE_ERROR, payload: err.response.msg})
        }
    }

    return (
        <FileContext.Provider value ={{
            files: state.files,
            images: state.images,
            error: state.error,
            loading: state.loading,
            submitted: state.submitted,
            addImage,
            getImages,
            addFile,
            getFiles,
            deleteFile,
            deleteImage,
            updateFile
            }}>
            {props.children}
        </FileContext.Provider>
    )
}


export default FileState;