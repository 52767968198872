import React,{useContext, useState, useEffect} from 'react';
import AuthContext from '../../context/auth/authContext';
import PropTypes from 'prop-types';
import ServiceContext from '../../context/service/serviceContext';
import {Button, Form,Row, Col} from "react-bootstrap";

const ServiceForm = ({newService,service, closeForm}) => {

    const serviceContext = useContext(ServiceContext);
    const {addService,updateService} = serviceContext;

    const authContext = useContext(AuthContext);
    const {user} = authContext;

    const [serviceData, setServiceData] = useState({        
        name : '',
        title :'',
        body: '',
        active: false
    })

    const {name, title, body, active} = serviceData; 

    //const [showPreview, SetShowPreview] = useState(false);

    //const tagReplace = (template) =>template.replaceAll('<~NAME~>',user.name).replaceAll('<~LASTNAME~>',user.lastName).replaceAll('<~SESSION~>','https://portal.bladonmorecoaching.com/sessions/portal-id').replaceAll('\n','<br/>');

    const onChange = e =>{
        e.preventDefault();
        setServiceData({...serviceData, [e.target.name] :e.target.value });
    }

    const onCheckboxChange = (e) =>{
        setServiceData({...serviceData, [e.target.name] : e.target.checked });
    }

    const onSubmit = e =>{
        e.preventDefault();
        if(newService){
            addService(serviceData);
            clearForm();
        }
        else{
            updateService(serviceData)
            closeForm();
        }
    }
    
    const clearForm = () => {
        setServiceData({
            name : '',
            title: '',
            body: '',
            active: false
        })
    }


    useEffect(() =>{
        if(!newService){
            setServiceData(service);
        }
        // eslint-disable-next-line
    },[])

    return (
        <div>
        <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3">
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" name="name" value ={name} onChange={onChange} className="w-50" required/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title" name="title" value ={title} onChange={onChange} className="" required/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Body</Form.Label>
                        <Form.Control  as="textarea" rows={5}  placeholder="body text" name="body" value ={body} onChange={onChange} required/>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Label>Is active</Form.Label>
                        <Form.Check 
                            type="switch"
                            name="active"
                            checked={active ?? false}
                            value={active ?? false}
                            onChange= {onCheckboxChange}
                            />     

                </Col>
            </Row>
            <Button variant="primary" type="submit">Submit</Button>
            &nbsp;
            <Button variant="primary" type="" onClick={closeForm}>Cancel</Button>
            
        </Form.Group>
        </Form>
      
        
        </div>
    )

}

// ServiceForm.propTypes = {
//     service: PropTypes.object.isRequired,
// }

export default ServiceForm