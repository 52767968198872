import React, {useContext, useRef, useEffect} from 'react';
import UserContext from '../../context/user/userContext';

const UserFilter = () => {

    const userContext = useContext(UserContext);
    const text = useRef('');
    const {filterUsers,clearFilter,filtered, preFiltered} = userContext;

    useEffect(()=>{
        if(filtered ===null){
            text.current.value = '';
        }
    })

    useEffect(()=>{
        if(text.current.value !== ''){
            filterUsers(text.current.value);
        } 
        else{
            clearFilter();
        }
    },[preFiltered]);

    const onChange = e =>{
        if(text.current.value !== ''){
            filterUsers(e.target.value);
        } 
        else{
            clearFilter();
        }
    }

    return (
        <form className='text-primary py-2 justify-content-between d-flex w-100' onSubmit={e=>(e.preventDefault())}>
            <input ref={text} type="text" placeholder='Filter users' onChange={onChange} className='d-block w-100 p-2'/>
        </form>
    )
}

export default UserFilter
