import React, {useContext,useEffect} from 'react';

import AuthContext from '../../context/auth/authContext';

import SessionContext from '../../context/session/sessionContext';
import TeamContext from '../../context/team/teamContext';
import {useNavigate} from 'react-router-dom';
import {Row, Col } from "react-bootstrap";
import '../../styles/dashboard.scss';
import STATUS from '../../utils/status';

import TeamItem from '../team/TeamItem';

import DashboardServices from './DashboardServices';
import DashboardCarousel from './DashboardCarousel';

//import dashboardBg from '../../img/dashboard-bg.jpg';

function Dashboard() {

  const authContext = useContext(AuthContext);

  const sessionContext = useContext(SessionContext);

  const {user, isAdmin, isClient} = authContext;

  const teamContext = useContext(TeamContext);
  const {getTeam,team} = teamContext;

  const {sessions, getSessions,setStatus, feedbackStatuses,setViewFiltered,sessionStatuses,sessionStatusesAdmin, sessionStatusesClient} = sessionContext;

  const {members} = team || {};

  let navigate = useNavigate();

  useEffect(() =>{
    authContext.loadUser();
    

    if(!sessions || !sessions.length){
      getSessions();
    }

    if(!team){
      getTeam();
    }
    // eslint-disable-next-line
  },[])

  const onClick = (e, count, status) => {
    e.preventDefault();
    if(count>0){
      setStatus(status);
      setViewFiltered();
      navigate('/sessions');
    }
  }

  const renderSatuses = (statuses) =>{

    return(
      <Row>
            {(statuses !==null && sessions !==null) ? (
              statuses.map(status => {
                let count = sessions.filter(session => session.status === status ).length;
                return(
                  <Col key={status} xs='6' md='4' className='p-2 justify-content-between' onClick={e=> onClick(e,count,status)}> 
                    <div className={`dashboard-session-inner p-3 h-100 bg-blue position-relative cta${count>0 && ' dashboard-session-inner--hover'}`}>
                      <h4 className='d-block'>
                      {status}
                      </h4>
                      {count > 0 && (
                        <div className='d-inline-block mt-3 px-2 bg-yellow'>
                          {count}
                        </div>)
                      }
                    </div>
                  </Col>
                );
              }
            ) ):
            <div>Loading</div>
            }
          </Row>
    )
  }



  return (
    <>
      <Row className='dashboard'>
        <Col className='pb-5 d-flex align-items-center col-12 col-lg-6 col-xl-6'>
          <h1>Welcome back { user && user.name }</h1>
        </Col>

    
          <DashboardCarousel/>

      </Row>

      <Row className='mb-5'>
        <Col xs='12' lg='2'>
          <h3 className='mt-2 dashboard-h3'>Sessions</h3>
        </Col>

        <Col xs='12' lg='10'>
          {isAdmin ? renderSatuses(sessionStatusesAdmin)
                    : (isClient ?
                      renderSatuses(sessionStatusesClient):
                       renderSatuses(sessionStatuses)
                    )
          }
        </Col>
        </Row>
        <Row className='mb-5'>
          <Col xs='12' lg='2'>
            <h3 className='mt-2 dashboard-h3'>Feedback</h3>
          </Col>
          <Col xs='12' lg='10'>
            <Row>
              {(feedbackStatuses !==null && sessions !==null) ? 
                feedbackStatuses.map(status => {
                  let count = sessions.filter(session => session.status === status ).length;
                  return(
                    <Col key={status} xs='6' md='4' className='p-2 justify-content-between' onClick={e=> onClick(e,count,status)}> 
                      <div className={`dashboard-session-inner p-3 h-100 bg-blue position-relative cta${count>0 && ' dashboard-session-inner--hover'}`}>
                        <h4 className='d-block'>{STATUS[status]}</h4>
                          {count > 0 && 
                            <div className='d-inline-block mt-3 px-2 bg-yellow'>{count}</div>
                          }
                      </div>
                    </Col>
                  );
                }):
                <div>Loading</div>
              }
            </Row>
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col xs='12' lg='2'>
            <h3 className='mt-1 dashboard-h3'>Meet your team</h3>
            <button className='btn btn-arrow mb-4 text-start' onClick={()=>{navigate('/team')}}>Meet them all</button>
        </Col>
        <Col xs='12' lg='10'>
            <Row>
                {team !== null && members && members.length ? (
                            members.slice(0,3).map( user =>
                            <TeamItem user={user} key={user._id}/>
                        )
                    ):
                    <div>Loading</div>
                }
            </Row>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard