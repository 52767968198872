import React,{useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import OrganisationContext from '../../context/organisation/organisationContext';
import FileContext from '../../context/file/fileContext';

import OrganisationFormImageItem from './OrganisationFormImageItem';

import {Button, Form,Row, Col} from "react-bootstrap";

const OrganisationForm = ({newOrganisation,organisation, closeForm}) => {

    const organisationContext = useContext(OrganisationContext);

    const fileContext = useContext(FileContext);

    const {addOrganisation,updateOrganisation} = organisationContext;

    const {images, getImages} = fileContext;

    const [organisationData, setOrganisationData] = useState({        
        title : '',
        description: '',
        logo: null
     })

     const [showPicker, setShowPicker] = useState(false)

     const [selectedIndex, setSelectedIndex] = useState(-1);
     const onImageClick = (newIndex,imageId) =>{
        if(newIndex === selectedIndex){
            newIndex=(-1);
            imageId = null;
        }
        setSelectedIndex(newIndex);
        setOrganisationData({
            ...organisationData, 
            logo: imageId
        })
     }

     const {title, description,logo} = organisationData; 

     useEffect(() =>{
        if(!newOrganisation){
            setOrganisationData(organisation);
        }
        if(!images){
            getImages();
        }
        // eslint-disable-next-line
    },[])

    const onSubmit = e =>{
        e.preventDefault();
        if(newOrganisation){
            addOrganisation(organisationData);
            clearForm();

        }
        else{
            updateOrganisation(organisationData)
            closeForm();
        }
    }

    const onChange = e =>{
        e.preventDefault();
        setOrganisationData({...organisationData, [e.target.name] :e.target.value });
    }

    const clearForm = () => {
        setOrganisationData({
            title : '',
            description: '',
            logo: null
        })
    }

    const setLogo = (logoID) =>{

        if(logoID == organisationData.logo)
            logoID = null;
        
        setOrganisationData({
            ...organisationData, 
            logo: logoID
        })
    }
    
    const togglePicker = () =>{
        setShowPicker(!showPicker);
    }

    return (

        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title" name="title" value ={title} onChange={onChange} className="w-50" required/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control  as="textarea" rows={3}  placeholder="Description" name="description" value ={description} onChange={onChange} required/>
                    </Form.Group>
                </Col>
                <Form.Control  as="textarea" name="logo" value ={logo} onChange={onChange} className="" hidden/>
            </Row>

            <Button onClick={() => togglePicker()} className="mb-2">{showPicker ? 'Hide logo picker' : 'Show Logo picker'}</Button>
            {showPicker &&
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Row>

                                {
                                images && images.map(
                                    (img,index) => <OrganisationFormImageItem key={index} image={img} index={index} onImageClick={onImageClick} selectedIndex={selectedIndex} setLogo={setLogo} />
                                    )
                                }
                            </Row>

                        </Form.Group>
                    </Col>
        
                </Row>
            }

            <Form.Group className="mb-3">
                <Button variant="primary" type="submit">
                    Submit
                </Button>
                &nbsp;
                <Button variant="primary" type="" onClick={closeForm}>
                    Cancel
                </Button>
            </Form.Group>
        </Form>   
    )
}


OrganisationForm.propTypes = {
    organisation: PropTypes.object.isRequired,
}

export default OrganisationForm