
import React,{useState} from 'react';
import PropTypes from 'prop-types';
import {Col} from "react-bootstrap";
import ServiceForm  from './ServiceForm';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ServicesItem = ({service, deleteService}) => {

    const {name, title, body, active} = service || null;
    const [showForm, setShowForm] = useState(false);

    const toggleForm= () =>{
        setShowForm(!showForm);
    }
    const closeForm = () =>{
        setShowForm(false);
    }
    const onDelete = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        deleteService(service._id);
    }

    const truncate = (str) => {return str.length > 80 ? str.substring(0, 80) + "..." : str; }

    return (
        <Col xs="12" className={`pt-2 row-border ${showForm? '': ' organisation-inner--hover'}`}>
            <div className='p-2 justify-content-between d-flex row cursor-pointer' onClick={toggleForm}>
                <div className='col-12 col-md-4'>
                    {name}
                </div>
                <div className='col-12 col-md-6'>
                    <div className="mb-2"> {title}</div>
                    <div>{truncate(body)}</div>
                </div>
                <div className='col-12 col-md-2 text-end'>
                <button className='btn btn-del px-2 m-2 d-inline-block' onClick={(e) => { if (window.confirm('Are you sure you wish to delete this service?')) onDelete(e)}} data-tooltip-id="del-tooltip" data-tooltip-content="Delete Template"> Delete Service</button>
                    <Tooltip id="del-tooltip"/>
                    <span className={showForm ? 'chevron-down' : 'chevron-up'}></span>
                </div>
            </div>
            {showForm &&
                <div>
                    <ServiceForm service={service} closeForm={closeForm}/>
                </div>
            }
        </Col>
    )
}

ServicesItem.propTypes = {
    services: PropTypes.object.isRequired,
}

export default ServicesItem;