import React, {useState, useContext,useEffect, useRef}  from 'react';
import moment from "moment";
import {useParams, useNavigate } from "react-router-dom";
import AuthContext from '../../context/auth/authContext';
import SessionContext from '../../context/session/sessionContext';
import UserContext from '../../context/user/userContext';
import AlertContext from '../../context/alert/alertContext';
import {Button, Container, Form,Row, Col} from "react-bootstrap";

import {Link} from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import Multiselect from 'multiselect-react-dropdown';
import { Editor } from '@tinymce/tinymce-react';
//import Select from 'react-select';

import statusHelper from '../../utils/nextStatus';

const SessionForm = ({newSession}) => {

    let navigate = useNavigate();

    const params = useParams();
    const agendaEditorRef = useRef(null);
    const feedbackEditorRef = useRef(null);

    const authContext = useContext(AuthContext);
    const sessionContext = useContext(SessionContext);    
    const userContext = useContext(UserContext);
    const alertContext = useContext(AlertContext);

    const {user} = authContext;

    const {current, getSession, addSession, updateSession,statuses,updateSessionAndEmail} = sessionContext;
    const {getUsers, users} = userContext;
    const {setAlert} = alertContext;

    const [session, setSession] = useState({
       title : '',
       description: '',
       trainers: [],
       client: null,
       delegate: null,
       startDate: new Date(),
       copy: '',
       status: 'draft',
       projectManager: null,
       sendEmail: false
    })

    const [trainersList, setTrainersList] = useState([]);

    const [delegatesList, setDelegatesList] = useState([]);
    const [clientsList, setClientsList] = useState([]);

    const [navToSession, setNavToSession] = useState(false);

    const [viewTab, setViewTab] = useState('details');

    //set status flag
    const [statusUpdated, setStatusUpdated] = useState(false);

    const {title, description, startDate, agenda, feedback, trainers, status, projectManager, sendEmail, clients, delegates} = session || {};

    useEffect( () =>{

        if(users===null || !users.length){
            getUsers();
        }

        if(newSession) return;

        getSession(params.id);

        // eslint-disable-next-line
    },[])

    useEffect( () =>{
        if(navToSession){
            if(current && current._id){
                navigate(`/sessions/session/${current._id}`);
            }
        }
    },[navToSession,current])

    useEffect( () =>{

        if(!newSession && current !== null){
            setSession({
                ...current,
                startDate: moment(current.startDate).format("YYYY-MM-DD"),
            
            });

        }

    },[sessionContext])

    useEffect( () =>{

        if(!newSession && current !== null){
          
            if(trainers && trainers.length){
                setSession({
                    ...session,
                    trainers: trainers.map(trainer => ({...trainer, fullName: `${trainer.name} ${trainer.lastName}`})),
                    clients: clients ? clients.map(client => ({...client, fullName: `${client.name} ${client.lastName} - ${client.email}`})) : [],
                    delegates: delegates ? delegates.map(delegate => ({...delegate, fullName: `${delegate.name} ${delegate.lastName} - ${delegate.email}`})) :[]
                })
            }

        }

    },[sessionContext])

    useEffect( () =>{

        if(users && users.length >0){
            let list =users.filter(u => (u.isCoach));
            list.forEach(el=>{
                el.fullName= `${el.name} ${el.lastName}`;
             })

            setTrainersList(list);

            list =users.filter(user => user.role===4);
            list.forEach(el=>{
                el.fullName= `${el.name} ${el.lastName} - ${el.email}`;
             })
            setDelegatesList(list);

            list = users.filter(user => user.role===3);
            list.forEach(el=>{
                el.fullName= `${el.name} ${el.lastName} - ${el.email}`;
             });
             setClientsList(list);
        }

    },[users])

    useEffect( () =>{
        if(statusUpdated) submitOnNextAction();
    },[statusUpdated])

    const onSubmit = e =>{
        e.preventDefault();
        if(trainers === null || trainers.length < 1){
            setAlert('Please enter a Trainer', 'danger');
            window.scrollTo(0, 0);
            return;
        }

        if(newSession){
            addSession(session);
            setNavToSession(true);
        }
        else{
            updateSession(session);
            navigate(`/sessions/session/${params.id}`);
        }
    }

    const onNextAction = e =>{
        e.preventDefault();
        if(trainers === null || trainers.length < 1){
            setAlert('Please enter a Trainer', 'danger');
            window.scrollTo(0, 0);
            return;
        }

        if(newSession){
            addSession(session);
            navigate(`/sessions`);
        }
        else{
            const nextStatus = statusHelper(status).next
            setSession({...session, status : nextStatus });
            setStatusUpdated(true);
        }
    }

    const submitOnNextAction = () =>{
            updateSessionAndEmail(session);
            navigate(`/sessions/session/${params.id}`);
    }

    const onChange = e => {
        setSession({...session, [e.target.name] :e.target.value });
    }

    const onAgendaChange = (e) => {

        let text = e.target.getContent();
        setSession({...session, agenda: text});
    }
    
    const onFeedbackChange = (e) => {
        let newText = e.target.getContent();
        setSession({...session, feedback: newText });
    }

    const onTrainersSelect = (trainers) => {
        setSession({...session, trainers: trainers });
    }

    const onCheckboxChange = (e) =>{
        setSession({...session, [e.target.name] : e.target.checked });
    }
 
    const onDelegatesSelect = (delegates)=>{
        setSession({...session, delegates: delegates });
    }

    const onClientsSelect = (clients)=>{
        setSession({...session, clients: clients });
    }

    return (
        <Container>
            <Row className='pb-1 mb-4 border-bottom'>
                <Col xs="12">
                    {title ? <h3>{title}</h3> : <h1>New Session</h1>}
                </Col>
            </Row>
            <Row>
                <Col md={3} lg={2}>
                    <div className="pt-2">
                        <a href="#" className={`d-block mb-2 px-3 py-1 position-relative session-sidebar-link${viewTab ==='details' ? ' session-sidebar-link--active':''}`} onClick={()=>{setViewTab('details')}}><span className="status-menu">Details</span></a>
                        <a href="#" className={`d-block mb-2 px-3 py-1 position-relative session-sidebar-link${viewTab ==='agenda' ? ' session-sidebar-link--active':''}`} onClick={()=>{setViewTab('agenda')}}><span className="status-menu" >Agenda</span></a>
                        <a href="#" className={`d-block mb-2 px-3 py-1 position-relative session-sidebar-link${viewTab ==='feedback' ? ' session-sidebar-link--active':''}`} onClick={()=>{setViewTab('feedback')}}><span className="status-menu" >Feedback</span></a>
                    </div>
                </Col>
                <Col md={9} lg={10}>
                    <Form onSubmit={onSubmit}>
              
                    { viewTab === 'details' &&
                        <>
                            <Row className='zindex-dropdown'>
                                <Form.Group className="mb-3 col-6">
                                    <Form.Label>Client</Form.Label>
                                    <Form.Control type="text" placeholder="Client" name="title" value ={title} onChange={onChange} required/>
                                </Form.Group>
                                <Form.Group className="mb-3 col-6">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control  type="date" name="startDate" value ={startDate} onChange={onChange} />
                                </Form.Group>
                                <Form.Group className="mb-3 col-12">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control  as="textarea" rows={3}  placeholder="Description" name="description" value ={description} onChange={onChange} />
                                </Form.Group>
                                <Form.Group className="mb-3 col-6">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Status" value={status} name="status" onChange={onChange} >
                                        {statuses && (statuses.map(st => <option key={st.value} value={st.value}> {st.label}</option>))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3 col-6">
                                    <Form.Label>Project manager</Form.Label>
                                    <Form.Select aria-label="Project manager" value={projectManager?._id} name="projectManager" onChange={onChange} >
                                        {users && (users.filter(user => user.isProjectManager).map(user => <option key={user._id} value={user._id}> {user.name}</option>))}
                                    </Form.Select>
                                </Form.Group>

                                <Col xs={12} md={12}>
                                    <Form.Group  className="mb-3">
                                        <Form.Label>Clients</Form.Label>
                                        <Multiselect
                                            options={clientsList} // Options to display in the dropdown
                                            selectedValues={clients} // Preselected value to persist in dropdown
                                            onSelect={onClientsSelect} // Function will trigger on select event
                                            onRemove={onClientsSelect} // Function will trigger on remove event
                                            displayValue="fullName" // Property name to display in the dropdown options
                                            // add email
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12}>
                                    <Form.Group  className="mb-3">
                                        <Form.Label>Delegates</Form.Label>
                                        <Multiselect
                                            options={delegatesList} // Options to display in the dropdown
                                            selectedValues={delegates} // Preselected value to persist in dropdown
                                            onSelect={onDelegatesSelect} // Function will trigger on select event
                                            onRemove={onDelegatesSelect} // Function will trigger on remove event
                                            displayValue="fullName" // Property name to display in the dropdown options // full name + email                                            
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>

                                    <Form.Group className="mb-3 form-dropdown col-6 col-lg-3">
                                        <Form.Label>Send email</Form.Label> 
                                        (Send automated emails)
                                        <Form.Check 
                                            disabled= {(newSession || user.role===1) ? false : true}
                                            type="switch"
                                            name="sendEmail"
                                            checked={sendEmail ?? false}
                                            value={sendEmail ?? false}
                                            onChange = {onCheckboxChange}
                                        />
                                        <div className="locked pt-2">Automated emails are {sendEmail ? 'ACTIVE' : 'DISABLED'}</div>
                                    </Form.Group>            
                                </Col>
                                <Col xs={12}>
                                    <Form.Label>Trainers</Form.Label>
                                    <Form.Group  className="mb-3">
                                        <Multiselect
                                            options={trainersList} // Options to display in the dropdown
                                            selectedValues={trainers} // Preselected value to persist in dropdown
                                            onSelect={onTrainersSelect} // Function will trigger on select event
                                            onRemove={onTrainersSelect} // Function will trigger on remove event
                                            displayValue="fullName" // Property name to display in the dropdown options
                                        />
                                    </Form.Group>
                                </Col>
                              
                            </Row>
                        </>
                    }
                    {viewTab ==="agenda" &&
                        <Row className='z-index-0'>
                            <Col>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                onInit={(evt, editor) => agendaEditorRef.current = editor}
                                initialValue={agenda}
                                init={{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | fontsize | code | help',
                                content_style: 'body { font-family:Montserrat,sans-serif; font-size:14px }'
                                }}
                                onBlur={onAgendaChange}
                            />

                            </Col>
                        </Row>
                        }
                        { viewTab==='feedback' &&
                        <Row className='mt-4'>
                            <Col>                        
                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    onInit={(evt, editor) => feedbackEditorRef.current = editor}
                                    initialValue={feedback}
                                    init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | fontsize | code | help',
                                    content_style: 'body { font-family:Montserrat,sans-serif; font-size:14px }'
                                    }}
                                    onBlur={onFeedbackChange}
                                />

                            </Col>
                        </Row>            
                        }

                        <Row className='my-4'>
                            <Col className='d-flex justify-content-between'>
                            <Button variant="primary" type="submit">
                                Save     
                            </Button>

                            <Button className='btn-alt' variant="primary" onClick={(e) => { if (window.confirm(`Are you sure ${user.name}?`)) onNextAction(e) } }  data-tooltip-id="warning-tooltip" data-tooltip-html="Warning: This action will send <br />an email to the delegate.">
                                {statusHelper(status).sessionLabel}
                            </Button>
                            {(session.status === 'pending' || session.status === 'feedbackPending' ) && //(session.status === 'approved' || session.status === 'feedbackApproved' )
                                <Tooltip id="warning-tooltip" multiline={true} data-html={true}
                                style={{ backgroundColor: "#ffc107" }}/>
                            }
                            </Col>
                        </Row>
                    </Form>

                    <div>
                        <Link to={`/sessions/session/${params.id}`} className="btn btn-primary btn-sm  my-1">
                            Back
                        </Link>                            
                    </div>
                </Col>
            </Row>
        </Container>
        
    )
}

export default SessionForm;