import React from 'react'

const Contact = () => {
  return (
    <>
      <h2>Contact</h2>
      <p>Have a question related to the training portal?
      <br/>Please email us <a className='email' href="mailto:bladonmoretraining@bladonmore.com">here</a></p>
    </>

  )
}

export default Contact