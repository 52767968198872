import React, {useContext, useEffect, useState,useRef} from 'react';
import {useParams, useNavigate, Link } from "react-router-dom";
import {Row, Col, Form, Container} from "react-bootstrap";

import BookingSessionsDelegates from '../bookingSession/BookingSessionsDelegates';
import BookingContext from '../../context/booking/bookingContext';
import BookingSessions from '../bookingSession/BookingSessions';
import BookingSessionsLocked from '../bookingSession/BookingSessionsLocked';

import Alerts from '../layout/Alerts';

const Booking = ({isAdmin}) => {

  const params = useParams();

  const bookingContext = useContext(BookingContext);

  let navigate = useNavigate();

  const {currentBooking, getBooking, updateBooking} = bookingContext;
  const {title, delegateSelectionLimit, delegateLowerSelectionLimit, sessionSlotsLimit, startDate, delegates, locked} = currentBooking || {};

  useEffect( () =>{

      getBooking(params.id);
      // eslint-disable-next-line
  },[])

  const editBooking = (e) =>{
    e.preventDefault();
    navigate(`/bookings/edit/${currentBooking._id}`);
  }

  const onLockChange = (e) =>{
    updateBooking({...currentBooking, [e.target.name] : e.target.checked });
  
  }

  return (
    <>
        <Row className='pb-1 mb-5 border-bottom'>
            <div className='col-12 col-md-8'>
                <h2 className="d-inline-block">{title}</h2> {isAdmin && <button className='btn btn-edit px-2 m-3' onClick={editBooking}>Edit booking</button> }
            </div>
            <div className='col-12 col-md-4 text-end align-bottom'>
              <Link to={`/bookings`} className="btn btn-primary d-inline-block my-1">
                Back to all bookings
              </Link>
            </div>
        </Row>
        
        <Container className=''>
                <div className='alerts'>
                    <Alerts />
                </div>
        </Container>

        <Row>
          {isAdmin && 
            <Col xs={12} lg={2}>
              <BookingSessionsDelegates currentBooking={currentBooking}/>
            </Col>
          }
          <Col xs={12} lg={isAdmin ? 10 : 12}>
            {isAdmin && <Row className='pb-1 mb-5'>
                <div className='col-6 col-md-3'> 
                  <div>
                    Max delegates per session: {sessionSlotsLimit}
                  </div>
                </div>
                <div className='col-6 col-md-3'> 
                  <div>
                    Delegate selection Limit: {delegateSelectionLimit}
                  </div>
                  <div>
                    Lower selection Limit: {delegateLowerSelectionLimit}
                  </div>
                </div>
                <div className='col-6 col-md-3'> 
                    start date: {new Date(startDate).toLocaleDateString('en-GB')}
                </div>
                <div className='col-6 col-md-3'> 
                  {isAdmin  ?
                    <Form.Group className="mb-3">
                                <Form.Label>Lock booking</Form.Label> 
                                    <Form.Check 
                                        type="switch"
                                        name="locked"
                                        checked={locked ?? false}
                                        value={locked ?? false}
                                        onChange = {onLockChange}
                                    />
                    </Form.Group>
                    : (locked ? 'Booking locked' : 'Booking open')
                    }
                </div>
            </Row>}
            {locked && !isAdmin ?
              <BookingSessionsLocked delegates={delegates}  isAdmin={isAdmin}/>
            :
              <BookingSessions delegates={delegates}  isAdmin={isAdmin}/>
            }
          </Col>
      </Row>
    </>
  )
}

export default Booking