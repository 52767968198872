import {
    GET_BOOKING_SESSIONS,
    ADD_BOOKING_SESSION,
    BOOKING_SESSION_ERROR,
    DELETE_BOOKING_SESSION,
    UPDATE_BOOKING_SESSION
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_BOOKING_SESSIONS:
            return {
                ...state,
                bookingSessions: action.payload,
                loading: false
            }
        
        case ADD_BOOKING_SESSION:
            return{
                ...state,
                bookingSessions: [action.payload, ...state.bookingSessions]
            }

        case DELETE_BOOKING_SESSION:
            return{
                ...state,
                bookingSessions: state.bookingSessions.filter(bookingSession => bookingSession._id !== action.payload)
            }

        case UPDATE_BOOKING_SESSION:
            return{
                ...state,
                bookingSessions: state.bookingSessions.map(bookingSession => bookingSession._id === action.payload._id ? action.payload : bookingSession )
            }


        case BOOKING_SESSION_ERROR:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }
}