import React, {useContext,useEffect,useState}  from 'react';
//import PropTypes from 'prop-types';
import {useParams } from "react-router-dom";
import SessionContext from '../../context/session/sessionContext';
import {Link, useNavigate} from 'react-router-dom';

import AuthContext from '../../context/auth/authContext';

import SessionSidebar from './SessionSidebar';
import {Container, Card, Row, Col, Button} from "react-bootstrap";

import VideoForm from '../video/VideoForm';
import VideosView from '../video/VideosView';
import VideoPreview from '../video/VideoPreview';

import moment from "moment";

import logo from '../../img/logo.svg';

import Files from '../media/Files';

import SessionApproveForm from './SessionApproveForm';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import TagManager from 'react-gtm-module';

const Session = ({isAdmin}) => {

    let navigate = useNavigate();
    const sessionContext = useContext(SessionContext);
    const {current, getSession,clearSession} = sessionContext;
    const authContext = useContext(AuthContext);
    const {user} = authContext;
    const [showTab, setShowTab] = useState('agenda');
    const [showVideoForm, setShowVideoForm] = useState(false)

    let {title, description, client, status, startDate, delegate, trainers, agenda, feedback,projectManager,delegates,clients } = current || {};

    const onShowVideoFormClick = () => {
        setShowVideoForm(!showVideoForm);
    }
    
    const editSession = (e) =>{
        e.preventDefault();
        navigate(`/sessions/session/edit/${current._id}`);
    }

    const newSession = (e)=>{
        e.preventDefault();
        clearSession();
        navigate('/sessions/new');
    }

    useEffect( () =>{

    getSession(params.id);
    if(current){
        //gtag
        const tagManagerArgs = {
            dataLayer: {
                'sessionID': current._id,  //A unique ID associated with session
                'sessionName': title,  //Client open field value
                'sessionStatus': status,  //Status field value
                'projectManager': projectManager,  //Project manager name drop down value
                'sessionClient': client,  //Client name drop down value
                'sessionDelegate': delegate,  //Delegate name drop down value
                'sessionTrainers': trainers, //Trainers array
                'userID': user._id,
                'userStatus' : user.role
            },
            dataLayerName: 'dataLayer'
        }
       
        //console.log(tagManagerArgs);
    }
        return()=>{
            clearSession();
        }
         // eslint-disable-next-line
    },[])

    useEffect( () =>{

       if(feedback){
        setShowTab('feedback');
       }
    },[feedback, sessionContext])

    useEffect( () =>{
        //gtag
        if(current){

        const tagManagerArgs = {
            dataLayer: {
                'sessionID': current._id,  //A unique ID associated with session
                'sessionName': title,  //Client open field value
                'sessionStatus': status,  //Status field value
                'projectManager': projectManager ? `${projectManager.name} ${projectManager.lastName}`:'',  //Project manager name drop down value
                'sessionClients': clients ? clients.map(client => client._id) : [],  //Client name drop down value
                'sessionDelegates': delegates ? delegates.map(del => del._id) : [], //Delegate name drop down value
                'sessionTrainers': trainers ? trainers.map(tr => `${tr.name} ${tr.lastName}`) : [], //Trainers array
                'userID': user._id,
                'userStatus' : user.role
            },
            dataLayerName: 'dataLayer'
        }
        //if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1")
            TagManager.dataLayer(tagManagerArgs);        

    }
       
    },[current])


    const params = useParams();

    return (
        <>
        <Row className='pb-1 mb-4 border-bottom'>
            <Col xs="12" md="6">
                <h1>Session</h1>
            </Col>
            {user && user.role===3 && current && status==='pending' &&
                <Col xs="12" md="6" className='mt-4'>
                    <SessionApproveForm isFeedback={false}/>
                </Col>
            }
            {user && user.role===3 && current && status==='feedbackPending' &&
                <Col xs="12" md="6" className='mt-4'>
                    <SessionApproveForm isFeedback={true}/>
                </Col>
            }
            { isAdmin &&
                <Col xs="12" md="6" className='mt-4'>
                    <Row>
                    <Col className='d-flex justify-content-end'>
                    <Button className='btn btn-yellow' onClick={newSession} data-tooltip-id="create-tooltip" data-tooltip-content="Create new session">
                        New session
                    </Button>
                    <Tooltip id="create-tooltip" />
                    </Col>
                    </Row>
                </Col>
            }
        </Row>
        <Container className='py-5 print-padding'>

            <Row className='print-header'>
                <Col xs='3'>
                    <span className='h1 my-2'><img src={logo} className='logo' alt='Bladonmore logo'/>Bladonmore portal</span>
                </Col>

                <Col xs='9' className='d-flex justify-content-end'>
                    <div className='address'>
                    <b>Bladonmore</b><br/>
                    55 New Oxford Street<br/>
                    London WC1A 1BS<br/>
                    <b>bladonmore.com</b><br/>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className="col-2 no-print">            
                    <SessionSidebar/>
                </Col>
                <Col className='align-self-stretch'>
                    <Row>
                        <Col xs="12" md="11">
                            <h2 className='d-inline h2 large'>{title}</h2>
                            {isAdmin && <button className='btn btn-edit px-2 m-3' onClick={editSession}>Edit Session</button> }
                        </Col>

                        <Col xs='12'>

                            <div>{moment(startDate).format('DD MMMM YYYY')}</div>
                            <p>{description}</p>
                            {client &&
                            <div className='mb-2 text-muted'>
                                Client: {client?.name} &nbsp; Delegate: {delegate?.name}
                            </div>
                            }
                            <div className="mb-2 text-muted">
                                Status: {status}
                            </div>
                            <div className='text-muted pb-2'>Clients: {(clients && clients.length >0) && clients.map(client => `${client?.name} ${client?.lastName}`).join(', ')}
                            </div>
                            <div className='text-muted pb-4'>Delegates: {(delegates && delegates.length >0) && delegates.map(delegate =>  `${delegate?.name} ${delegate?.lastName}`).join(', ')}
                            </div>
                            <div className='no-print'>
                                {<button className= {showTab === 'agenda'? 'btn btn-yellow': 'btn'} onClick = {()=>setShowTab('agenda')}>Agenda</button>}
                                {<button className= {showTab === 'videos'? 'btn btn-yellow': 'btn'} onClick = {()=>setShowTab('videos')}>Videos and slides</button>}
                                {feedback && <button className= {showTab === 'feedback' ? 'btn btn-yellow' : 'btn'} onClick = {()=>setShowTab('feedback')}>Feedback</button>}
                            </div>

                        </Col>
               
                        <Col xs='12' className='pt-4 print-content'>
                           
                            {(showTab === 'agenda') && 
                                <>
                                    <h3 className="agenda-tab mb-4">Agenda</h3>
                                    <div dangerouslySetInnerHTML={{__html:agenda}}/>    
                                </>
                            }

                            {(showTab === 'videos') && 
                                <>
                                    <h3 className="resources-tab mb-4">Videos</h3>
                                    <VideosView/>

                                    <div className='my-3'>
                                        {showVideoForm && 
                                            <Card>
                                                <Card.Body>                        
                                                    <VideoForm newVideo={true} session={current} setShowForm={setShowVideoForm}/>
                                                </Card.Body>
                                            </Card>
                                        }

                                        {isAdmin &&
                                            <a href="#" className="btn btn-primary btn-sm  my-1" onClick={onShowVideoFormClick}>
                                                {showVideoForm ? 'Close form' : 'New Video'} 
                                            </a>
                                        }
                                    </div> 
                                    <Files isAdmin={isAdmin} showForm={null} setShowForm={null} session={current} />
                                </>
                            }
                                    
                            {showTab === 'feedback' && feedback && 
                                <>
                                    <h3 className="feedback-tab mb-4">Feedback</h3>
                                    <div dangerouslySetInnerHTML={{__html:feedback}}/>
                                </>
                            }
                            <div className='no-print d-flex justify-content-between '>
                                <div>
                                <Link to={`/sessions`} className="btn btn-primary btn-sm  my-1">
                                Back
                                </Link>
                                </div>
                                {user && user.role===3 && current && status==='pending' &&
                                    <SessionApproveForm />     
                                }
                                {user && user.role===3 && current && status==='feedbackPending' &&
                                    <SessionApproveForm isFeedback={true}/>
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-2 no-print">
                    {projectManager &&
                        <div className='mb-4'>
                            <h4 className='pb-2'>Project manager</h4>
                            <Row>
                                {projectManager.image ?
                                    <Col key={projectManager._id} xs="12">
                                        <img src={projectManager.image.url}/>
                                        <div className='pt-2'>
                                            {`${projectManager.name} ${projectManager.lastName}`}
                                        </div>
                                    </Col>
                                    :
                                    <Col key={projectManager._id} xs="12">
                                        <div className='pt-2'>
                                            {`${projectManager.name} ${projectManager.lastName}`}
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                        }
                        {trainers && trainers.length >0 &&
                            <>
                                <div className='mb-4'>
                                    <h4 className='pb-2'>Coaches</h4>
                                    <Row>
                                    {trainers.map(
                                        trainer => (trainer.image ?
                                            <Col key={trainer._id} xs="12">
                                                <img src={trainer.image.url}/>
                                                <div className='py-2'>
                                                    {`${trainer.name} ${trainer.lastName}`}
                                                </div>
                                            </Col>                                        
                                            :
                                            <Col key={trainer._id} xs="12">
                                                <div className='py-2'>
                                                    {`${trainer.name} ${trainer.lastName}`}
                                                </div>
                                            </Col>)
                                        )}
                                    </Row>                         
                                </div>
                                <VideoPreview session={current}/>
                            </>
                        }
                    </Col>
            </Row>
        </Container>
        </>
    )
}
    
Session.propTypes = {}
    
export default Session