import React,{useReducer} from "react";
import axios from "axios";

import BookingContext from "./bookingContext";
import bookingReducer from './bookingReducer';

import {
    GET_BOOKING,
    GET_BOOKINGS,
    ADD_BOOKING,
    DELETE_BOOKING,
    UPDATE_BOOKING,
    BOOKING_ERROR,
    LOCK_BOOKING
} from '../types';

const BookingState = props => {

    const initialState ={
        bookings: [],
        currentBooking: null,
        error: null,
        loading: true
    }

    const[state,dispatch] = useReducer(bookingReducer, initialState);

    const getBookings = async () => {

        try {
            const res = await axios.get('/api/bookings');
            dispatch({type: GET_BOOKINGS, payload: res.data});
        } catch (err) {
            dispatch({type: BOOKING_ERROR, payload: err.response.msg})
        }
    }    

    const addBooking = async formData => {
            const config = {
                Headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
    
            try {
                const res = await axios.post('/api/bookings', formData, config);
                dispatch({type: ADD_BOOKING, payload: res.data});
            } catch (err) {
                dispatch({type: BOOKING_ERROR, payload: err.response.msg})
            }
    }
    
    const updateBooking = async booking => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/bookings/${booking._id}`, booking, config);
            dispatch({type: UPDATE_BOOKING, payload: res.data});
        } catch (err) {
            dispatch({type: BOOKING_ERROR, payload: err.response.msg});
        }
    }

    const getBooking = async id => {

        try {
            const res = await axios.get(`/api/bookings/${id}`);
            dispatch({type: GET_BOOKING, payload: res.data});

        } catch (err) {
            dispatch({type: BOOKING_ERROR, payload: err.response.msg})
        }
    }

    const deleteBooking = async id => {
        try {
            await axios.delete(`/api/bookings/${id}`);
            dispatch({type: DELETE_BOOKING, payload: id});
        } catch (err) {
            dispatch({type: BOOKING_ERROR, payload: err.response.msg});
        }
    }

    const requestSessions = async bookingRequest => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/bookings/requests/${bookingRequest._id}`, bookingRequest, config);
            dispatch({type: UPDATE_BOOKING, payload: res.data});
        } catch (err) {
            dispatch({type: BOOKING_ERROR, payload: err.response.msg});
        }
    }

    return (
        <BookingContext.Provider value ={{
            bookings : state.bookings,
            currentBooking : state.currentBooking,
            addBooking,
            updateBooking,
            getBookings,
            getBooking,
            deleteBooking,
            requestSessions
            }}>
            {props.children}
        </BookingContext.Provider>
    )
}


export default BookingState;