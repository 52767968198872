import React,{useReducer} from "react";
import axios from "axios";

import bookingSessionContext from "./bookingSessionContext";
import bookingSessionReducer from './bookingSessionReducer';


import {
    GET_BOOKING_SESSIONS,
    ADD_BOOKING_SESSION,
    BOOKING_SESSION_ERROR,
    DELETE_BOOKING_SESSION,
    UPDATE_BOOKING_SESSION
} from '../types';


const BookingSessionState = props => {
    const initialState ={
        bookingSessions: [],
        error: null,
        loading: false
    }

    const[state,dispatch] = useReducer(bookingSessionReducer, initialState);

    const getAllBookingSessions = async () => {

        try {
            const res = await axios.get('/api/booking-sessions');
            dispatch({type: GET_BOOKING_SESSIONS, payload: res.data});
        } catch (err) {
            dispatch({type: BOOKING_SESSION_ERROR, payload: err.response.msg})
        }
    }

    const getBookingSessions = async booking => {

        try {
            const res = await axios.get(`/api/booking-sessions/booking/${booking._id}`);
            dispatch({type: GET_BOOKING_SESSIONS, payload: res.data});
        } catch (err) {
            dispatch({type: BOOKING_SESSION_ERROR, payload: err.response.msg})
        }
    }

    const addBookingSession = async formData => {
        const config = {
            Headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        try {
            const res = await axios.post('/api/booking-sessions', formData, config);
            dispatch({type: ADD_BOOKING_SESSION, payload: res.data});
        } catch (err) {
            dispatch({type: BOOKING_SESSION_ERROR, payload: err.response.msg})
        }
    }

    const updateBookingSessionUI = async bookingSession => {

        dispatch({type: UPDATE_BOOKING_SESSION, payload: bookingSession});
     
    }
    
    const updateBookingSession = async bookingSession => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/booking-sessions/${bookingSession._id}`, bookingSession, config);
            dispatch({type: UPDATE_BOOKING_SESSION, payload: res.data});
        } catch (err) {
            dispatch({type: BOOKING_SESSION_ERROR, payload: err.response.msg});
        }
    }

    const deleteBookingSession = async id => {
        try {
            await axios.delete(`/api/booking-sessions/${id}`);
            dispatch({type: DELETE_BOOKING_SESSION, payload: id});
        } catch (err) {
            dispatch({type: BOOKING_SESSION_ERROR, payload: err.response.msg});
        }
    }

    return (
        <bookingSessionContext.Provider value ={{
            bookingSessions: state.bookingSessions,
            error: state.error,
            loading: state.loading,
            getAllBookingSessions,
            getBookingSessions,
            updateBookingSession,
            deleteBookingSession,
            addBookingSession,
            updateBookingSessionUI
            }}>
            {props.children}
        </bookingSessionContext.Provider>
    )
}


export default BookingSessionState;