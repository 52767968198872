import React, {useContext,useEffect} from 'react'
import AuthContext from '../../context/auth/authContext';
//import UserContext from '../../context/user/userContext';
import TeamContext from '../../context/team/teamContext';
import TeamItem  from './TeamItem';

import TeamForm  from './TeamForm';

import {Row, Col, Button} from "react-bootstrap";

import '../../styles/images.scss';
import { useState } from 'react';

const Team = () => {


    const authContext = useContext(AuthContext);


    const teamContext = useContext(TeamContext);

    const {isSuperAdmin} = authContext;
    const {getTeam,team} = teamContext;


    const {members} = team || {};
    const [showForm,setShowForm] =  useState(false);

    useEffect(() =>{
        authContext.loadUser();
        //getUsers();
        getTeam();
        // eslint-disable-next-line
    },[])



  return (
        <>
            <Row className='pb-1 mb-5 border-bottom'>
                <div className='col-12 col-md-6'> 
                    <h1>Meet your team</h1>
                </div>
                <div className='mb-2 text-end d-flex align-items-end justify-content-end col-12 col-md-6'> 

                    { isSuperAdmin && !showForm && <Button onClick={()=>setShowForm(true)} className='btn btn-primary btn-session-top'>Edit Team</Button> }

                </div>
            </Row>
            { isSuperAdmin && showForm &&
                <Row>
                    <TeamForm setShowForm={setShowForm}/>
                </Row>
            }
            <Row>
                {team !== undefined && members !== null && members.length ? (
                            members.map( user =>
                            <TeamItem user={user} key={user._id}/>
                        )
                    ):
                    <div>Loading</div>
                }
            </Row>
        </>
  )
}


export default Team;