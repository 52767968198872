import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import {Col} from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import VideoContext from '../../context/video/videoContext';
import 'react-tooltip/dist/react-tooltip.css';

const VideoItem = ({video, isAdmin, selectedVideo, setSelectedVideo }) => {

    const {title,vimeoID, date, session} = video;

    const videoContext = useContext(VideoContext);
    const {deleteVideo} = videoContext;

    const onDeleteVideo=() =>{
        deleteVideo(video._id);
    }

    const onClick = () => {
        setSelectedVideo(video);
    }
  
    return (
        <Col xs='6' md='4' lg='3'  className='px-2 my-3 justify-content-between video-container iframe-z'>

            <div className='position-relative'>
                <div className='link' onClick={()=>onClick()}></div>
                <iframe key={video._id}  width="100%" height="" src={`https://player.vimeo.com/video/${vimeoID}`} allow="autoplay; encrypted-media" allowFullScreen="true"></iframe>
            </div>
            <div className='d-flex'>
                <div className='py-2' >{session && session.title}</div>
                {isAdmin && <div className="edit-btns text-end flex-grow-1">

                <button className='btn btn-del btn-del--alt px-2 m-2' onClick={(e) => { if (window.confirm('Are you sure you wish to delete this video?')) onDeleteVideo(e)}} data-tooltip-id="del-tooltip" data-tooltip-content="Delete Video"> Delete Video</button>
                <Tooltip id="del-tooltip" />
                </div>}
            </div>
            <h6 className='pb-2 link-cursor' data-tooltip-id="exp-tooltip" data-tooltip-content="Play video" onClick={()=>onClick()}>{title} </h6>
            <Tooltip id="exp-tooltip" />
        </Col>
    )
    
}

VideoItem.propTypes = {
    video: PropTypes.object.isRequired,
}

export default VideoItem