import React, {useContext, useState} from 'react';
import UserContext from '../../context/user/userContext';

const UserSidebar = () => {
    
    const userContext = useContext(UserContext);
    const {filterUserRoles,clearRoleFilter}= userContext;

    const [selectedStatus,setSelectedStatus] = useState('all');

    const onClick = e =>{
        if(e.target.value !==''){
            if(e.target.name ==='all'){
                clearRoleFilter();
            }else{
                filterUserRoles(e.target.name);
            }
            setSelectedStatus(e.target.name);
        } 
    }

    const options = [
                    {name:'all', label:'All'},
                    {name:2, label:'Admin'},
                    {name:3, label:'Clients'},
                    {name:4, label:'Delegates'},
                    ];

    return(
        <>
            {options.map(option =>
                <a href="#" key={option.name} className={`d-block px-3 py-1 text-capitalize session-sidebar-link ${selectedStatus == option.name ? 'session-sidebar-link--active' : ''}`} name={option.name} onClick={(e)=>onClick(e)}>{option.label}</a>
            )}
        </>
    )
}

export default UserSidebar