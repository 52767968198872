import React,{useReducer} from "react";
import axios from "axios";

import notificationContext from "./notificationContext";
import notificationReducer from './notificationReducer';

import {
    ADD_NOTIFICATION,
    DELETE_NOTIFICATION,
    SET_CURRENT_NOTIFICATION,
    CLEAR_CURRENT_NOTIFICATION,
    UPDATE_NOTIFICATION,
    FILTER_NOTIFICATIONS,
    CLEAR_NOTIFICATION_FILTER,
    NOTIFICATION_ERROR,
    GET_NOTIFICATIONS,
    CLEAR_NOTIFICATIONS
} from '../types';


const NotificationState = props => {
    const initialState ={
        notiifcations:null,
        current: null,
        filtered: null,
        error: null,
        loading: false
    }

    const[state,dispatch] = useReducer(notificationReducer, initialState);


    //Get NOTIFICATIONs

    const getNotifications = async () => {

        try {
            const res = await axios.get('/api/notifications');
            dispatch({type: GET_NOTIFICATIONS, payload: res.data});

        } catch (err) {
            dispatch({type: NOTIFICATION_ERROR, payload: err.response.msg})
        }
    }

     //Get NOTIFICATION

     const getNotification = async (id) => {

        try {
            const res = await axios.get(`/api/notifications/${id}`);
            dispatch({type: SET_CURRENT_NOTIFICATION, payload: res.data});

        } catch (err) {
            dispatch({type: NOTIFICATION_ERROR, payload: err.response.msg})
        }
    }

    // Add NOTIFICATION
    const addNotification= async notification => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/notification', notification, config);
            dispatch({type: ADD_NOTIFICATION, payload: res.data});

        } catch (err) {
            dispatch({type: NOTIFICATION_ERROR, payload: err.response.msg})
        }
    }

    const updateNotification = async notification => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/notification/${notification._id}`, notification, config);
            dispatch({type: UPDATE_NOTIFICATION, payload: res.data});
        } catch (err) {
            dispatch({type: NOTIFICATION_ERROR, payload: err.response.msg})
        }
    }

    // set current contact
    const setCurrent = notification => {
        dispatch({type: SET_CURRENT_NOTIFICATION, payload: notification});
    }

    // set current contact
    const clearNotification = () => {
        dispatch({type: CLEAR_CURRENT_NOTIFICATION});
    }

    return (
        <notificationContext.Provider value ={{
            Notifications: state.Notifications,
            current: state.current,
            filtered: state.filtered,
            error: state.error,
            loading: state.loading,
            getNotification,
            getNotifications,
            setCurrent,
            clearNotification,
            addNotification,
            updateNotification
            }}>
            {props.children}
        </notificationContext.Provider>
    )
}

export default NotificationState;