import React,{useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import moment from "moment";
import {Col} from "react-bootstrap";


import '../../styles/main.scss';

const BookingItem = ({booking, isAdmin}) => {

    let navigate = useNavigate(); 
    const {title, startDate} = booking || {};

    const viewBooking = (e) => {
        e.preventDefault();
        navigate(`/bookings/booking/${booking._id}`);
    }

    const [clientView, setClientView] = useState(false);

    return (

        <Col xs='6' md='4' className='p-2 justify-content-between'>
            <div className='session-inner session-inner--hover p-3 pb-5 h-100 bg-blue position-relative cta' onClick={viewBooking}>
                <div className='session-status mb-2'>Booking</div>
                <h4>{title}</h4>
                <div className='position-absolute pb-3 bottom-0'>
                    {moment(startDate).format('DD MMMM YYYY')}

                </div>
            </div>
        </Col>
       
    )

}

export default BookingItem;