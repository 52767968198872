import React, {useState, useContext, useEffect} from 'react'

import moment from "moment";
import BookingSessionItemAdmin from './bookingSessionItem/BookingSessionItemAdmin';
import BookingSessionItem from './bookingSessionItem/BookingSessionItem';

const BookSessionDay = ({bookingSessionDay, title, isAdmin, currentBooking, delegates, toggleRequests, requests}) => {

    useEffect( () =>{
        // eslint-disable-next-line
    },[])

    
    //const {booked} = currentBooking || {};

    return (

        <div className='col-6 col-md-4 col-lg-4 mb-5' >
            <h4>{moment(title).format('D MMMM yyyy')}</h4>
            <div>
                {isAdmin ? (
                    bookingSessionDay.map(bookingSession => <BookingSessionItemAdmin key={bookingSession._id} bookingSession={bookingSession} booking={currentBooking} delegatesList={delegates}/>)               
                ):(
                    bookingSessionDay.map(bookingSession => <BookingSessionItem key={bookingSession._id} id={bookingSession._id} bookingSession={bookingSession} booking={currentBooking} delegatesList={delegates} toggleRequests={toggleRequests} requests={requests}/>)
                )}
            </div>
        </div>

    )

}

export default BookSessionDay