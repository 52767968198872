import React, {useState, useContext, useEffect} from 'react'

import BookingSessionContext from '../../../context/bookingSession/bookingSessionContext';
import Multiselect from 'multiselect-react-dropdown';

import {Button, Container, Form,Row, Col} from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const BookingSessionItemDelegatesPicker = ({delegates, delegatesList, bookingSession, onDelete}) => {


    const bookingSessionContext = useContext(BookingSessionContext);

    const {updateBookingSession} = bookingSessionContext;

    const [selectedDelegates, setSelectedDelegates] = useState([]);
    const [otherDelegates, setOtherDelegates] = useState([]);

    const [showAddForm, setShowAddForm] = useState(false);
        
        useEffect( () =>{

            setSelectedDelegates(delegates.map(del => ({...del, toInclude: true})));
    
        },[delegates])
        
        useEffect( () =>{
            setOtherDelegates(delegatesList.filter(del => selectedDelegates.filter(x => x._id === del._id).length <= 0 ));
        },[selectedDelegates])

        const toggleDelegate = id => {


            setSelectedDelegates( 
                selectedDelegates.map(sd => sd._id === id ? {...sd, toInclude: !sd.toInclude} : sd)
            )
        }

        const moveToSelected = (delegate) =>{
            setSelectedDelegates([...selectedDelegates, {...delegate, toInclude:true}]);
        }

    const onSubmit = () =>{
        const toSubmit = selectedDelegates.filter(td => td.toInclude);
        //push all
        console.log('submit callled');
        updateBookingSession({...bookingSession, delegates:toSubmit});
    }

    const toggleAddForm = (e) =>{
        e.preventDefault();
        setShowAddForm(!showAddForm);
    }

    return(
<>
        <div className='mb-2'>
            {selectedDelegates.map((del,index )=> 
                <div key={del._id} onClick={()=>toggleDelegate(del._id)}> {++index}: {del.name} {del.lastName} {del.toInclude ? <span className='text-success'>&#10003;</span>:<span className='text-danger'>&#10005;</span>} </div>
                )}
        </div>

        <div className='d-flex justify-content-between mb-2'>
            <button className='btn btn-primary' onClick={()=>onSubmit()}>Save</button>
            <button className='btn btn-primary' onClick={()=>onSubmit()}>Create session</button>
        </div>

        <div className='text-end'>
            <a onClick={(e)=>toggleAddForm(e)}>More options  {showAddForm ? <span>&#8963;</span>:<span>&#8964;</span>}</a>
        </div>
        {showAddForm && <div>
            <div className='mb-1'>Add delegates:</div>
            {otherDelegates.length ? otherDelegates.map((del )=> 
               <div key={del._id} className='pb-1' onClick={()=>moveToSelected(del)}> {del.name} {del.lastName} <span>&#43;</span></div>
               )
               :
               <div>no more delegates</div>
            }

            <div className="edit-btns text-end">
                <button className='btn btn-del px-2' onClick={(e) => { if (window.confirm('Are you sure you wish to delete this booking session?')) onDelete(e)}} data-tooltip-id="del-tooltip" data-tooltip-content="Delete Booking Session"> Delete Session</button>
                <Tooltip id="del-tooltip" />
            </div>


       </div>}
        
      
     
    </>
        
    )
}
 
export default BookingSessionItemDelegatesPicker