import React,{useContext, useState} from 'react';
import PropTypes from 'prop-types';
//import SessionContext from '../../context/session/sessionContext';
import moment from "moment";
import {useNavigate} from 'react-router-dom';

import {Col} from "react-bootstrap";

import STATUS from '../../utils/status';

import '../../styles/main.scss';

const SessionItem = ({session, isAdmin}) => {

    let navigate = useNavigate(); 
    //const sessionContext = useContext(SessionContext);

    //const {setCurrent, clearCurrent } =sessionContext;

    const {title, startDate, status} = session;

    const viewSession = (e)=>{
        e.preventDefault();
        navigate(`/sessions/session/${session._id}`);
    }

    const editSession = (e) =>{
        e.preventDefault();
        navigate(`/session/edit/${session._id}`);
    }

    return (
        <Col xs='6' md='4' className='p-2 justify-content-between'>
            <div className='session-inner session-inner--hover p-3 pb-5 h-100 bg-blue position-relative cta' onClick={viewSession}>
                <div className='session-status mb-2'>{STATUS[status]}</div>
                <h4>{title}</h4>
                <div className='position-absolute pb-3 bottom-0'>
                    {moment(startDate).format('DD MMMM YYYY')}
                </div>
            </div>
        </Col>
    )
}

SessionItem.propTypes = {
    session: PropTypes.object.isRequired,
}

export default SessionItem