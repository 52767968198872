import React, {useContext,useEffect, useState,useRef} from 'react';
import {Button, Card, Row, Col, Form} from "react-bootstrap";
import SessionContext from '../../context/session/sessionContext';
import AuthContext from '../../context/auth/authContext';
import {useNavigate} from 'react-router-dom';
import SessionItem from './SessionItem';
import SessionItemAdmin from './SessionItemAdmin';
import SessionSidebarStatus from './SessionSidebarStatus';

import Pagination from '../pagination/Pagination';

import '../../styles/sessions.scss';
import '../../styles/pagination.scss';
import paginate from '../../utils/paginate';

const Sessions = ({isAdmin}) => {
    
    const authContext = useContext(AuthContext);
    const sessionContext = useContext(SessionContext);

    const {sessions, getSessions, clearSession, loading, filtered,setStatus,currentStatus} = sessionContext;
    const {user} = authContext;

    const [viewSessions, setViewSessions] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [filterValue, setFilterValue] = useState(false);

    const [adminView, setAdminView] = useState(false);

    let navigate = useNavigate();

    const [viewOwnSessions, SetViewOwnSessions] = useState(false);

    useEffect(() =>{
        authContext.loadUser();

        if(sessions=== null || !sessions.length|| currentStatus === 'all'){
            getSessions();
        }

        if(isAdmin){
            setAdminView(true);
            //setItemsPerPage(15);
        }  
        // eslint-disable-next-line
    },[])


    useEffect(() =>{
        if(currentStatus && currentStatus !== 'all'){
            setStatus(currentStatus);
        }
        if(sessions && sessions.length){

            let mPage = parseInt(sessions.length / paginationInfo.pageSize);
            if(sessions.length % paginationInfo.pageSize) mPage++;

            SetPaginationInfo({...paginationInfo,maxPage:mPage});
        }

    },[sessions])

    useEffect(() =>{

        if(viewOwnSessions){
            setViewSessions(filtered.filter(pr => (pr.projectManager && pr.projectManager._id===user._id)||(pr.trainers.map(tr => tr._id ).includes(user._id))));
            //const newPageCount = viewSessions ? Math.ceil(viewSessions.length / itemsPerPage) : 0;
            //setPageCount( newPageCount );
        }
        else{
            setViewSessions(filtered);
            //const newPageCount = viewSessions ? Math.ceil(viewSessions.length / itemsPerPage) : 0;
            ////setPageCount( newPageCount );   
        }
        
    },[filtered,viewOwnSessions])


    const [paginationInfo, SetPaginationInfo] = useState(
                                                    {
                                                        pageSize: 15,
                                                        currentPage: 0,
                                                        maxPage: 1,
                                                        
                                                    }
                                                );


    const newSession = (e)=>{
        e.preventDefault();
        clearSession();
        navigate('/sessions/new');
    }

    const requestSession = (e)=>{
        e.preventDefault();
        clearSession();
        navigate('/sessions/request');
    }

    const text = useRef('');

    const onFilterChange = (e) =>{
        e.preventDefault();
        const match = e.target.value;
        if(match!==null && !match.length){
            setFilterValue(false);
            setFilteredSessions([]);
            return;
        }
        if(match.length > 1){
            setFilterValue(true);
            const filtered = viewSessions.filter(session => {
                const regex = new RegExp(e.target.value,'gi');
                return session.title.match(regex) || session.description.match(regex);
            });
            setFilteredSessions(filtered);
        }

    }

    const mapSessionsAdmin = (sessions2) =>{
    
        const offset = paginationInfo.currentPage * paginationInfo.pageSize;
        const offset2 = offset + paginationInfo.pageSize;
        const sessions3 = sessions2.slice(offset, offset2);

        return sessions3.map(session => <SessionItemAdmin key={session._id} session={session} isAdmin={isAdmin}/>)
    }

    const mapSessions = (sessions2) =>{
        // pagination
        return sessions2.map(session => <SessionItem key={session._id} session={session} isAdmin={isAdmin}/>)
    }

    const setCurrentPage = (newCurrentPage) =>{
        SetPaginationInfo({...paginationInfo, currentPage: newCurrentPage});
    }

    if(sessions !== null && sessions.length === 0 && !loading){
        return (
                <Row className='pb-1 border-bottom mb-4'>
                    <Col xs="6">                    
                        <h1>Sessions</h1>
                    </Col>
                    <Col xs="6"  className='d-flex align-items-end justify-content-end'>
                        { isAdmin && <Button onClick={newSession} >Add Session</Button> }
                        { (user && user.role == '3') &&
                            <Button onClick={requestSession}>Request Session</Button>
                        }
                    </Col>
                </Row>
        )
    }

    if(adminView){
        return (
            <>
            <Row className='pb-1 border-bottom mb-4'>
                <Col xs="6">                    
                    <h1>Sessions</h1>
                </Col>
                {isAdmin &&
                    <Col xs="6" className='mb-2 text-end d-flex align-items-end justify-content-end'>
                        <Form.Group className="mb-1 pe-4 d-inline-block align-self-baseline mt-auto">
                            <label htmlFor='view-own-sessions'>My Sessions</label>
                            <Form.Check 
                                type="switch"
                                name="viewOwnSessions"
                                checked={viewOwnSessions ?? false}
                                value={viewOwnSessions ?? false}
                                onChange= {() => SetViewOwnSessions(!viewOwnSessions)}
                                className='d-inline-block me-4'
                                id='view-own-sessions'
                                />

                            <label htmlFor='admin-view'>Admin view</label>
                            <Form.Check                             
                                type="switch"
                                name="adminView"
                                checked={adminView ?? false}
                                value={adminView ?? false}
                                onChange= {() => setAdminView(!adminView)}
                                className='d-inline-block'
                                id='admin-view'
                                />     
                            </Form.Group>
                        <button className='btn btn-primary btn-session-top' onClick={newSession}>Add Session</button> 
                    </Col>
                }
            </Row>
          
            <Row>
                <Col xs='12' md='3' lg='2'>
                    {<SessionSidebarStatus/>}
                </Col>
                <Col xs='12' md='9' lg='10'>                    
                    <Row>
                    <form className='text-primary py-2 px-1 justify-content-between d-block' onSubmit={e=>(e.preventDefault())}>
                        <input className="d-block w-100 p-2" ref={text} type="text" placeholder='Filter sessions' onChange={e=>onFilterChange(e)}/>
                    </form>
                        <Row className='row-border'>
                            <Col xs={2} className="py-1">
                                <b>Date</b>
                            </Col>
                            <Col xs={10}  className="py-1 d-flex">
                                <div className="col col-md-5"><b>Client</b></div>
                                <div className="col col-md-7"><b>Description</b></div>
                            </Col>
                        </Row>

                    {sessions !==null && sessions.length && !loading ? (
                        filterValue ?
                            filteredSessions.length ? 
                                mapSessionsAdmin(filteredSessions)
                                : <div>No matching sessions</div>
                                    
                            : viewSessions!==null && viewSessions.length ? mapSessionsAdmin(viewSessions ) 
                                : 
                                <div>No sessions</div>
                        ):
                        <div>Loading</div>
                    }
                    </Row>

                    <Pagination pageSize={paginationInfo.pageSize} currentPage={paginationInfo.currentPage} maxPage={paginationInfo.maxPage} setCurrentPage={setCurrentPage}/>

                  
                </Col>
               
            </Row>
            </>
        )
    }
    else{
        return (
            <>    
                <Row className='pb-1 border-bottom mb-4'>
                    <Col xs="6">                    
                        <h1>Sessions</h1>
                    </Col>
                    
                    <Col xs="6" className='mb-2 text-end d-flex align-items-end justify-content-end'>
                        {isAdmin && 
                            <>
                            <Form.Group className="mb-1 pe-4 d-inline-block align-self-baseline mt-auto">
                            <label htmlFor='view-own-sessions'>My Sessions</label>
                            <Form.Check 
                                type="switch"
                                name="viewOwnSessions"
                                checked={viewOwnSessions ?? false}
                                value={viewOwnSessions ?? false}
                                onChange= {() => SetViewOwnSessions(!viewOwnSessions)}
                                className='d-inline-block me-4'
                                id='view-own-sessions'
                            />    

                            <label htmlFor='admin-view'>Admin view</label>
                            <Form.Check                             
                                type="switch"
                                name="adminView"
                                checked={adminView ?? false}
                                value={adminView ?? false}
                                onChange= {() => setAdminView(!adminView)}
                                className='d-inline-block'
                                id='admin-view'
                            />     
                            </Form.Group>
                            </>
                        }

                        { (user && user.role <= '3') &&
                            <button className='btn btn-primary btn-session-top' onClick={requestSession}>Request Session</button>
                        }
                    </Col>
                </Row>
               
                <Row>
                    <Col xs='12' md='2'>
                        {<SessionSidebarStatus/>}
                    </Col>
                    <Col xs='12' md='10'>
                        <Row>
                        {sessions !==null && sessions.length && !loading ? (
                            filterValue ?
                                filteredSessions.length ? 
                                    mapSessions(filteredSessions)
                                    : <div>No matching sessions</div>
                                        
                                : viewSessions!==null && viewSessions.length ? mapSessions(viewSessions ) 
                                    : 
                                    <div>No sessions</div>
                            ):
                            <div>Loading</div>
                        }
                        </Row>

                    

                    </Col>
                </Row>
            </>
        )
    }
}

export default Sessions