import {
    ADD_ORGANISATION,
    DELETE_ORGANISATION,
    SET_CURRENT_ORGANISATION,
    CLEAR_CURRENT_ORGANISATION,
    UPDATE_ORGANISATION,
    FILTER_ORGANISATIONS,
    CLEAR_ORGANISATION_FILTER,
    ORGANISATION_ERROR,
    GET_ORGANISATIONS,
    CLEAR_ORGANISATIONS,
    CLEAR_ERRORS
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_ORGANISATIONS:
            return {
                ...state,
                organisations: action.payload,
                loading: false
            }
        case SET_CURRENT_ORGANISATION:
            return {
                ...state,
                current: action.payload
            }
        case ADD_ORGANISATION:
            return{
                ...state,
                organisations: [action.payload, ...state.organisations]
            }
        case UPDATE_ORGANISATION:
            return{                
                ...state,
                organisations: state.organisations.map(org => org._id === action.payload._id ? action.payload: org)
            }
        case CLEAR_CURRENT_ORGANISATION:
            return{
                ...state,
                current: null
            }
        case ORGANISATION_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        case DELETE_ORGANISATION:
            return{
                ...state,
                organisations: state.organisations.filter(organisation => organisation._id !== action.payload)
            }
        default:
            return state;
    }
}