import React, {useEffect, useState,useContext} from 'react'

import { Button, Form,Row, Col} from "react-bootstrap";
import UserContext from '../../context/user/userContext';
import SessionContext from '../../context/session/sessionContext';

import FileContext from '../../context/file/fileContext';

import Multiselect from 'multiselect-react-dropdown';

const FileEditForm = ({file, setShowForm }) => {

    const [clientValue, setClientValue] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const [clientId, setClientId] = useState(null);

    const [delegateValue, setDelegateValue] = useState(null);
    const [delegateOptions, setDelegateOptions] = useState([]);
    const [delegateId, setDelegateId] = useState(null);

    const [sessionValue, setSessionValue] = useState(null);
    const [sessionOptions, setSessionOptions] = useState([]);
    const [sessionId, setSessionId] = useState(null);

    const [clientsList, setClientsList] = useState([]);
    const [clientsSubmitList, setClientsSubmitList] = useState([]);
    const [delegatesList, setDelegatesList] = useState([]);
    const [delegatesSubmitList, setDelegatesSubmitList] = useState([]);
    const [sessionsList, setSessionsList] = useState([]);
    const [sessionsSubmitList, setSessionsSubmitList] = useState([]);

    const userContext = useContext(UserContext);
    const {getUsers, users} = userContext;

    const sessionContext = useContext(SessionContext);
    const {getSessions, sessions} = sessionContext;

    const fileContext = useContext(FileContext);
    const {updateFile} = fileContext;

    const [fileData,setFileData] = useState({
        sessionsList:[],
        clientsList:[],
        delegatesList:[]
    });

    const onSessionsSelect = (sessions) => {
        let values = sessions.map(session => session.value);
        setSessionsList(sessions);
        setSessionsSubmitList(values);
        setFileData({...fileData, sessions:values});
    }

    const onClientsSelect = (clients) => {
        let values = clients.map(client => client.value);
        setClientsList(clients);
        setClientsSubmitList(values);
        setFileData({...fileData, clients:values});
    }

    const onDelegatesSelect = (delegates) => {
        let values = delegates.map(delegate => delegate.value);
        setDelegatesList(delegates);
        setDelegatesSubmitList(values);

        setFileData({...fileData, delegates:values});
    }

    useEffect(() =>{  
        if(users===null || !users.length){
            getUsers();
        }
        if(sessions===null || !sessions.length){
            getSessions();
        }

        if(file){
            if(file.clients){
                setClientsList(file.clients.map( client => ({value: client._id, label: `${client.name} ${client.lastName}`})));
            }
            if(file.delegates){
                setDelegatesList(file.delegates.map( delegate => ({value: delegate._id, label: `${delegate.name} ${delegate.lastName} - ${delegate.email}`})));
            }
            if(file.sessions){
                setSessionsList(file.sessions.map( session => ({value: session._id, label: `${session.title}: ${session.description}`})));
            }
            setFileData(file);
        }

        // eslint-disable-next-line
    },[])

    useEffect( () =>{

        if(users && users.length){
            setClientOptions( users.filter(user => user.role===3).map(user => ({value: user._id, label: `${user.name} ${user.lastName}`})));
            setDelegateOptions( users.filter(user => user.role===4).map(user => ({value: user._id, label: `${user.name} ${user.lastName} - ${user.email}`})));
        }
    
    },[users])

    useEffect( () =>{

        if(sessions && sessions.length){
            setSessionOptions(sessions.map(session => ({value: session._id, label: `${session.title}: ${session.description}`})));
        }
    
    },[sessions])

  

    const onSubmit = (e) =>{
        e.preventDefault();
        updateFile(fileData);
        if(setShowForm){
            setShowForm(false);
        }
    }

    return(
        <Form onSubmit={onSubmit}>
            <Row className='py-2'>
                <Col xs={12} >
                    <Form.Label>Clients</Form.Label>
                    <Form.Group  className="mb-3">
                        <Multiselect
                            options={clientOptions} // Options to display in the dropdown
                            selectedValues={clientsList} // Preselected value to persist in dropdown
                            onSelect={onClientsSelect} // Function will trigger on select event
                            onRemove={onClientsSelect} // Function will trigger on remove event
                            displayValue='label' // Property name to display in the dropdown options
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} >
                    <Form.Label>Delegates</Form.Label>
                    <Form.Group  className="mb-3">
                        <Multiselect
                            options={delegateOptions} // Options to display in the dropdown
                            selectedValues={delegatesList} // Preselected value to persist in dropdown
                            onSelect={onDelegatesSelect} // Function will trigger on select event
                            onRemove={onDelegatesSelect} // Function will trigger on remove event
                            displayValue='label' // Property name to display in the dropdown options
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} >
                    <Form.Label>Sessions</Form.Label>
                    <Form.Group  className="mb-3">
                        <Multiselect
                            options={sessionOptions} // Options to display in the dropdown
                            selectedValues={sessionsList} // Preselected value to persist in dropdown
                            onSelect={onSessionsSelect} // Function will trigger on select event
                            onRemove={onSessionsSelect} // Function will trigger on remove event
                            displayValue='label' // Property name to display in the dropdown options
                        />
                    </Form.Group>
                </Col>

                </Row>
                <Row className='mb-5'>

                    <Col>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        &nbsp;
                        <Button variant="primary" onClick={()=>setShowForm(false)}>
                            Close
                        </Button>
                    </Col>
            </Row>
        </Form>

    )
}

export default FileEditForm;