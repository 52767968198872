import {
    ADD_SERVICE,
    GET_SERVICES,
    GET_ALL_SERVICES,
    UPDATE_SERVICE,
    SERVICE_ERROR,
    DELETE_SERVICE,
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_SERVICES:
            return {
                ...state,
                services:action.payload
            }

        case UPDATE_SERVICE:
            return {
                ...state,
                services: state.services.map(service => service._id === action.payload._id ? action.payload: service)
            }
        case ADD_SERVICE:
            return {
                ...state,
                services:[action.payload, ...state.services]
            }
        case DELETE_SERVICE:
            return{
                ...state,
                services: state.services.filter(service => service._id !== action.payload)
            }
        default:
            return state;
        
    }
}
