import React,{useReducer} from "react";
import axios from "axios";
import organisationContext from "./organisationContext";
import organisationReducer from './organisationReducer';

import {
    ADD_ORGANISATION,
    DELETE_ORGANISATION,
    SET_CURRENT_ORGANISATION,
    CLEAR_CURRENT_ORGANISATION,
    UPDATE_ORGANISATION,
    FILTER_ORGANISATIONS,
    CLEAR_ORGANISATION_FILTER,
    ORGANISATION_ERROR,
    GET_ORGANISATIONS,
    CLEAR_ORGANISATIONS,
    CLEAR_ORGANISATION_ERROR,
    CLEAR_ERRORS
} from '../types';


const OrganisationState = props => {
    const initialState ={
        organisations: null,
        current: null,
        filtered: null,
        error: null,
        loading: false
    }

    const[state,dispatch] = useReducer(organisationReducer, initialState);

    const getOrganisations = async () => {

        try {
            const res = await axios.get('/api/organisations');
            dispatch({type: GET_ORGANISATIONS, payload: res.data});
        } catch (err) {
            dispatch({type: ORGANISATION_ERROR, payload: err.response.msg});
        }
    }

    const getOrganisation = async (id) => {

        try {
            const res = await axios.get(`/api/organisations/${id}`);
            dispatch({type: SET_CURRENT_ORGANISATION, payload: res.data});
        } catch (err) {
            dispatch({type: ORGANISATION_ERROR, payload: err.response.msg});
        }
    }

    const addOrganisation = async organisation => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/organisations', organisation, config);
            dispatch({type: ADD_ORGANISATION, payload: res.data});
        } catch (err) {
            dispatch({type: ORGANISATION_ERROR, payload: err.response.msg});
        }
    }

    const updateOrganisation = async organisation => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/organisations/${organisation._id}`, organisation, config);
            dispatch({type: UPDATE_ORGANISATION, payload: res.data});
        } catch (err) {
            dispatch({type: ORGANISATION_ERROR, payload: err.response.msg});
        }
    }

    const setCurrent = organisation => {
        dispatch({type: SET_CURRENT_ORGANISATION, payload: organisation});
    }

    const clearOrganisation = () => {
        dispatch({type: CLEAR_CURRENT_ORGANISATION});
    }

    const deleteOrganisation = async id => {
        try {
            await axios.delete(`/api/organisations/${id}`);
            dispatch({type: DELETE_ORGANISATION, payload: id});
        } catch (err) {
            dispatch({type: ORGANISATION_ERROR, payload: err.response.msg});
        }
    }

    const clearErrors = () => dispatch({type:CLEAR_ERRORS});

    return (
        <organisationContext.Provider value ={{
            organisations: state.organisations,
            current: state.current,
            filtered: state.filtered,
            error: state.error,
            loading: state.loading,
            getOrganisation,
            getOrganisations,
            setCurrent,
            clearOrganisation,
            deleteOrganisation,
            addOrganisation,
            updateOrganisation,
            clearErrors
            }}>
            {props.children}
        </organisationContext.Provider>
    )
}

export default OrganisationState;