import React, {useState, useContext,useEffect, useRef}  from 'react';

import AuthContext from '../../context/auth/authContext';
import TeamContext from '../../context/team/teamContext';
import UserContext from '../../context/user/userContext'; 

import AlertContext from '../../context/alert/alertContext';
import {Button, Form,Row, Col} from "react-bootstrap";

import Multiselect from 'multiselect-react-dropdown';


const TeamForm = ({setShowForm}) => {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const teamContext = useContext(TeamContext);

    const {getTeam,updateTeam,team} = teamContext;
    const {getUsers, users} = userContext;

    const [trainersList, setTrainersList] = useState([]);

    const [teamData, setTeamData] = useState({
        members:null
    })

    const {members} = teamData || {};
  
    useEffect(() =>{
        authContext.loadUser();
        if(!users){
            getUsers();
        }
        getTeam();
        // eslint-disable-next-line
    },[])

    useEffect( () =>{

        if(users && users.length >0){
            let list =users.filter(u => (u.isCoach || u.isProjectManager) );
            setTrainersList(list);

        }

    },[users])

    useEffect( () =>{
        if(team){
            setTeamData({
                ...team
            }
            );
          
        }

    },[team,teamContext])

    const onSelect = (trainers) => {
        setTeamData({...teamData, members: trainers });
    }
    const onSubmit = (e) => {
        e.preventDefault();
        updateTeam(teamData);
        //close form
    }

    const closeForm = (e) => {
        e.preventDefault();
        setShowForm(false);
    }

    return(
    
            <Row>
        
                <Col xs={12}>
                <Form onSubmit={onSubmit}>
                    <Form.Label>Trainers</Form.Label>
                                <Form.Group  className="mb-3">
                                    <Multiselect
                                        options={trainersList} // Options to display in the dropdown
                                        selectedValues={members} // Preselected value to persist in dropdown
                                        onSelect={onSelect} // Function will trigger on select event
                                        onRemove={onSelect} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                </Form.Group>

                    <Form.Group className="mb-3">
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        &nbsp;
                        <Button onClick={closeForm} className="btn btn-primary my-1">
                            Close
                        </Button>   
                    </Form.Group>
                </Form>
                </Col>
            </Row>

    )

}

export default TeamForm