import React, {useState} from 'react';
import {Row, Col, Button,Tabs, Tab, Form} from "react-bootstrap";
import Images from './Images';
import Files from './Files';
import Videos from './Videos';


const Media = ({isAdmin}) => {

    const [viewFilesTab, setViewFilesTab] = useState('file');
    //const [viewFilesTab, setViewFilesTab] = useState('video');

    const [showForm, setShowForm] = useState(false);


    const [viewVideoList, SetViewVideoList] = useState(false);

    return(
        <>
        <Row className='pb-1 mb-5 border-bottom'>
            <div className='col-12 col-md-6'> 
                <h1>Media</h1>
            </div>
            <div className='mb-2 text-end d-flex align-items-end justify-content-end col-12 col-md-6'> 
                
                {isAdmin && viewFilesTab==='video' &&
                <Form.Group className="mb-1 pe-4 d-inline-block align-self-baseline mt-auto">
                            <label htmlFor='view-video-list'>View List</label>
                            <Form.Check 
                                type="switch"
                                name="viewOwnSessions"
                                checked={viewVideoList ?? false}
                                value={viewVideoList ?? false}
                                onChange= {() => SetViewVideoList(!viewVideoList)}
                                className='d-inline-block me-4'
                                id='view-video-list'
                                />
                </Form.Group>
                }
                { isAdmin && <Button onClick={()=>setShowForm(true)} className={`btn btn-primary btn-session-top ${showForm && 'disabled'}`}>Add {viewFilesTab}</Button> }

            </div>
        </Row>
        <Row className='pb-1 mb-5'>
            <Col md={3} lg={2}>
                <div className="pt-2">
                    <a className={`d-block mb-2 px-3 py-1 position-relative session-sidebar-link${viewFilesTab ==='file' ? ' session-sidebar-link--active':''}`} onClick={()=>{setViewFilesTab('file')}}><span className="status-menu">Files</span></a>
                    {isAdmin && <a className={`d-block mb-2 px-3 py-1 position-relative session-sidebar-link${viewFilesTab === 'image' ? ' session-sidebar-link--active':''}`} onClick={()=>{setViewFilesTab('image')}}><span className="status-menu" >Images</span></a>}
                    <a className={`d-block mb-2 px-3 py-1 position-relative session-sidebar-link${viewFilesTab === 'video' ? ' session-sidebar-link--active':''}`} onClick={()=>{setViewFilesTab('video')}}><span className="status-menu" >Videos</span></a>
                </div>
            </Col>
            <Col md={9} lg={10}>
                {viewFilesTab==='file' && <Files isAdmin={isAdmin} showForm={showForm} setShowForm={setShowForm}/>}
                {viewFilesTab==='image' && <Images isAdmin={isAdmin} showForm={showForm} setShowForm={setShowForm}/>}
                {viewFilesTab==='video' && <Videos isAdmin={isAdmin} showForm={showForm} setShowForm={setShowForm} viewVideoList={viewVideoList}/>}
            </Col>
        </Row>
        </>
    )

}

export default Media;