import React,{useContext, useState} from 'react';
import PropTypes from 'prop-types';

import FileContext from '../../context/file/fileContext';

import {Col} from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ImageItem = ({image}) => {

    const fileContext = useContext(FileContext);

    const {deleteImage} = fileContext;

    const {name, path, url, _id} = image;

   
    const onDelete = (e) =>{
        e.preventDefault();
        deleteImage(_id);
    }

  
        return (
        <Col xs='2' className='p-2 justify-content-between position-relative image-item'>

            <img src={url}/>
      
            <button className='btn btn-del px-2 m-2 position-absolute' onClick={(e) => { if (window.confirm('Are you sure you wish to delete this file?')) onDelete(e)}} data-tooltip-id="del-tooltip" data-tooltip-content="Delete Image"> Delete Session</button>
                <Tooltip id="del-tooltip" />
        </Col>
        )
    
}

ImageItem.propTypes = {
    image: PropTypes.object.isRequired,
}

export default ImageItem