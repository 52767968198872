import React, {useContext,useEffect} from 'react';
import SessionContext from '../../context/session/sessionContext';
import VideoContext from '../../context/video/videoContext';

import { Container,Row} from "react-bootstrap";

const VideosView = () => {

    const sessionContext = useContext(SessionContext);
    const videoContext = useContext(VideoContext);

    const {current} = sessionContext;
    const {getAllVideos, videos} = videoContext;


    useEffect(() =>{
      getAllVideos();
    
      // eslint-disable-next-line
    },[current,sessionContext])

    return (

      <Container>
        <Row>

              {(videos !==null && current && current._id)? (
                videos.length ?
                videos.filter(video => ((video.session && video.session._id === current._id) 
                              || (video.sessions && video.sessions.filter(session =>(session === current._id || session._id === current._id )).length > 0)) )
                              .reverse().map(video => 
                    <div className='col-12 mb-3 video-container' key={video._id} >
                      <iframe key={video._id}  width="100%" height="100%" src={`https://player.vimeo.com/video/${video.vimeoID}`} allow="autoplay; encrypted-media" allowFullScreen=""></iframe>
                      <h3 className='h6 py-2'>{video.title}</h3>
                    </div>
                    )
                    :
                    <>No videos</>
                    ):
                    <div>Loading</div>
                  }
     
        </Row>
      </Container>
  )
}

export default VideosView