import React,{useReducer} from "react";
import axios from "axios";

import activityContext from "./activityContext";
import activityReducer from './activityReducer';


import {

    ADD_ACTIVITY,
    DELETE_ACTIVITY,
    SET_CURRENT_ACTIVITY,
    CLEAR_CURRENT_ACTIVITY,
    UPDATE_ACTIVITY,
    ACTIVITY_ERROR,
    GET_ACTIVITIES,
    CLEAR_ACTIVITIES,

} from '../types';


const ActivityState = props => {
    const initialState ={
        activities: null,
        current: null,
        filtered: null,
        error: null,
        loading: false
              
    }

    const[state,dispatch] = useReducer(activityReducer, initialState);


    //Get Activities

    const getActivities = async id => {

        try {
            const res = await axios.get(`/api/activities/session/${id}`);
            dispatch({type: GET_ACTIVITIES, payload: res.data});

        } catch (err) {
            dispatch({type: ACTIVITY_ERROR, payload: err.response.msg})
        }
    }

     //Get Activity

     const getActivity = async (id) => {

        try {
            const res = await axios.get(`/api/sessions/${id}`);
            dispatch({type: SET_CURRENT_ACTIVITY, payload: res.data});

        } catch (err) {
            dispatch({type: ACTIVITY_ERROR, payload: err.response.msg})
        }
    }

    // Add activity
    const addActivity = async activity => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/activities', activity, config);
            dispatch({type: ADD_ACTIVITY, payload: res.data});

        } catch (err) {
            dispatch({type: ACTIVITY_ERROR, payload: err.response.msg})
        }
    }

    //
    // Add activity
    const updateActivity = async activity => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/activities/${activity._id}`, activity, config);
            dispatch({type: UPDATE_ACTIVITY, payload: res.data});

        } catch (err) {
            dispatch({type: ACTIVITY_ERROR, payload: err.response.msg})
        }
    }

    // set current activity
    const setCurrent = activity => {
        dispatch({type: SET_CURRENT_ACTIVITY, payload: activity});
    }
    
    // set current activity
    const clearActivity = () => {
        dispatch({type: CLEAR_CURRENT_ACTIVITY});
    }

    // delete activity
    const deleteActivity = async id => {
        try {
            await axios.delete(`/api/activities/${id}`);
            dispatch({type: DELETE_ACTIVITY, payload: id});

        } catch (err) {
            dispatch({type: ACTIVITY_ERROR, payload: err.response.msg})
        }
    }

    return (
        <activityContext.Provider value ={{
            activities: state.activities,
            current: state.current,
            filtered: state.filtered,
            error: state.error,
            loading: state.loading,
            getActivities,
            addActivity,
            updateActivity,
            deleteActivity
            }}>
            {props.children}
        </activityContext.Provider>
    )
}


export default ActivityState;