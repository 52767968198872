import React, {useState, useContext, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {Row} from "react-bootstrap";

import VideoContext from '../../context/video/videoContext';

import VideoItem from './VideoItem';
import VideoItemList from './VideoItemList';
import VideoForm from './VideoForm';

import VideoWindow from './VideoWindow';

import '../../styles/pagination.scss';
import ReactPaginate from 'react-paginate';

const Videos =  ({isAdmin,showForm,setShowForm, viewVideoList}) => {


    const videoContext = useContext(VideoContext);

    const {getAllVideos,videos} = videoContext;

    const navigate = useNavigate();

    useEffect(() =>{
        getAllVideos();
        setFilteredVideos(videos);



        // eslint-disable-next-line
    },[])


    useEffect(() =>{

        if(videos && videos.length){
            const newPageCount = Math.ceil(videos.length / itemsPerPage) ?? 0;
        

        setPageCount( newPageCount );
        }

        // eslint-disable-next-line
    },[videos])

    const [video, setVideo] = useState('');
    const [filename, setFilename]= useState('');
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [filterValue, setFilterValue] = useState(false);

    const [selectedVideo, setSelectedVideo] = useState(null);

    const text = useRef('');

    const onFilterChange = (e) =>{
        e.preventDefault();
        const match = e.target.value;
        if(match!==null && !match.length){
            setFilterValue(false);
            setFilteredVideos([]);
            return;
        }
        if(match.length > 3){
            setFilterValue(true);
            const filtered = videos.filter(video => {
                const regex = new RegExp(e.target.value,'gi');
                return video.title.match(regex);
            });
            setFilteredVideos(filtered);
        }

    }

    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
  // Invoke when user click to request another page.

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % videos.length;
        setItemOffset(newOffset);
    };


    const mapVideosList = (videos2) =>{

        // pagination
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = videos2.slice(itemOffset, endOffset);

        return currentItems.map(video => <VideoItemList key={video.vimeoID} video={video} isAdmin={isAdmin} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo}/>) ;
    }

    const mapVideosItem = (videos2) =>{

        // pagination
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = videos2.slice(itemOffset, endOffset);

        return currentItems.map(video => <VideoItem key={video._id} video={video} isAdmin={isAdmin} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo}/>)
        
    }


    return (
        <>
         {showForm &&
           <Row className='mb-5'>
           
            <VideoForm newVideo={true} setShowForm={setShowForm}/>
               
           </Row>
         }
            
            <Row>
                <Row>
                    <form className='text-primary py-2 px-1 justify-content-between d-block' onSubmit={e => e.preventDefault()}>
                        <input className="d-block w-100 p-2" ref={text} type="text" placeholder='Filter videos' onChange={onFilterChange}/>
                    </form>
                </Row>

                <VideoWindow selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />

                {viewVideoList ?
                (
                    <>
                    <div className="row-border row">
                        <div className="py-1 col-2"><b>Date</b></div>
                        <div className="py-1 d-flex col-9">
                            <div className="col col-md-10"><b>Title</b></div>
                            <div className="col col-md-2"><b>Client</b></div>
                        </div>
                    </div>

                    {videos ?
                        filterValue ? 
                            (filteredVideos && filteredVideos.length) ?
                            mapVideosList(filteredVideos):
                                <div>No matching videos</div>
                        : 
                        mapVideosList(videos):
                        <div>No Videos</div>
                    }
                    </>
                )
                :
                (videos ?
                    filterValue ? 
                        (filteredVideos && filteredVideos.length) ?
                            mapVideosItem(filteredVideos):
                            <div>No matching videos</div>
                    : mapVideosItem(videos):

                    <div>No Videos</div>
                )}

            </Row>
            <Row className={filterValue && 'd-none' }>

                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={false}

                        activeClassName={'item active '}
                        breakClassName={'item break-me '}
                        containerClassName={'pagination'}
                        disabledClassName={'disabled-page'}
                        nextClassName={"item next "}
                        pageClassName={'item pagination-page '}
                        previousClassName={"item previous"}

                    />
            </Row>
        </>
    )

}

export default Videos;