import React, {useEffect, useState,useContext} from 'react'

// import UserContext from '../../context/user/userContext';
// import SessionContext from '../../context/session/sessionContext';

import moment from "moment";
import {Button, Form, Row, Col} from "react-bootstrap";
import BookingSessionContext from '../../context/bookingSession/bookingSessionContext';

import {useParams, useNavigate} from "react-router-dom";

//import Multiselect from 'multiselect-react-dropdown';

const BookingSessionForm = ({newBookingSession, bookingSession, booking, setShowForm }) => {

    const bookingSessionContext = useContext(BookingSessionContext);
    const {addBookingSession,updateBookingSession} = bookingSessionContext;
    let navigate = useNavigate();

    const [bookingSessionData, setBookingSessionData] = useState({
        
        title : booking.title,
        booking: booking._id,
        //start: null
        startDate : moment(booking.startDate).format("YYYY-MM-DD"),
        startTime : booking.startDate,
        endTime : moment(booking.startDate).format("YYYY-MM-DD")
     })

     const {title,startTime, endTime, startDate} = bookingSessionData; 

     useEffect(() =>{
        if(!newBookingSession){
        
            setBookingSessionData(bookingSession);
        }
  
        // eslint-disable-next-line
    },[])




    const onChange = e => {
        setBookingSessionData({...bookingSessionData, [e.target.name] :e.target.value });
    }

    const onSubmit = e =>{
        e.preventDefault();
       
        if(newBookingSession){
            addBookingSession(bookingSessionData);
            clearForm();
            if(setShowForm !== null){
                setShowForm(false);
            }
        }
        else{
            updateBookingSession(bookingSessionData);
            if(setShowForm !== null){
                setShowForm(false);
            }
        }
    }

    const clearForm = () => {
        setBookingSessionData({
            title : '',
            booking: booking._id,
          //  description: '',
          //  session: session._id,
            startTime : booking.startDate,
            endTime : booking.startDate 

        })
    }

    const changeTime = e =>{

        const time = e.target.value.split(":");
    
        setBookingSessionData({
            ...bookingSessionData,
            [e.target.name]: moment(startTime).set('hour',time[0]).set('minute',time[1])
        });
    }

    return (

        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title" name="title" value ={title} onChange={onChange}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Start Date</Form.Label>
                        {//<Form.Control type="time" name="startTime" value={moment(startTime).format("HH:mm")} onChange={changeTime} className="w-50"/>
}
                        <Form.Control  type="date" name="startDate" value ={startDate}  onChange={onChange} />
                    </Form.Group>
                 
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Start Time</Form.Label>
                        {//<Form.Control type="time" name="startTime" value={moment(startTime).format("HH:mm")} onChange={changeTime} className="w-50"/>
}
                        <Form.Control className='mb-2' type="time" name="startTime" value={moment(startTime).format("HH:mm")} onChange={changeTime} />

                        <Form.Label>End Time</Form.Label>
                        {//<Form.Control type="time" name="startTime" value={moment(startTime).format("HH:mm")} onChange={changeTime} className="w-50"/>
}
                        <Form.Control  type="time" name="endTime" value={moment(endTime).format("HH:mm")} onChange={changeTime} />
                    </Form.Group>
                </Col>

                
            </Row>
            
            <Form.Group className="mb-3">
                <Button variant="primary" type="submit">
                    Add
                </Button>
            </Form.Group>
        </Form>
    )

}

export default BookingSessionForm;