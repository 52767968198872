import React, {useContext,useEffect, useState} from 'react';
import {Button, Row,Col} from "react-bootstrap";
import UserContext from '../../context/user/userContext';
import UserFilter from './UserFilter';

import UserItem from './UserItem';
import UserForm from './UserForm';
import UserSidebar from './UserSidebar';
import AuthContext from '../../context/auth/authContext';

import '../../styles/pagination.scss';
import ReactPaginate from 'react-paginate';

const Users = ({isAdmin}) => {

    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const {getUsers, filtered,users, preFiltered} = userContext;
    const {isSuperAdmin} = authContext;

    useEffect(() =>{
        authContext.loadUser();
        getUsers();

        // eslint-disable-next-line
    },[])

    useEffect(() =>{
        const newPageCount =  preFiltered ? Math.ceil(preFiltered.length / itemsPerPage): 0;
        setPageCount( newPageCount ); 
    },[filtered,preFiltered])

    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const [showForm, setShowForm] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(4);

    const openForm = () =>{
        setShowForm(true);
    }
    const closeForm = () =>{
        setShowForm(false);
        getUsers();
    }

    const mapUsers = (users2) =>{

        // pagination
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = users2.slice(itemOffset, endOffset);
    
        return currentItems.map( user => <UserItem key={user._id} user={user} isSuperAdmin={isSuperAdmin}/> );
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % users.length;
        setItemOffset(newOffset);
      };

    return (
        <>
            <Row className='pb-1 mb-4 border-bottom'>
                <Col xs="6">                    
                    <h1>User List</h1>      
                </Col>
                <Col xs="6" className='text-end'>
                    { isAdmin && <Button onClick={openForm}>Add User</Button> }
                </Col>
            </Row>

            {showForm &&
                <Row className='pb-1 mb-4 border-bottom'>
                     <Col xs='12' md='3' lg='2'></Col>
                     <Col xs='12' md='9' lg='10'>    
                        <UserForm newUser={true} closeForm={closeForm}/>
                    </Col>
                </Row>
            }

            <Row>
                <Col xs='12' md='3' lg='2'>
                    <UserSidebar selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}/>
                </Col>
                <Col xs='12' md='9' lg='10'>    

                    <Row>
                        <Col xs="12">  
                            <UserFilter/>
                        </Col>
                        <Col xs="12">  
                            <Row className='border-bottom-bold fw-bold mt-4 py-2'>
                                <div className='col-12 col-md-3'>Name</div>
                                <div className='col-12 col-md-3'>Email</div>
                                <div className='col-12 col-md-3'>Organisation</div>
                                <div className='col-12 col-md-2'>Role</div>
                            </Row>
                        </Col>
                        {preFiltered !==null ? (
                                filtered !==null ? mapUsers(filtered):
                                mapUsers(preFiltered)
                            ):
                            <div>Loading</div>
                        }
                    </Row>

                    <Row>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}

                            activeClassName={'item active '}
                            breakClassName={'item break-me '}
                            containerClassName={'pagination'}
                            disabledClassName={'disabled-page'}
                            nextClassName={"item next "}
                            pageClassName={'item pagination-page '}
                            previousClassName={"item previous"}
                        />
                    </Row>

                </Col>
            </Row>
        </>
    )
}

export default Users;