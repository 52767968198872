import {
    ADD_SESSION,
    DELETE_SESSION,
    SET_CURRENT_SESSION,
    CLEAR_CURRENT_SESSION,
    UPDATE_SESSION,
    FILTER_SESSIONS,
    GET_SESSIONS,
    FILTER_SESSION_STATUS,
    CLEAR_STATUS_FILTER,
    SET_VIEW_FILTERED,
    CLEAR_VIEW_FILTERED,
    UPDATE_STATUS,
    APPLY_FILTERS,
    VIEW_OWN_SESSIONS,
    CLONE_SESSION,
    SESSION_ERROR,
    CLEAR_ERRORS,
    CLEAR_SESSIONS
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_SESSIONS:
            return {
                ...state,
                sessions: action.payload,
                filtered: action.payload,
                xFiltered: null,
                loading: false
            }
        case SET_CURRENT_SESSION:
            return {
                ...state,
                current: action.payload
            }
        case ADD_SESSION:
            return{
                ...state,
                current: action.payload,
                sessions:[action.payload, ...state.sessions],
                filtered:[action.payload, ...state.filtered]
            }
        case UPDATE_SESSION:
            return{
                ...state,
                current: action.payload
            }
        case CLEAR_CURRENT_SESSION:
            return{
                ...state,
                current: null
            }
        case FILTER_SESSION_STATUS:
            return {
                ...state,
                currentStatus: action.payload,
                filtered: state.sessions.filter(session => (session.status === action.payload))
            }
        case VIEW_OWN_SESSIONS:
            return {
                ...state,
                filters: [...state.filters,{key:'client',value:action.payload}]
            }
        case APPLY_FILTERS:
            return {
                ...state,
                filtered: state.sessions.filter(session => state.filters.every(filter => session[filter.key]===filter.value))
            }
        case UPDATE_STATUS:
            return{
                ...state
            }
        case CLEAR_STATUS_FILTER:
            return {
                ...state,
                currentStatus: 'all',
                filters: state.filters.filter(filter => filter.key !== 'status')
            }
        case SET_VIEW_FILTERED:
            return {
                ...state,
                viewFiltered: true
            }
        case CLEAR_VIEW_FILTERED:
            return {
                ...state,
                viewFiltered : false
            }
        
        case DELETE_SESSION:
            return{
                ...state,
                current: null,
                viewFiltered : false,
                sessions: state.sessions.filter(session => session._id !== action.payload),
                filtered: state.filtered.filter(session => session._id !== action.payload)
            }
        case CLONE_SESSION:
            return{
                ...state,
                current: null,
                viewFiltered : false,
                sessions: [action.payload, ...state.sessions],
                filtered: [action.payload, ...state.filtered]
            }
        case FILTER_SESSIONS:
            return {
                ...state,
                xfiltered: state.xFiltered.filter(session => {
                    const regex = new RegExp(`${action.payload}`,'gi');
                    return session.title.match(regex) || session.description.match(regex);
                })
            }
        
        case SESSION_ERROR:
            return {
                ...state,
                error: action.payload
            }
        
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        case CLEAR_SESSIONS:
            return{
                ...state,
                sessions: [],
                filtered: []
            }
            
        default:
            return state;
    }
}