import React, {useState, useContext, useEffect} from 'react'

import moment from "moment";
import BookingSessionContext from '../../../context/bookingSession/bookingSessionContext';

import BookingSessionItemDelegatesPicker from './BookingSessionItemDelegatesPicker';


const BookingSessionItemAdmin = ({bookingSession, booking, isAdmin}) => {

    const bookingSessionContext = useContext(BookingSessionContext);
    const {deleteBookingSession} = bookingSessionContext;
    const {title,startTime, endTime, startDate,delegates} = bookingSession;
    const [delegatesList, SetDelegatesList] = useState([]);
    const [displayDelegates, setDisplayDelegates] = useState([]);

    useEffect(() =>{

        if(!booking || !booking.delegates.length) return;

        let delegatesData = booking.delegates.map(el => ({ ...el, fullName: `${el.name} ${el.lastName}`}));

        SetDelegatesList(delegatesData);
        
    },[booking])

    const onDelete = (e)=>{
        e.preventDefault();
        deleteBookingSession(bookingSession._id);
    }

    return (
        <div className='col-12 p-3 my-1 booking-item bg-grey-alt'>
            {title}
            <div className='my-2'>{moment(startTime).format("HH:mm")} - {moment(endTime).format("HH:mm")}</div>
            <BookingSessionItemDelegatesPicker displayDelegates={displayDelegates} bookingSession={bookingSession} delegates={delegates} delegatesList={delegatesList} onDelete={onDelete}/>               
        </div>

    )


}

export default BookingSessionItemAdmin