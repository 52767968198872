import React, {useState, useContext,useEffect}  from 'react';
import {useParams, useNavigate, Link } from "react-router-dom";
import UserContext from '../../context/user/userContext';
import AlertContext from '../../context/alert/alertContext';
import { Button, Form,Row, Col} from "react-bootstrap";
import ROLES from '../../utils/roles.js';

import OrganisationContext from '../../context/organisation/organisationContext';

const UserForm = ({newUser}) => {

    const { id } = useParams();

    const navigate = useNavigate();
    
    const userContext = useContext(UserContext);
    const alertContext = useContext(AlertContext);
    const organisationContext = useContext(OrganisationContext);
    
    const {current, getUserById, addUser, updateUser, error , submitted} = userContext;
    const {setAlert} = alertContext;
    const {organisations,getOrganisations} = organisationContext;
    
    const [user, setUser] = useState({
        name:'',
        email:'',
        role:'',
        organisation: null,
        password: ''
    })

    let {name, email, role, organisation, password} = user;

    useEffect( () =>{

        if(id !== null && !newUser){
            getUserById(id);
        }
        if(organisations===null || !organisations.length){
            getOrganisations();            
        }
         // eslint-disable-next-line
    },[])

    useEffect( () =>{
     
        if(current !== null){
            setUser(current);
            ({name, email, role, organisation, password} = user);
        }
        },[current,userContext])
        
    useEffect( () =>{

        if(error !== null){
            setAlert(error, 'danger');
        }

        if(!error && submitted){
            navigate('/users');
        }
    },[error, submitted, userContext])

    const onSubmit = e =>{
        e.preventDefault();
        if(current === null){
            addUser(user);            
        }
        else{
            updateUser(user);
        }
    }

    const onChange = e => {
        setUser({...user, [e.target.name] :e.target.value });
    }

    const isDisabled = () =>{

        return (role != 2 || role != 1);
    }

    return (    
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" name="name" value ={name} onChange={onChange}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email" name="email" value ={email} onChange={onChange}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Role</Form.Label>
                        <Form.Select aria-label="Roles" values={role} name="role" disabled={role === 2 || role === 1} onChange={onChange}>
                            <option>{ ROLES[role] }</option>
                            <option value="3" >Client</option>
                            <option value="4" >Delegate</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Label>Organisation</Form.Label>
                    <Form.Select aria-label="Default select example" values={organisation} name="organisation" onChange={onChange}>
                        <option>{organisation?.title || '' }</option>
                        {organisations && organisations.map(org => <option value={org._id}>{org.title}</option>)}
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>password</Form.Label>
                        <Form.Control type="password" placeholder="password" name="password" value ={password} onChange={onChange}/>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="mb-3">
                <Button variant="primary" type="submit">
                    Submit
                </Button>
                &nbsp;
                <Link to={`/users`} className="btn btn-primary my-1">
                    Back
                </Link>   
            </Form.Group>
        </Form>
    )
}

export default UserForm