import React, {useState, useContext, useEffect}  from 'react';
import {useNavigate} from 'react-router-dom';
import SessionContext from '../../context/session/sessionContext';

const SessionSidebarItem = ({status}) => {

    let navigate = useNavigate();
    const sessionContext = useContext(SessionContext);
    const {setCurrent,sessions, getSessions, currentStatus, setStatus} = sessionContext;
    const [filteredSessions, setFilteredSessions] = useState([]);

    useEffect(() =>{
        if(sessions === null || !sessions.length){
            getSessions();          
        }
    },[sessionContext])

    useEffect(() =>{
        if(sessions !== null && sessions.length){
            setFilteredSessions(sessions.filter(session => (session.status===status)));
        }
    },[sessions])

    const onClick = (e,session) => {
        e.preventDefault();
        setCurrent(session);
        navigate(`/sessions/session/${session._id}`);        
    }

    const changeStatus = (e, status) => {
        e.preventDefault();
        if(status !==currentStatus){
            setStatus(status);
        }
    }

    const truncate = (str) => {return str.length > 22 ? str.substring(0, 22) + "..." : str; }

    return(

        <div>
            <a href="#" className={`d-block px-3 py-1 text-capitalize session-sidebar-link${currentStatus === status ? ' session-sidebar-link--active':''}`} onClick={e=>changeStatus(e,status)}>{status}</a>
            {currentStatus === status && (
                <div>
                    {(filteredSessions !==null && filteredSessions.length > 0) ? 
                        (
                            filteredSessions.slice(0,5).map(
                                fs => <a href="#" className='d-block px-3 session-sidebar-link small bg-blue-alt' key={fs._id} onClick={e=>onClick(e,fs)}>{truncate(fs.title)}</a>
                            )
                        ):
                        <div className='px-3 small bg-blue-alt'>No sessions</div>
                    }                            
                </div>)
            }
        </div>
    )
}

export default SessionSidebarItem;