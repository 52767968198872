import {
   GET_TEAM,
   UPDATE_TEAM,
   TEAM_ERROR,
   CLEAR_ERRORS
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_TEAM:
            return {
                ...state,
                team:action.payload
            }
        case UPDATE_TEAM:
            return {
                ...state,
                team:action.payload
            }
        case TEAM_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state;
    }
}
