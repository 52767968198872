import React, {useContext,useEffect}  from 'react';
import {useNavigate } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';

const NotFoundPage = () => {
    
  const authContext = useContext(AuthContext);
  const {isAuthenticated} = authContext;
    
  let navigate = useNavigate();

  useEffect( () =>{
    console.log('isAuthenticated');
    console.log(isAuthenticated);
    if(!isAuthenticated){
      navigate('/');
    }
    // eslint-disable-next-line
  },[])

  return (
    <>
      <div>NotFoundPage</div>
      <div>Click <a href="/" className='d-inline'>here to return to your Dashboard</a></div>
    </>

  )
}

export default NotFoundPage