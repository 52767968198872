import {
    ADD_VIDEO,
    DELETE_VIDEO,
    SET_CURRENT_VIDEO,
    UPDATE_VIDEO,
    VIDEO_ERROR,
    GET_VIDEOS,
    CLEAR_VIDEOS,
    CLEAR_ERRORS
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_VIDEOS:
            return {
                ...state,
                videos: action.payload,
                loading: false
            }
        
        case SET_CURRENT_VIDEO:
            return {
                ...state,
                current: action.payload
            }

        case UPDATE_VIDEO:
            return{
                ...state,
                videos: state.videos.map(video => video._id === action.payload._id ? action.payload : video)
            }

        case ADD_VIDEO:
            return{
                ...state,
                videos: [action.payload, ...state.videos]
            }

        case DELETE_VIDEO:
            return{
                ...state,
                videos: state.videos.filter(video => video._id !== action.payload)
            }

        case CLEAR_VIDEOS:
            return{
                ...state,
                videos:[]
            }

        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }

        case VIDEO_ERROR:
            return{
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}