import {
    ADD_ACTIVITY,
    DELETE_ACTIVITY,
    SET_CURRENT_ACTIVITY,
    CLEAR_CURRENT_ACTIVITY,
    UPDATE_ACTIVITY,
    ACTIVITY_ERROR,
    GET_ACTIVITIES,
    CLEAR_ACTIVITIES,
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_ACTIVITIES:
            return {
                ...state,
                activities: action.payload,
                loading: false
            }
        
        case SET_CURRENT_ACTIVITY:
            return {
                ...state,
                current: action.payload
            }

        case UPDATE_ACTIVITY:
            return{
                ...state,
                activities: state.activities.map(activity => activity._id === action.payload._id ? action.payload : activity)
            }

        case ADD_ACTIVITY:
            return{
                ...state,
                activities: [...state.activities , action.payload] 
            }

        case DELETE_ACTIVITY:
            return{
                ...state,
                activities: state.activities.filter(activity => activity._id !== action.payload)
            }

        default:
            return state;
    }
}