import React,{useReducer} from "react";
import axios from "axios";
import teamContext from "./teamContext";
import teamReducer from './teamReducer';

import {
    GET_TEAM,
    UPDATE_TEAM,
    TEAM_ERROR
} from '../types';

const TeamState = props => {
    const initialState ={
        members:[],
        //team:[]
    }

    const[state,dispatch] = useReducer(teamReducer, initialState);

    const getTeam = async () => {

        try {
            const res = await axios.get('/api/team');
            dispatch({type: GET_TEAM, payload: res.data});
        } catch (err) {
            dispatch({type: TEAM_ERROR, payload: err.response.msg})
        }
    }

    const updateTeam = async team => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post(`/api/team/`, team, config);
            dispatch({type: UPDATE_TEAM, payload: res.data});
        } catch (err) {
            dispatch({type: TEAM_ERROR, payload: err.response.msg})
        }
    }

    return (
        <teamContext.Provider value ={{
            team: state.team,
            members: state.members,
            getTeam,
            updateTeam
            }}>
            {props.children}
        </teamContext.Provider>
    )
}

export default TeamState;