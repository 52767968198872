import {
    ADD_TEMPLATE,
    GET_TEMPLATES,
    UPDATE_TEMPLATE,
    TEMPLATE_ERROR,
    DELETE_TEMPLATE,
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_TEMPLATES:
            return {
                ...state,
                templates:action.payload
            }

        case UPDATE_TEMPLATE:
            return {
                ...state,
                templates: state.templates.map(template => template._id === action.payload._id ? action.payload: template)
            }
        case ADD_TEMPLATE:
            return {
                ...state,
                templates:[action.payload, ...state.templates]
            }
        case DELETE_TEMPLATE:
            return{
                ...state,
                templates: state.templates.filter(template => template._id !== action.payload)
            }
        default:
            return state;
        
    }
}
