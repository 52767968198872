import React, {useContext,useEffect,useState}  from 'react';
import {BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import {Container, Row, Col, Card, Form, Button, Navbar } from "react-bootstrap";

import AuthContext from '../../context/auth/authContext';

import Alerts from './Alerts';
//import MainMenu from './MainMenu';
import MainNav from './MainNav';
import dashboardBg from '../../img/dashboard-bg.jpg';

const NavContainer = ({children, navType}) =>{

    const authContext = useContext(AuthContext);
    const { isAdmin, isAuthenticated, loading } = authContext;

    const containerStyle = navType ? 'main dashboard-bg':'main';

    const bgStyle = navType ? {
        backgroundImage: `url(${dashboardBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
        
    }: {};

    return (
        <Container fluid className={containerStyle} style={bgStyle}>
            { isAuthenticated && <MainNav isAdmin={isAdmin} navType={navType}/>}
            <Container className='py-5'>
                <div className='alerts'>
                    <Alerts />
                </div>
                {children}
            </Container>
        </Container>
    )
}

export default NavContainer