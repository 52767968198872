const STATUS ={
    'all' : 'All',
    'draft': 'Draft',
    'pending' : 'Pending approval',
    'approved' : 'Approved',
    'completed' : 'Complete',
    'requested' : 'Requested',
    'feedbackPending' : 'Feedback Pending',
    'feedbackApproved' : 'Feedback Approved',
    'feedbackCompleted' : 'Feedback Completed'
}

module.exports = STATUS;