import React, {Fragment, useState, useContext,useEffect} from 'react';

import {Button, Card, Container, ListGroup, Row, Col} from "react-bootstrap";

import OrganisationContext from '../../context/organisation/organisationContext';

import AuthContext from '../../context/auth/authContext';

import OrganisationItem from './OrganisationItem';

import OrganisationForm from './OrganisationForm';

const Organisations = ({isAdmin}) => {
    
    const authContext = useContext(AuthContext);
    const organisationContext = useContext(OrganisationContext);
    const {organisations, getOrganisations,  loading, deleteOrganisation} = organisationContext;
    const [showForm, setShowForm] = useState(false);

    const openForm= () =>{
        setShowForm(true);
    }
    const closeForm = () =>{
        setShowForm(false);
    }

    useEffect(() =>{
        authContext.loadUser();
        getOrganisations();
    },[]);

    const newOrganisation = (e)=>{
        e.preventDefault();
    }

    if(organisations !== null && organisations.length === 0 && !loading){
        return (
            <Row>
                <Card.Header className='justify-content-between d-flex'> 
                    <h3>Empty</h3>
                </Card.Header>
            </Row>
        )
    }

    return (
        <>
            <Row className='pb-1 mb-5 border-bottom'>
                <Col xs="6">                    
                    <h1>Organisations</h1>
                </Col>
                <Col xs="6" className='text-end'>
                    { isAdmin && <Button onClick={openForm}>Add Organisation</Button> }
                </Col>
            </Row>
            <Row>
                {showForm &&
                    <Col xs="12">  
                        <OrganisationForm newOrganisation={true} closeForm={closeForm}/>
                    </Col>
                }

                {organisations !==null ? (<>
                    <Col xs="12">  
                    <Row className='border-bottom-bold fw-bold mt-4 py-2'>
                        <div className='col-12 col-md-4'>Organisation</div>
                        <div className='col-12 col-md-7'>Description</div>
                    </Row>
                </Col>
                       { organisations.map(organisation => <OrganisationItem key={organisation._id} organisation={organisation} isAdmin={isAdmin} deleteOrganisation={deleteOrganisation}/>)}
                    </>):
                    <div>Loading</div>
                }
            </Row>
        </>
    )
}

export default Organisations;