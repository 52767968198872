import React, {useState, useContext,useEffect} from 'react';
import BookingContext from '../../context/booking/bookingContext';
import BookingSessionContext from '../../context/bookingSession/bookingSessionContext';

import AlertContext from '../../context/alert/alertContext';

import BookingSessionDay from './BookingSessionDay';
import BookingSessionForm from './BookingSessionForm';

import { Row, Col} from "react-bootstrap";

const BookingSessions = ({delegates, isAdmin}) => {

    const bookingContext = useContext(BookingContext);
    const bookingSessionContext = useContext(BookingSessionContext);

    const alertContext = useContext(AlertContext);

    const {setAlert} = alertContext;

    const {currentBooking, requestSessions} = bookingContext;
    const {bookingSessions, getBookingSessions} = bookingSessionContext;

    const [bookingDays, setBookingDays] = useState(null);

    const [bookingRequest, setBookingRequest] = useState({
                                                    _id: 0,
                                                    requests:[]    
                                                });
    
    const {requests} = bookingRequest;

    const {delegateSelectionLimit} = currentBooking || {};

    const [showConfirmPanel, setShowConfirmPanel] = useState(false);

    const [localAlert, setLocalAlert] = useState({
     type: 'danger',
     msg: '',
     active: false
    })

    useEffect(() =>{

        if(!currentBooking) return;

        getBookingSessions(currentBooking);
        
    },[currentBooking])

    const updateLocalAlert = (msg,type,active)=>{
        setLocalAlert({
            type,
            msg,
            active
        })
        setTimeout(()=>{setLocalAlert({...localAlert, active:false})}, 5000);
    }


    useEffect(() =>{

        if(bookingSessions.length){
            const temp = Object.groupBy(bookingSessions, ({ startDate }) => startDate);
            setBookingDays(temp);
        }

    },[bookingSessions]) 

    const toggleRequests = sessionId => {

        if(bookingRequest.requests.includes(sessionId)){
            setBookingRequest({
                ...bookingRequest,
                _id: currentBooking._id,
                requests: bookingRequest.requests.filter(req=> req !== sessionId)
            })
        }
        else{
            setBookingRequest({
                ...bookingRequest,
                _id: currentBooking._id,
                requests: [...bookingRequest.requests, sessionId]
            })
        }

     
    }

    const submitRequest = () =>{

        if(requests.length < currentBooking.delegateLowerSelectionLimit){
            //setAlert('Please select more sessions','danger');
            updateLocalAlert('Please select more sessions','danger',true);
            return;
        }
        if(requests.length > currentBooking.delegateSelectionLimit){
            //setAlert('Please remove sessions','danger');
            updateLocalAlert('Please remove sessions','danger',true);
            return;
        }
        //setAlert('Success!!','success');
        //requestSessions(bookingRequest);
        setShowConfirmPanel(true);
        updateLocalAlert('Booking successful!','success',true);
    }

    const confirmRequest = () =>{

        if(requests.length < currentBooking.delegateLowerSelectionLimit){
            setAlert('Please select more sessions','danger');
            return;
        }
        if(requests.length > currentBooking.delegateSelectionLimit){
            setAlert('Please remove sessions','danger');
            return;
        }
        setAlert('Success!!','success');
        requestSessions(bookingRequest);
    }


    return(
        
        <>
        { !isAdmin && currentBooking && currentBooking.locked &&
        <Row className='mb-3'>
            <Col className='mb-3'>
                <p>
                    Thank you for booking.
                </p>
                <p>
                    Your days are:
                </p>

            </Col>
        </Row>}
        { !isAdmin && !showConfirmPanel && currentBooking && !currentBooking.locked &&
        <Row className='mb-3'>
            <Col className='my-3'>
                Please select up to {delegateSelectionLimit} booking sessions
            </Col>
        </Row>
        }

        {localAlert.active &&
            <div className={`alert alert-${localAlert.type}`}>
                <i className='fas fa-info-circle'/> {localAlert.msg}
            </div>
        }
        {!isAdmin && 
            <Row className='mb-5'>
                <Col>
                    {currentBooking && !currentBooking.locked && !showConfirmPanel &&
                        <button  className="btn btn-alt" onClick={()=> submitRequest()}>
                            Submit request
                        </button>
                    }
                </Col>
            </Row>
        }


        { !isAdmin && showConfirmPanel &&
            <Row className='mb-3'>
                <Col className='my-3'>
                    Please confirm the dates and times below: <button className='btn btn-primary ms-3' onClick={()=> setShowConfirmPanel(false)}> Go back </button> <button className='btn btn-alt ms-3' onClick={()=> confirmRequest()}>Submit</button>
                </Col>
            </Row>
        }

            <Row className='mb-3'>
                {bookingDays !==null && Object.values(bookingDays).length ?
                        Object.keys(bookingDays).sort().map( key => 
                            <BookingSessionDay key={key} bookingSessionDay ={bookingDays[key]} title={key} currentBooking={currentBooking} delegates={delegates} toggleRequests={toggleRequests} requests={requests} isAdmin={isAdmin}/> 
                        )
                        :
                        <div>No booking sessions found</div>
                    
                }
            </Row>

            {isAdmin ?? 
                <Row className='border-top mt-5 pt-5'>
                    {currentBooking && <BookingSessionForm booking={currentBooking} newBookingSession={true} setShowForm={null}/>}
                </Row>                               
            }
        </>

    )
  
}

export default BookingSessions