import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import {Container, Navbar, Nav } from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap'

import logo from '../../img/logo.svg';
import logoWhite from '../../img/logo-white.svg';

const MainNav = ({isAdmin, navType}) => {

    const authContext = useContext(AuthContext);
    const {isAuthenticated, isSuperAdmin} = authContext;

    const containerStyle = navType ? 'bg text-white':'bg';
    const variant = navType ? 'dark' : 'light';

    const logoSrc = navType ? logoWhite : logo;

    return (
        <Navbar collapseOnSelect expand='md' variant={variant} className={containerStyle}>
            <Container>
                <Link to='/'><h1 className='font-size-0 my-2'><img src={logoSrc} className='logo' />Bladonmore portal</h1></Link>            
                <Navbar.Toggle aria-controls='navbarCollapse' data-bs-target='#navbarCollapse'/>
                <Navbar.Collapse id='navbarCollapse' className='w-100'>

                <Nav className='justify-content-end'>
                    <LinkContainer to='/'><Nav.Link><span>Home</span></Nav.Link></LinkContainer>
                    {!isAuthenticated && <Nav.Link href='/about'><span>Login</span></Nav.Link>}    
                    <LinkContainer to="/sessions"><Nav.Link><span>Sessions</span></Nav.Link></LinkContainer>
                    <LinkContainer to="/team"><Nav.Link><span>Your team</span></Nav.Link></LinkContainer>                    
                    { isAdmin && <LinkContainer to="/users"><Nav.Link> <span>Users</span> </Nav.Link></LinkContainer>}
                    { isSuperAdmin && <LinkContainer to="/organisations"><Nav.Link> <span>Organisations</span></Nav.Link></LinkContainer>}
                    { isAdmin && <LinkContainer to="/template"><Nav.Link> <span>Templates</span></Nav.Link></LinkContainer>}
                    <LinkContainer to="/media"><Nav.Link> <span>Media</span> </Nav.Link></LinkContainer>
                </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    )
}

export default MainNav