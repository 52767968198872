import {
    ADD_NOTIFICATION,
    DELETE_NOTIFICATION,
    SET_CURRENT_NOTIFICATION,
    CLEAR_CURRENT_NOTIFICATION,
    UPDATE_NOTIFICATION,
    FILTER_NOTIFICATIONS,
    CLEAR_NOTIFICATION_FILTER,
    NOTIFICATION_ERROR,
    GET_NOTIFICATIONS,
    CLEAR_NOTIFICATIONS
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_NOTIFICATIONS:
            return {
                ...state,
                notification: action.payload,
                loading: false
            }
        case SET_CURRENT_NOTIFICATION:
            return {
                ...state,
                current: action.payload
            }
        case ADD_NOTIFICATION:
            return{
                ...state
            }
        case UPDATE_NOTIFICATION:
            return{
                ...state
            }
        case CLEAR_CURRENT_NOTIFICATION:
            return{
                ...state,
                current: null
            }
        default:
            return state;
    }
}