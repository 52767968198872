import React from 'react';
import PropTypes from 'prop-types';
import {Col,Row} from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const VideoWindow = ({selectedVideo, setSelectedVideo }) => {

    const {title,vimeoID, date} = selectedVideo || {};

    const onClick = () => {
        setSelectedVideo(null);
    }
  
    return (
        <Col xs={12}>
            <Row className='video-container-container'>
            {selectedVideo && selectedVideo !==null && 
                <Col xs={12} lg={9} className='my-3 justify-content-between video-container'>

                    <iframe key={selectedVideo._id}  width="100%" height="100%" src={`https://player.vimeo.com/video/${vimeoID}`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen="true"></iframe>
    
                    <h6 className='py-2 position-relative'>{title}
                        <button className='btn btn-close px-2 mt-2 position-absolute top-0 end-0' onClick={(e) => {onClick(e)}} data-tooltip-id="close-tooltip" data-tooltip-content="Close window"> Close window</button>
                        <Tooltip id="close-tooltip" />
                    </h6>
                </Col>
            }
            </Row>
        </Col>
    )
}

VideoWindow.propTypes = {
    video: PropTypes.object.isRequired,
}

export default VideoWindow