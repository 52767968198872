const statusHelper = (status)=>{

    switch(status) {
        case('requested'): 
            return {next:'pending', 
                sessionLabel:'Submit for Approval'};
        case('draft'): 
            return {next:'pending', 
                sessionLabel:'Submit for Approval'};
        case('pending'): 
            return {next:'approved', 
                sessionLabel:'Approve Session'};
        case('approved'): 
            return {next:'feedbackPending', 
                sessionLabel: 'Submit feedback for Approval'}; 
        case('complete'): // not used
            return {next:'feedbackPending', //not used
                sessionLabel:'Submit Feedback'};// not used
        case('feedbackPending'): 
            return {next:'feedbackApproved', 
                sessionLabel:'Approve Feedback'};
        case('feedbackApproved'): // not used
            return {next:'feedbackComplete', // not used
                sessionLabel:'Set Complete'};//Not used
        default: return '';
    }
}

export default statusHelper;