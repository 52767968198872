import React, {useState, useEffect} from 'react'

import moment from "moment";


const BookingSessionDayLocked = ({bookingSessionDay, title}) => {

    useEffect( () =>{

        setBookedSlots(bookingSessionDay.filter(bsd => bsd.booked));

        // eslint-disable-next-line
    },[])


    const [bookedSlots, setBookedSlots] = useState([]);
    

    return (
        <>
            {(bookedSlots && bookedSlots.length) ? 
                (<div className='col-12 mb-1' >
                    <h4>{moment(title).format('D MMMM yyyy')}</h4>
                    <div className='mb-2'>
                        {bookedSlots.map(session => 
                        <div className='col-12'>
                            <div className={`d-inline-block booking-item`}>
                                <span>{moment(session.startTime).format("HH:mm")} - {moment(session.endTime).format("HH:mm")}</span>
                            </div>
                        </div>)}
                    </div>
                </div>)
            :
            (<></>)}
        </>
    )
}

export default BookingSessionDayLocked