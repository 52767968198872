export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const ADD_SESSION = 'ADD_SESSION';
export const DELETE_SESSION = 'DELETE_SESSION';
export const SET_CURRENT_SESSION = 'SET_CURRENT_SESSION';
export const CLEAR_CURRENT_SESSION = 'CLEAR_CURRENT_SESSION';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const FILTER_SESSIONS = 'FILTER_SESSIONS';
export const CLEAR_FILTER = 'CLEAR_SESSION_FILTER';
export const SESSION_ERROR = 'SESSION_ERROR';
export const GET_SESSIONS = 'GET_SESSIONS';
export const CLEAR_SESSIONS = 'CLEAR_SESSIONS';

export const CLONE_SESSION = 'CLONE_SESSION';

export const FILTER_SESSION_STATUS = 'SET_SESSION_STATUS';
export const CLEAR_STATUS_FILTER = 'CLEAR_STATUS_FILTER';

export const SET_VIEW_FILTERED = 'SET_VIEW_FILTERED';
export const CLEAR_VIEW_FILTERED = 'CLEAR_VIEW_FILTERED';

export const APPLY_FILTERS = 'APPLY_FILTERS';

export const UPDATE_STATUS = 'UPDATE_STATUS';
export const VIEW_ALL_SESSIONS = 'VIEW_ALL_SESSIONS';
export const VIEW_OWN_SESSIONS = 'VIEW_OWN_SESSIONS';

export const ADD_USER = 'ADD_USER';
export const SELECT_USER = 'SELECT_USER';
export const DELETE_USER = 'DELETE_USER';
export const CLEAR_USER = 'CLEAR_USER';

export const GET_USERS = 'GET_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const USER_ERROR = 'USER_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FILTER_USERS = 'FILTER_USERS';
export const CLEAR_USERS_FILTER = 'CLEAR_USERS_FILTER';
export const FILTER_USER_ROLES = 'FILTER_USER_ROLES';
export const CLEAR_ROLES_FILTER = 'CLEAR_ROLES_FILTER';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY';
export const CLEAR_CURRENT_ACTIVITY = 'CLEAR_CURRENT_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const FILTER_ACTIVITIES = 'FILTER_ACTIVITIES';
//export const CLEAR_FILTER = 'CLEAR_ACTIVITY_FILTER';
export const ACTIVITY_ERROR = 'ACTIVITY_ERROR';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const CLEAR_ACTIVITIES = 'CLEAR_ACTIVITIES';


export const ADD_ORGANISATION = 'ADD_ORGANISATION';
export const DELETE_ORGANISATION = 'DELETE_ORGANISATION';
export const SET_CURRENT_ORGANISATION = 'SET_CURRENT_ORGANISATION';
export const CLEAR_CURRENT_ORGANISATION = 'CLEAR_CURRENT_ORGANISATION';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const FILTER_ORGANISATIONS = 'FILTER_ORGANISATIONS';
export const CLEAR_ORGANISATION_FILTER = 'CLEAR_ORGANISATION_FILTER';
export const ORGANISATION_ERROR = 'ORGANISATION_ERROR';
export const GET_ORGANISATIONS = 'GET_ORGANISATIONS';
export const CLEAR_ORGANISATIONS = 'CLEAR_ORGANISATIONS';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const SET_CURRENT_NOTIFICATION = 'SET_CURRENT_NOTIFICATION';
export const CLEAR_CURRENT_NOTIFICATION = 'CLEAR_CURRENT_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const FILTER_NOTIFICATIONS = 'FILTER_NOTIFICATIONS';
export const CLEAR_NOTIFICATION_FILTER = 'CLEAR_NOTIFICATION_FILTER';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const GET_IMAGES = "GET_IMAGES";
export const GET_FILES = "GET_FILES";
export const FILE_ERROR = "FILE_ERROR";
export const ADD_FILE = "ADD_FILE";
export const ADD_IMAGE = "ADD_IMAGE";
export const UPDATE_FILE = "UPDATE_FILE";
export const DELETE_FILE = "DELETE_FILE";
export const DELETE_IMAGE = "DELETE_IMAGE";

export const ADD_VIDEO = 'ADD_VIDEO';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const SET_CURRENT_VIDEO = 'SET_CURRENT_VIDEO';
export const CLEAR_CURRENT_VIDEO = 'CLEAR_CURRENT_VIDEO';
export const UPDATE_VIDEO = 'UPDATE_VIDEO';
export const FILTER_VIDEOS = 'FILTER_VIDEOS';
//export const CLEAR_FILTER = 'CLEAR_VIDEO_FILTER';
export const VIDEO_ERROR = 'VIDEO_ERROR';
export const GET_VIDEOS = 'GET_VIDEOS';
export const CLEAR_VIDEOS = 'CLEAR_VIDEOS';

export const GET_TEAM = 'GET_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const TEAM_ERROR = 'TEAM_ERROR';


export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const TEMPLATE_ERROR = 'TEMPLATE_ERROR';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';


export const ADD_SERVICE = 'ADD_SERVICE';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_ALL_SERVICES = 'GET_ALL_SERVICES';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const DELETE_SERVICE = 'DELETE_SERVICE';

export const GET_BOOKING = 'GET_BOOKING';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const ADD_BOOKING = 'ADD_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const BOOKING_ERROR = 'BOOKING_ERROR';
export const SET_CURRENT_BOOKING = 'SET_CURRENT_BOOKING';

export const ADD_BOOKING_SESSION = 'ADD_BOOKING_SESSION';
export const GET_BOOKING_SESSIONS = 'GET_BOOKING_SESSIONS';
export const BOOKING_SESSION_ERROR = 'BOOKING_SESSION_ERROR';

export const DELETE_BOOKING_SESSION = 'DELETE_BOOKING_SESSION';
export const UPDATE_BOOKING_SESSION = 'UPDATE_BOOKING_SESSION';
export const LOCK_BOOKING = 'LOCK_BOOKING';