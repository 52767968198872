import React, {useContext,useEffect} from 'react'

import ServiceContext from '../../context/service/serviceContext';
import ServiceItem  from './ServiceItem';

import ServiceForm  from './ServiceForm';



import {Row, Col, Button} from "react-bootstrap";

import '../../styles/images.scss';
import { useState } from 'react';

const Service = ({isAdmin}) => {


    // const authContext = useContext(AuthContext);

    const serviceContext = useContext(ServiceContext);

    // const {isSuperAdmin} = authContext;
    const {getServices,services, deleteService} = serviceContext;


    // const {members} = team || {};
     const [showForm,setShowForm] =  useState(false);



     const toggleForm= () =>{
        setShowForm(!showForm);
    }
    const closeForm = () =>{
        setShowForm(false);
    }
    const openForm= () =>{
        setShowForm(true);
    }

    useEffect(() =>{
         //authContext.loadUser();

        getServices();
    // eslint-disable-next-line
    },[])



  return (
        <>
            <Row className='pb-1 mb-5 border-bottom'>
                <div className='col-12 col-md-6'> 
                    <h1>Services</h1>
                </div>
                <div className='mb-2 text-end d-flex align-items-end justify-content-end col-12 col-md-6'> 
                  { isAdmin && <Button onClick={openForm}>Add Service</Button> }
                </div>
            </Row>
  
            <Row>
                {showForm &&
                    <Col xs="12">  
                        <ServiceForm newService={true} closeForm={closeForm}/>
                    </Col>
                }

                {services !==null ? (<>
                    <Col xs="12">  
                    <Row className='border-bottom-bold fw-bold mt-4 py-2'>
                        <div className='col-12 col-md-4'>
                            Name
                        </div>

                        <div className='col-12 col-md-7'>
                            Title
                        </div>
                    </Row>
                </Col>
                       { services.map(service => <ServiceItem key={service._id} service={service} deleteService={deleteService}/>)}
                    </>):
                    <div>No Services</div>
                }

            </Row>

     
          
        </>
  )
}


export default Service;