import React,{useReducer} from "react";
import axios from "axios";

import videoContext from "./videoContext";
import videoReducer from './videoReducer';


import {

    ADD_VIDEO,
    DELETE_VIDEO,
    //SET_CURRENT_VIDEO,
    //CLEAR_CURRENT_VIDEO,
    UPDATE_VIDEO,
    VIDEO_ERROR,
    GET_VIDEOS,
    //CLEAR_VIDEOS,

} from '../types';


const VideoState = props => {
    const initialState ={
        videos: null,
        current: null,
        filtered: null,
        error: null,
        loading: false
              
    }

    const[state,dispatch] = useReducer(videoReducer, initialState);

    //Get Videos

    const getAllVideos = async () => {

        try {
            const res = await axios.get(`/api/videos`);
            dispatch({type: GET_VIDEOS, payload: res.data});

        } catch (err) {
            //dispatch({type: VIDEO_ERROR, payload: err.response.msg})
        }
    }

    //Get Videos

    const getVideos = async id => {

        try {
            const res = await axios.get(`/api/videos/session/${id}`);
            dispatch({type: GET_VIDEOS, payload: res.data});

        } catch (err) {
            dispatch({type: VIDEO_ERROR, payload: err.response.msg})
        }
    }

     //Get Video

    //  const getVideo = async (id) => {

    //     try {
    //         const res = await axios.get(`/api/sessions/${id}`);
    //         //dispatch({type: GET_SESSIONS, payload: res.data});
    //         dispatch({type: SET_CURRENT_VIDEO, payload: res.data});

    //     } catch (err) {
    //         dispatch({type: VIDEO_ERROR, payload: err.response.msg})
    //     }
    // }

    // Add video
    const addVideo = async video => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.post('/api/videos', video, config);
            dispatch({type: ADD_VIDEO, payload: res.data});

        } catch (err) {
            dispatch({type: VIDEO_ERROR, payload: err.response.msg})
        }
    }

    //
    // Update video
    const updateVideo = async video => {

        const config = {
            Headers: {
                'Content-Type': 'application/json'
            }
        }

        try {
            const res = await axios.put(`/api/videos/${video._id}`, video, config);
            dispatch({type: UPDATE_VIDEO, payload: res.data});

        } catch (err) {
            dispatch({type: VIDEO_ERROR, payload: err.response.msg})
        }
    }

    // const getSession = async (id) => {

    //     try {
    //         const res = await axios.get(`/api/sessions/${id}`);

    //     } catch (err) {
    //         dispatch({type: SESSION_ERROR, payload: err.response.msg})
    //     }
    // }

    // delete VIDEO
    const deleteVideo = async id => {
        try {
            await axios.delete(`/api/videos/${id}`);
            dispatch({type: DELETE_VIDEO, payload: id});

        } catch (err) {
            dispatch({type: VIDEO_ERROR, payload: err.response.msg})
        }
    }

    return (
        <videoContext.Provider value ={{
            videos: state.videos,
            current: state.current,
            filtered: state.filtered,
            error: state.error,
            loading: state.loading,
            getVideos,
            addVideo,
            updateVideo,
            deleteVideo,
            getAllVideos
            }}>
            {props.children}
        </videoContext.Provider>
    )
}


export default VideoState;