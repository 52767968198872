import React, {useState, useContext,useEffect} from 'react';

import BookingContext from '../../context/booking/bookingContext';
import e from 'cors';


const BookingSessionsDelegates = ({currentBooking}) => {

    const bookingContext = useContext(BookingContext);

    const {delegates,bookedDelegates} = currentBooking || [];

    const {updateBooking} = bookingContext;

    const [myBookedDelegates, setMyBookedDelegates] = useState([]);
    const [notBookedDelegates, setNotBookedDelegates] = useState([]);
    const [showButton, setShowButton] = useState(false);

    useEffect(() =>{
        setMyBookedDelegates(bookedDelegates);

        setShowButton(getShowButton());

    },[currentBooking, BookingContext, delegates,bookedDelegates])

    useEffect(() =>{
        if(!bookedDelegates || !bookedDelegates.length) return;
        
        setNotBookedDelegates(delegates.filter(del => bookedDelegates.filter(x => x._id === del._id).length <= 0 ));
        
    },[currentBooking, bookedDelegates])

    useEffect(() =>{

        if(!myBookedDelegates || !myBookedDelegates.length){
            setNotBookedDelegates(delegates);
        }
        else{
            setNotBookedDelegates(delegates.filter(del => myBookedDelegates.filter(x => x._id === del._id).length <= 0 ));
        }
        setShowButton(getShowButton());
    },[myBookedDelegates])

    const moveToBooked = (e,booking) => {
        e.preventDefault();
        setMyBookedDelegates([...myBookedDelegates, {...booking}]);
    }

    const removefromBooked = (e,id) => {
        e.preventDefault();
        setMyBookedDelegates(myBookedDelegates.filter(booking => booking._id !== id));
    }

    const getShowButton = () =>{
        if(!bookedDelegates || !myBookedDelegates) return false;

        if(bookedDelegates.length !=  myBookedDelegates.length ) return true;

        return bookedDelegates.filter((del,index) => del._id !== myBookedDelegates[index]._id ).length > 0;
    }

    const update = () =>{
        updateBooking(
            {...currentBooking, bookedDelegates: myBookedDelegates}
        );

        setShowButton(false);
        
    }

    return (

        <div className='col'>
            <h4>Delegates</h4>
            <b className='d-block mt-2 mb-1'>Booked:</b>
            {(myBookedDelegates && myBookedDelegates.length) ? 
                myBookedDelegates.map((booked) => <a className="d-block add-remove" onClick={(e)=>removefromBooked(e,booked._id)} key={booked._id}>{booked.name} {booked.lastName}</a>)
                :
                <span><i>empty</i></span>
            }


            <b className='d-block mt-2 mb-1'>Yet to respond:</b>
            {(notBookedDelegates && notBookedDelegates.length) ?
                notBookedDelegates.map((booked) => <a className="d-block add-remove" onClick={(e)=>moveToBooked(e,booked)} key={booked._id}>{booked.name} {booked.lastName}</a>)
                :
                <div><i>empty</i></div>
            }


            {showButton && <button className='btn btn-primary mt-3' onClick={()=>update()} >Save</button>}
        </div>

    )

}


export default BookingSessionsDelegates