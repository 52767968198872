import React,{useReducer} from "react";
import axios from "axios";
import templateContext from "./templateContext";
import templateReducer from './templateReducer';

import {

    ADD_TEMPLATE,
    GET_TEMPLATES,
    UPDATE_TEMPLATE,
    TEMPLATE_ERROR,
    DELETE_TEMPLATE

} from '../types';


const TemplateState = props => {
    const initialState ={
        templates:[],
    }

    const[state,dispatch] = useReducer(templateReducer, initialState);



        const getTemplates = async () => {

            try {
                const res = await axios.get('/api/template/');
                dispatch({type: GET_TEMPLATES, payload: res.data});
            } catch (err) {
                dispatch({type: TEMPLATE_ERROR, payload: err.response.msg})
            }
        }

        const updateTemplate = async template => {

            const config = {
                Headers: {
                    'Content-Type': 'application/json'
                }
            }
    
            try {
                const res = await axios.put(`/api/template/${template._id}`, template, config);
                dispatch({type: UPDATE_TEMPLATE, payload: res.data});
    
            } catch (err) {
    
                dispatch({type: TEMPLATE_ERROR, payload: err.response.msg})
            }
        }

         // Add template
        const addTemplate = async template => {

            const config = {
                Headers: {
                    'Content-Type': 'application/json'
                }
            }

            try {
                const res = await axios.post('/api/template', template, config);
                dispatch({type: ADD_TEMPLATE, payload: res.data});

            } catch (err) {
                dispatch({type: TEMPLATE_ERROR, payload: err.response.msg})
            }
        }

        const deleteTemplate = async id => {
            try {
                await axios.delete(`/api/template/${id}`);
                dispatch({type: DELETE_TEMPLATE, payload: id});
    
            } catch (err) {
                dispatch({type: TEMPLATE_ERROR, payload: err.response.msg})
            }
        }

        return (
            <templateContext.Provider value ={{
                templates: state.templates,
                addTemplate,
                getTemplates,
                updateTemplate,
                deleteTemplate
                }}>
                {props.children}
            </templateContext.Provider>
        )
    }
    
    
    export default TemplateState;