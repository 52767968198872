import React, {useState, useContext,useEffect} from 'react';
import BookingContext from '../../context/booking/bookingContext';
import BookingSessionContext from '../../context/bookingSession/bookingSessionContext';

import AlertContext from '../../context/alert/alertContext';
import BookingSessionDayLocked from './BookingSessionDayLocked';

import { Row, Col} from "react-bootstrap";

const BookingSessionsLocked = ({delegates, isAdmin}) => {

    const bookingContext = useContext(BookingContext);
    const bookingSessionContext = useContext(BookingSessionContext);

    const alertContext = useContext(AlertContext);

    //const {setAlert} = alertContext;

    const {currentBooking} = bookingContext;
    const {bookingSessions, getBookingSessions} = bookingSessionContext;

    const [bookingDays, setBookingDays] = useState(null);

    const [bookingRequest, setBookingRequest] = useState({
                                                    _id: 0,
                                                    requests:[]    
                                                });
    
    const {requests} = bookingRequest;



    useEffect(() =>{

        if(!currentBooking) return;

        getBookingSessions(currentBooking);
        
    },[currentBooking])


    useEffect(() =>{

        if(bookingSessions.length){
            const temp = Object.groupBy(bookingSessions, ({ startDate }) => startDate);
            setBookingDays(temp);
        }

    },[bookingSessions]) 

    const toggleRequests = sessionId => {

        if(bookingRequest.requests.includes(sessionId)){
            setBookingRequest({
                ...bookingRequest,
                _id: currentBooking._id,
                requests: bookingRequest.requests.filter(req=> req !== sessionId)
            })
        }
        else{
            setBookingRequest({
                ...bookingRequest,
                _id: currentBooking._id,
                requests: [...bookingRequest.requests, sessionId]
            })
        }

     
    }



    return(
        
        <>
            <Row className='mb-3'>
                <Col>
                    <p>Thank you for booking.<br/>
                    Your dates are:</p>
                </Col>
            </Row>
    
       
            <Row className='mb-3'>
                {bookingDays !==null && Object.values(bookingDays).length ?
                        Object.keys(bookingDays).sort().map( key => 
                            <BookingSessionDayLocked key={key} bookingSessionDay ={bookingDays[key]} title={key} currentBooking={currentBooking} delegates={delegates} toggleRequests={toggleRequests} requests={requests} isAdmin={isAdmin}/> 
                        )
                        :
                        <div>No booking sessions found</div>
                    
                }
            </Row>


        </>

    )
  
}

export default BookingSessionsLocked