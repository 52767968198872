import React, {Fragment, useState, useContext,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Button,Form, Card, Container, ListGroup, Row, Col} from "react-bootstrap";

import FileContext from '../../context/file/fileContext';

import ImageItem from './ImageItem';

const Images =  ({isAdmin,showForm,setShowForm}) => {


    const fileContext = useContext(FileContext);

    const {addImage, images, getImages} = fileContext;

    const navigate = useNavigate();

    useEffect(() =>{
        //authContext.loadUser();

        if(!isAdmin) navigate('/');

        getImages();
        // eslint-disable-next-line
    },[])

    const [file, setFile] = useState('');

    const [filename, setFilename]= useState('');



    const onChange = e =>{
        e.preventDefault();
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
    }

    const onSubmit = e =>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('imgFile', file);
        addImage(formData);
        setFile('');
    }

    return (
        <>
             {showForm &&
            <Row className='mb-5'>
                <Form onSubmit={onSubmit}>
                    <Col xs={12} >               
                        <Form.Group className="mb-3">
                            <Form.Label>Image picker</Form.Label>
                            <Form.Control type="file" rows={3} accept="image/jpeg" placeholder="file" name="file" onChange={onChange} />
                        </Form.Group>
                    </Col>
            
                    <Col>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    &nbsp;
                        <Button variant="primary" onClick={()=>setShowForm(false)}>
                            Close
                        </Button>
                    </Col>
                </Form>
            </Row>
            }
            <Row>

                {images ? images.map(image => <ImageItem key={image.path} image={image}/>) :
                    <div>No images</div>
                }

            </Row>
        </>
    )

}

export default Images;