import React from 'react'
//import {Container, Row, Col, Card, Form, Button } from "react-bootstrap";

const About = () => {
  return (
    <>
      <h2>About page</h2>
    </>
  )
}

export default About