import React, {useContext,useEffect,useState}  from 'react';
import {BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import {Container, Row, Col, Card, Form, Button, Navbar } from "react-bootstrap";

//import Dashboard from '../pages/Dashboard';
import Dashboard from '../dashboard/Dashboard';
//import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import NotFoundPage from '../pages/NotFoundPage';

import Alerts from './Alerts';
//import MainMenu from './MainMenu';
import MainNav from './MainNav';

import Login from '../auth/Login';
import Register from '../auth/Register';

import Sessions from '../sessions/Sessions';
import Session from '../sessions/Session';
import SessionForm from '../sessions/SessionForm';
import SessionRequestForm from '../sessions/SessionRequestForm';

import Users from '../users/Users';
import User from '../user/User';
import UserForm from '../user/UserForm';

import Organisations from '../organisations/Organisations';
import AuthContext from '../../context/auth/authContext';


import Service from '../service/Service';
import Team from '../team/Team';

import Template from '../template/Template';

import Footer from './Footer';

import NavContainer from './NavContainer';
import Media from '../media/Media';
//import Images from '../files/Images';
//import Files from '../files/Files';

import Recovery from '../recovery/Recovery';
import RecoveryCode from '../recovery/RecoveryCode';


import Bookings from '../booking/Bookings';
import Booking from '../booking/Booking';
import BookingForm from '../booking/BookingForm';

const PortalRoutes = () => {

    const authContext = useContext(AuthContext);
    const { isAdmin, isAuthenticated, loading } = authContext;

    useEffect(() =>{
       
        authContext.loadUser();
       
    },[])
    
    return (
        <Router>
            <div className='d-flex flex-column h-full-screen'> 
                    
                    {isAuthenticated ? (
                        <Routes>
                            <Route path="/" element={
                                <NavContainer children={<Dashboard/>} navType={true}/>
                            } />
                        
                            <Route path="/sessions" element={
                                <NavContainer children={<Sessions isAdmin={isAdmin}/>} />
                            } />
                        
                            <Route exact path="/sessions/view" element={
                                <NavContainer children={<Session isAdmin={isAdmin}/>} />
                            } />
                            <Route exact path="/sessions/edit" element={
                                <NavContainer children={<SessionForm/>} />
                            } />
                            <Route exact path="/sessions/new" element={
                                <NavContainer children={<SessionForm newSession={true}/>} />
                            } />
                            <Route exact path="/sessions/request" element={
                                <NavContainer children={<SessionRequestForm newSession={true}/>} />
                            } />
                            <Route exact path="/sessions/session/:id" element={
                                <NavContainer children={<Session isAdmin={isAdmin}/>} />
                            } />
                            <Route exact path="/sessions/session/edit/:id" element={
                                <NavContainer children={<SessionForm isAdmin={isAdmin}/>} />
                            } />

                            <Route path="/users" element={
                                <NavContainer children={<Users isAdmin={isAdmin}/>} />
                            } />
                            <Route path="/user/:id" element={
                                <NavContainer children={<User/>} />
                            } />
                            <Route path="/user/edit/:id" element={
                                <NavContainer children={<UserForm/>} />
                            } />
                            <Route path="/user/new" element={
                                <NavContainer children={<UserForm newUser={true}/>} />
                            } />

                            <Route path="/user/view" element={
                                <NavContainer children={<User/>}/>
                            } />
                            <Route path="/user/edit" element={
                                <NavContainer children={<UserForm/>} />
                            } />                            
                            <Route exact path='/about' element={
                                <NavContainer children={<About/>} />
                            }/>      
                            <Route exact path='/contact' element={
                                <NavContainer children={<Contact/>} />
                            }/>

                            <Route exact path='/organisations' element={                                
                                <NavContainer children={
                                    <Organisations isAdmin={isAdmin}/>
                                } />
                            }/>

                            <Route exact path='/media' element={                                
                                <NavContainer children={
                                    <Media isAdmin={isAdmin}/>
                                } />
                            }/> 

                            <Route exact path='/team' element={
                                <NavContainer children={
                                    <Team/>
                                } />
                            }/> 

                            <Route exact path='/template' element={
                                <NavContainer children={
                                    <Template isAdmin={isAdmin}/>
                                } />
                            }/> 
                            <Route exact path='/service' element={
                                <NavContainer children={
                                    <Service isAdmin={isAdmin}/>
                                } />
                            }/> 

                            <Route exact path='/bookings' element={
                                <NavContainer children={
                                    <Bookings isAdmin={isAdmin}/>
                                } />
                            }/> 

                            <Route exact path='/bookings/booking/:id' element={
                                <NavContainer children={
                                    <Booking isAdmin={isAdmin}/>
                                } />
                            }/> 
                             <Route exact path="/bookings/edit/:id" element={
                                <NavContainer children={<BookingForm/>} />
                            } />
                            <Route exact path="/bookings/new" element={
                                <NavContainer children={<BookingForm newBooking={true}/>} />
                            } />

                            <Route path="/register" element={
                                <NavContainer children={<Dashboard/>} navType={true}/>
                            } />

                            <Route path="*" element={
                                <NavContainer children={<NotFoundPage/>} />
                            }/>
                        </Routes>
                    ):(   

                        <Routes>
                            <Route exact path='/recovery/:code' element={
                                <RecoveryCode/>
                            }/> 
                             <Route exact path='/recovery' element={
                                <Recovery/>
                            }/> 
                            <Route exact path='/register' element={
                                <Register/>
                            }/> 
                            <Route path="*" element={<Login/>} /> 
                        </Routes>
                    )
                    }
         
            { isAuthenticated && <Footer />}
            </div>
        </Router>
    )
}

export default PortalRoutes