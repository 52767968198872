import React from 'react';

import PropTypes from 'prop-types';

import {Col} from "react-bootstrap";


const UserFormImageItem = ({image, index, onImageClick, selectedIndex}) => {

    const {name, url} = image;

    const onClick = () => {
        onImageClick(index,image._id);
    }

    return (
        <Col xs="3"><img src={url} className={selectedIndex===index ? 'selected':'not-selected'} onClick={onClick}/></Col>
    )
}

UserFormImageItem.propTypes = {
    image: PropTypes.object.isRequired,
}

export default UserFormImageItem