import {
    GET_IMAGES,
    GET_FILES,
    FILE_ERROR,
    ADD_FILE,
    ADD_IMAGE,
    UPDATE_FILE,
    DELETE_FILE,
    DELETE_IMAGE
} from '../types';

export default (state, action) =>{
    switch(action.type) {

        case GET_IMAGES:
            return {
                ...state,
                images: action.payload,
                loading: false
            }
        case FILE_ERROR:
            return{
                ...state,
                error: action.payload,
                submitted: false
            }
        case ADD_IMAGE:
            return{
                ...state,
                submitted: true
            }
        case GET_FILES:
            return {
                ...state,
                files: action.payload,
                loading: false
            }
        case ADD_FILE:
            return{
                ...state,
                files: [...state.files, action.payload],
                submitted: true
            }
        case UPDATE_FILE:
            return{
                ...state,
                files: state.files.map(file => file._id === action.payload._id ? action.payload : file),
                submitted: true
            }

        case DELETE_FILE:
            return{
                ...state,
                files: state.files.filter(file => file._id !==action.payload)
            }
        case DELETE_IMAGE:
            return{
                ...state,
                images: state.images.filter(image => image._id !==action.payload)
            }
        default:
            return state;
    }
}