import React, {useState, useContext, useEffect} from 'react'

import moment from "moment";

import '../../../styles/booking.scss';

const BookingSessionItem = ({bookingSession, booking, isAdmin, toggleRequests, requests}) => {

    const {title,startTime, endTime, startDate, booked, open} = bookingSession;
    const {locked} = booking || false;
    const [inSessionItem, SetInSessionItem] = useState(false);

    useEffect(() =>{

        const inArray = requests.includes(bookingSession._id);
        SetInSessionItem(inArray);

    },[requests])

    return (

        <div className='col-12'>
            {locked ?
                <div className={`d-inline-block py-2 px-5 my-1 border border-secondary booking-item ${booked ? 'booked':''}`}>
                    {moment(startTime).format("HH:mm")} - {moment(endTime).format("HH:mm")}               
                </div>
                : open ?
                    <button onClick={()=>toggleRequests(bookingSession._id)} className={`d-inline-block py-2 px-5 my-1 border border-secondary booking-item  ${inSessionItem ? 'booked' :'not'}`} > {moment(startTime).format("HH:mm")} - {moment(endTime).format("HH:mm")} </button>
                    :
                    <div className={`d-inline-block py-2 px-5 my-1 border border-secondary booking-item closed`}>
                        <span>{moment(startTime).format("HH:mm")} - {moment(endTime).format("HH:mm")}</span>
                    </div>
                    
            }
            {//!locked &&

            //<button onClick={()=>toggleRequests(bookingSession._id)}>{inSessionItem ? <span>Remove me</span>:<span>Add me</span>} </button>
            }
        </div>

    )


}

export default BookingSessionItem