import React, {useContext,useEffect}  from 'react';
import PropTypes from 'prop-types';
import {useParams } from "react-router-dom";
import UserContext from '../../context/user/userContext';
import {Link} from 'react-router-dom';

import {Card} from "react-bootstrap";

import ROLE from '../../utils/roles';

const User = props => {

  const userContext = useContext(UserContext);
  const {current, getUserById } = userContext;

  let {name, email, role} = current || {};

  const params = useParams();

  useEffect( () =>{

    getUserById(params.id)
    // eslint-disable-next-line
  },[])

  useEffect( () =>{
    ({name, email, role} = current || {}); 
  },[current,userContext])

  return (
    <div>
      {current === null ? <div>User not found </div> :

        <>
          <Card>
            <Card.Body>
                <Card.Title><h2>{name}</h2></Card.Title>
                <Card.Subtitle className="mb-2 text-muted">--------</Card.Subtitle>
                <Card.Text>
                    Email: {email}
                </Card.Text>
                <Card.Text>
                    Role: {ROLE[role]}
                </Card.Text>
            </Card.Body>
          </Card>
        </>
      }

      <div>
        <Link to={`/users`} className="btn btn-primary btn-sm  my-1">
          Back
        </Link>                    
      </div>
    </div>
  )
}

User.propTypes = {}

export default User