import React, {useState, useContext, useEffect, useRef} from 'react';
//import { useNavigate } from 'react-router-dom';
import {Button,Form, Row, Col} from "react-bootstrap";

import FileContext from '../../context/file/fileContext';

import FileItem from './FileItem';

import UserContext from '../../context/user/userContext';
import SessionContext from '../../context/session/sessionContext';
import Select from 'react-select';

import Multiselect from 'multiselect-react-dropdown';

const Files = ({isAdmin,showForm,setShowForm,session}) => {


    const fileContext = useContext(FileContext);

    const {addFile, files, getFiles} = fileContext;

    const userContext = useContext(UserContext);
    const {getUsers, users} = userContext;

    const sessionContext = useContext(SessionContext);
    const {getSessions, sessions} = sessionContext;

    useEffect(() =>{

        getFiles();
  
        if(users===null || !users.length){
            getUsers();
        }
        if(sessions===null || !sessions.length){
            getSessions();
        }

        // eslint-disable-next-line
    },[])

    useEffect( () =>{

        if(users && users.length){
            setClientOptions( users.filter(user => user.role===3).map(user => ({value: user._id, label: `${user.name} ${user.lastName}`})));
            setDelegateOptions( users.filter(user => user.role===4).map(user => ({value: user._id, label: `${user.name} ${user.lastName}`})));
        }
    
    },[users])

    useEffect( () =>{

        if(sessions && sessions.length){
            setSessionOptions(sessions.map(session => ({value: session._id, label: `${session.title}: ${session.description}`})));
        }
    
    },[sessions])

    
    useEffect( () =>{

        if(!files) return;

        if(session){
            //setActiveFiles(files.filter(file => file.session && ((file.session===session._id)||(file.sessions.filter(thisSession => thisSession._id === session._id)))));
            //            setActiveFiles(files.filter(file => file.session && file.session===session._id));

           let sessionFiles = files.filter(file => (file.session && file.session == session._id ) || 
                (
                    (((file.sessions.filter(thisSession => ((thisSession._id == session._id ) || (thisSession == session._id)))
                        ).length ))                   
                ));

                setActiveFiles(sessionFiles);

        }
        else{
            setActiveFiles(files);
        }
    
    },[files,session])

    const [file, setFile] = useState('');
    const [filename, setFilename]= useState('');

    const [activeFiles, setActiveFiles] = useState([]);

    const [filteredFiles, setFilteredFiles] = useState([]);
    const [filterValue, setFilterValue] = useState(false);
   
    const [clientValue, setClientValue] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const [clientId, setClientId] = useState(null);

    const [delegateValue, setDelegateValue] = useState(null);
    const [delegateOptions, setDelegateOptions] = useState([]);
    const [delegateId, setDelegateId] = useState(null);

    const [sessionValue, setSessionValue] = useState(null);
    const [sessionOptions, setSessionOptions] = useState([]);
    const [sessionId, setSessionId] = useState(null);


    const [clientsList, setClientsList] = useState([]);
    const [clientsSubmitList, setClientsSubmitList] = useState([]);
    const [delegatesList, setDelegatesList] = useState([]);
    const [delegatesSubmitList, setDelegatesSubmitList] = useState([]);
    const [sessionsList, setSessionsList] = useState([]);
    const [sessionsSubmitList, setSessionsSubmitList] = useState([]);

    const onChange = e =>{

        e.preventDefault();

        if(e.target.name === 'file'){
            setFile(e.target.files[0]);
            if(!filename.length){
                setFilename(e.target.files[0].name);
            }
        }
        if(e.target.name === 'filename'){
            setFilename(e.target.value);
        }
        if(e.target.name === 'client'){
            setClientValue(e.target.value);
        }
    }

    const onSubmit = e =>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('mediaFile', file);
        formData.append('filename', filename);
        formData.append('client', clientId);
        formData.append('delegate', delegateId);
        formData.append('session', sessionId);

        formData.append('clients', clientsSubmitList);
        formData.append('delegates',delegatesSubmitList);
        formData.append('sessions', sessionsSubmitList);


        addFile(formData);
        // setFile('');
        // setFilename('');
        // setClientValue(null);
        // setDelegateValue(null);
        // setSessionValue(null);


        // setSessionsList([]);

        // if(setShowForm !== null){
        //     setShowForm(false);
        // }
    }

    const text = useRef('');

    const onFilterChange = (e) =>{
        e.preventDefault();
        const match = e.target.value;
        if(match!==null && !match.length){
            setFilterValue(false);
            setFilteredFiles([]);
            return;
        }
        if(match.length > 3){
            setFilterValue(true);
            const filtered = activeFiles.filter(file => {
                const regex = new RegExp(e.target.value,'gi');
                return file.name.match(regex);
            });
            setFilteredFiles(filtered);
        }

    }

    const handleSelectClient = (selectedOption) => {
        setClientValue(selectedOption);
        setClientId(selectedOption.value);
    }

    const handleSelectDelegate = (selectedOption) => {
        setDelegateValue(selectedOption);
        setDelegateId(selectedOption.value);
    }

    const handleSelectSession = (selectedOption) => {
        setSessionValue(selectedOption);
        setSessionId(selectedOption.value);
    }
    //--
    const onSessionsSelect = (sessions) => {
        let values = sessions.map(session => session.value);
        setSessionsList(sessions);
        setSessionsSubmitList(values);
    }

    const onClientsSelect = (clients) => {
        let values = clients.map(client => client.value);
        setClientsList(clients);
        setClientsSubmitList(values);
    }

    const onDelegatesSelect = (delegates) => {
        let values = delegates.map(delegate => delegate.value);
        setDelegatesList(delegates);
        setDelegatesSubmitList(values);
    }


    return (
        <>
            {showForm &&
                <Form onSubmit={onSubmit}>
                    <Row className='mb-4'>
                    <Col xs={12} >               
                        <Form.Group className="mb-3">
                            <Form.Label>File picker</Form.Label>
                            <Form.Control type="file" rows={3} accept=".doc,.docx,application/msword,.pdf,pptx" placeholder="file" name="file" onChange={onChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} >               
                        <Form.Group className="mb-3">
                            <Form.Label>File name</Form.Label>
                            <Form.Control type="text" placeholder="Filename" name="filename" value ={filename} onChange={e => onChange(e)} required/>
                        </Form.Group>
                    </Col>
                {/* 
                    <Col xs={12} md={6}>
                    <Form.Group className="mb-3 form-dropdown">
                        <Form.Label>Client</Form.Label>
                        <Select name="" value={clientValue} onChange={handleSelectClient} options={clientOptions}/>
                    </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                    <Form.Group className="form-dropdown">
                        <Form.Label>Delegate</Form.Label>
                        <Select value={delegateValue} onChange={handleSelectDelegate} options={delegateOptions}/>
                    </Form.Group>
                </Col>
                <Col xs={12} >
                    <Form.Group className="form-dropdown">
                        <Form.Label>Session</Form.Label>
                        <Select value={sessionValue} onChange={handleSelectSession} options={sessionOptions}/>
                    </Form.Group>
                </Col>
            */}
                <Col xs={12} >
                    <Form.Label>Clients</Form.Label>
                    <Form.Group  className="mb-3">
                        <Multiselect
                            options={clientOptions} // Options to display in the dropdown
                            selectedValues={clientsList} // Preselected value to persist in dropdown
                            onSelect={onClientsSelect} // Function will trigger on select event
                            onRemove={onClientsSelect} // Function will trigger on remove event
                            displayValue='label' // Property name to display in the dropdown options
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} >
                    <Form.Label>Delegates</Form.Label>
                    <Form.Group  className="mb-3">
                        <Multiselect
                            options={delegateOptions} // Options to display in the dropdown
                            selectedValues={delegatesList} // Preselected value to persist in dropdown
                            onSelect={onDelegatesSelect} // Function will trigger on select event
                            onRemove={onDelegatesSelect} // Function will trigger on remove event
                            displayValue='label' // Property name to display in the dropdown options
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} >
                    <Form.Label>Sessions</Form.Label>
                    <Form.Group  className="mb-3">
                        <Multiselect
                            options={sessionOptions} // Options to display in the dropdown
                            selectedValues={sessionsList} // Preselected value to persist in dropdown
                            onSelect={onSessionsSelect} // Function will trigger on select event
                            onRemove={onSessionsSelect} // Function will trigger on remove event
                            displayValue='label' // Property name to display in the dropdown options
                        />
                    </Form.Group>
                </Col>

                </Row>
                <Row className='mb-5'>

                    <Col>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        &nbsp;
                        <Button variant="primary" onClick={()=>setShowForm(false)}>
                            Close
                        </Button>
                    </Col>
            </Row>
            </Form>
            }
            
            <Row className='mb-4'>
                {session ? 
                <h3 className='mb-4'>Slides</h3>
                :
                <Row>
                    <form className='text-primary py-2 px-1 justify-content-between d-block' onSubmit={e => e.preventDefault()}>
                        <input className="d-block w-100 p-2" ref={text} type="text" placeholder='Filter files' onChange={onFilterChange}/>
                    </form>
                </Row>
                }
                
                <Row className='row-border'>
                    <Col xs={2} className="py-1">
                        <b>Date</b>
                    </Col>
                    <Col xs={10}  className="py-1 d-flex">
                        <div className="col col-md-2"><b>Type</b></div>
                        <div className="col col-md-10"><b>File</b></div>
                    </Col>
                </Row>
                {activeFiles && activeFiles.length ? 
                    filterValue ? 
                        (filteredFiles && filteredFiles.length) ?
                            filteredFiles.map(file => <FileItem key={file._id} file={file} isAdmin={isAdmin}/>):
                            <div>No matching files</div>
                    : activeFiles.map(file => <FileItem key={file._id} file={file} isAdmin={isAdmin}/>) :
                    <div>No files</div>
                }
            </Row>
        </>
    )

}

export default Files;