import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import SessionContext from '../../context/session/sessionContext';
import moment from "moment";
import {useNavigate} from 'react-router-dom';

import {Row,Col} from "react-bootstrap";
import '../../styles/main.scss';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const SessionItemAdmin = ({session}) => {

    let navigate = useNavigate(); 
    const sessionContext = useContext(SessionContext);

    const {deleteSession, cloneSession } =sessionContext;
    const {title, description, startDate} = session;

     const onDelete = (e)=>{
        e.preventDefault();
        deleteSession(session._id);
    }

    const viewSession = (e)=>{

        e.preventDefault();
        navigate(`/sessions/session/${session._id}`);
    }
    
    const onClone = (e)=>{
        e.preventDefault();
        cloneSession(session._id);
    }

    return (
        <Row className="row-border session-row position-relative">
            <Col xs={2} className="py-2">
                {moment(startDate).format('DD MMM YYYY')}
            </Col>
            <a href="/sessions" onClick={e => viewSession(e)} className="col-10 py-2 d-flex" >
                <Col md={5} >
                <b>{title}</b>
                </Col>
                <Col md={7} className='pe-4'>
                {description}
                </Col>
            </a>
            <div className="edit-btns text-end">
            <button className='btn btn-edit-inline px-2' onClick={(e) => { if (window.confirm('Are you sure you wish to clone this session?')) onClone(e)}} data-tooltip-id="clone-tooltip" data-tooltip-content="Clone Session"> Clone Session</button>
                <Tooltip id="clone-tooltip" />
                <button className='btn btn-del px-2 m-2' onClick={(e) => { if (window.confirm('Are you sure you wish to delete this session?')) onDelete(e)}} data-tooltip-id="del-tooltip" data-tooltip-content="Delete Session"> Delete Session</button>
                <Tooltip id="del-tooltip" />
            </div>
        </Row>
    )
}

SessionItemAdmin.propTypes = {
    session: PropTypes.object.isRequired,
}

export default SessionItemAdmin