import React,{useContext, useEffect, useState} from 'react';

const Pagination = ({pageSize, currentPage, maxPage, setCurrentPage}) => {


    const [showPages, setShowPages] = useState(5);

    const [smallListing, setSmallListing] = useState(false);
    const [showMiddle, setShowMiddle] = useState(false);
    //const [showEnds, setShowEnds] = useState(false);
    const [atStart, setAtStart] = useState(true);

    useEffect(() =>{
       
        setSmallListing( maxPage < 10 );
    },[maxPage])

    useEffect(() =>{
        setShowMiddle( currentPage >= 5 && (currentPage < (maxPage - 5)) );
        setAtStart(currentPage <= 5 );
    },[currentPage])

    const showAllLinks = () =>{

        return (

            <div>    
                <ul className='pagination'>
                    <li className={`item previous ${currentPage > 0 ? '': 'disabled-page'}`}
                    onClick={() => setCurrentPage(currentPage-1)}
                    >
                        <a rel="prev">&lt; previous</a>
                    </li>

                    {[...Array(maxPage)].map((e, i) => 
                    <li className={`item pagination-page${currentPage==i ? ' active': ''}`} 
                        key={i}
                        onClick={() => setCurrentPage(i)}
                    >{i+1}
                    </li>
                    )}

                    <li className={`item next ${currentPage < maxPage-1 ? '': 'disabled-page'}`}
                        onClick={() => setCurrentPage(currentPage+1)}
                    >
                        <a rel="next">next &gt;</a>
                    </li>
                </ul>

            </div>
        )
    }

    const showEndLinks = () =>{


        return (
            atStart ?
            <div>    
                <ul className='pagination'>
                    <li className={`item previous ${currentPage > 0 ? '': 'disabled-page'}`}
                    onClick={() => setCurrentPage(currentPage-1)}
                    >
                        <a rel="prev">&lt; previous</a>
                    </li>

                    {[...Array(7)].map((e, i) => 
                    <li className={`item pagination-page${currentPage==i ? ' active': ''}`} 
                        key={i}
                        onClick={() => setCurrentPage(i)}
                    >{i+1}
                    </li>
                    )}

                    ...

                    {[...Array(2)].map((e, i) => 
                    <li className={`item pagination-page${currentPage==maxPage-(5-i) ? ' active': ''}`} 
                        key={maxPage-(2-i)}
                        onClick={() => setCurrentPage(maxPage-(2-i))}
                    >{(maxPage-(2-i))+1}
                    </li>
                    )}

                    <li className={`item next ${currentPage < maxPage-1 ? '': 'disabled-page'}`}
                        onClick={() => setCurrentPage(currentPage+1)}
                    >
                        <a rel="next">next &gt;</a>
                    </li>
                </ul>
            </div>

            :

            <div>    
                <ul className='pagination'>
                    <li className={`item previous ${currentPage > 0 ? '': 'disabled-page'}`}
                    onClick={() => setCurrentPage(currentPage-1)}
                    >
                        <a rel="prev">&lt; previous</a>
                    </li>

                    {[...Array(2)].map((e, i) => 
                    <li className={`item pagination-page${currentPage==i ? ' active': ''}`} 
                        key={i}
                        onClick={() => setCurrentPage(i)}
                    >{i+1}
                    </li>
                    )}
                    ...
                    {[...Array(7)].map((e, i) => 
                    <li className={`item pagination-page${currentPage==maxPage-(7-i) ? ' active': ''}`} 
                        key={maxPage-(7-i)}
                        onClick={() => setCurrentPage(maxPage-(7-i))}
                    >{(maxPage-(7-i))+1}
                    </li>
                    )}

                    <li className={`item next ${currentPage < maxPage-1 ? '': 'disabled-page'}`}
                        onClick={() => setCurrentPage(currentPage+1)}
                    >
                        <a rel="next">next &gt;</a>
                    </li>
                </ul>
            </div>            
        )
    }

    const showMiddleLinks = () =>{


        const indices = [-2,-1,0,1,2];

        return (
        
            <div>    
                <ul className='pagination'>
                    <li className={`item previous ${currentPage > 0 ? '': 'disabled-page'}`}
                    onClick={() => setCurrentPage(currentPage-1)}
                    >
                        <a rel="prev">&lt; previous</a>
                    </li>

                    {[...Array(2)].map((e, i) => 
                    <li className={`item pagination-page${currentPage==i ? ' active': ''}`} 
                        key={i}
                        onClick={() => setCurrentPage(i)}
                    >{i+1}
                    </li>
                    )}
                    ...
                    {indices.map((ind) => 
                    <li className={`item pagination-page${ind==0 ? ' active': ''}`} 
                        key={currentPage+ind}
                        onClick={() => setCurrentPage(currentPage+ind)}
                    >{(currentPage+ind)+1}
                    </li>
                    )}
                    ...
                    {[...Array(2)].map((e, i) => 
                    <li className={`item pagination-page${currentPage==maxPage-(2-i) ? ' active': ''}`} 
                        key={maxPage-(2-i)}
                        onClick={() => setCurrentPage(maxPage-(5-i))}
                    >{(maxPage-(2-i))+1}
                    </li>
                    )}

                    <li className={`item next ${currentPage < maxPage-1 ? '': 'disabled-page'}`}
                        onClick={() => setCurrentPage(currentPage+1)}
                    >
                        <a rel="next">next &gt;</a>
                    </li>
                </ul>
            </div>
        )
    }


    return (
        <>
        {currentPage!==null && maxPage && 
            (smallListing ? showAllLinks() :
            (showMiddle ? showMiddleLinks() : showEndLinks()))
        }
        </>

    )
}

export default Pagination