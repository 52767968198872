import React, {useContext} from 'react';
import {Link, NavLink} from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import SessionContext from '../../context/session/sessionContext';
import {Container, Row} from "react-bootstrap";

import logoSrc from '../../img/logo-footer.svg';
import yswtSrc from '../../img/yswt.svg';

const Footer = () => {

    const authContext = useContext(AuthContext);
    const {isAuthenticated, logout} = authContext;

    const sessionContext = useContext(SessionContext);

    const {clearAllSessions} = sessionContext;

    const onLogout = e => {
        e.preventDefault(); 
        clearAllSessions();
        logout();
    }

    return (
        <div className="footer text-white no-print">
            <Container>
                <Row className='justify-content-between'>
                    <div className='col-2'>
                        <Link to='/'><h4 className='h1 my-2 font-size-0'><img src={logoSrc} className='logo-footer' alt='Bladonmore logo'/>Bladonmore portal</h4></Link>
                    </div>

                    <ul className='col-3 col-lg-1 offset-lg-9 no-styling'>
                        <li>
                            {isAuthenticated ? <a href="/" onClick={onLogout}><h4>Logout</h4></a> : <span>&nbsp;</span> }
                        </li>
                        <li>
                            <NavLink to='/contact'><h4>Contact</h4></NavLink>
                        </li>

                       
                    </ul>
                </Row>
                <Row>
                    <div className='col-12'>

                    <Link to='/'><h4 className='h1 my-2 font-size-0'><img src={yswtSrc} className='logo' alt='Bladonmore logo'/>Your story well told</h4></Link>
                    </div>
                </Row>
            </Container>
        </div>
    )    

}


export default Footer