import React,{useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Col,Row} from "react-bootstrap";
import moment from "moment";
import { Tooltip } from 'react-tooltip';
import VideoContext from '../../context/video/videoContext';
import 'react-tooltip/dist/react-tooltip.css';

import VideoForm from './VideoForm';

const VideoItem = ({video, isAdmin, selectedVideo, setSelectedVideo }) => {

    const {title,vimeoID, date, session} = video;

    const videoContext = useContext(VideoContext);

    const [showForm,setShowForm] = useState(false);

    const {deleteVideo} = videoContext;

    const onDeleteVideo=() =>{
        deleteVideo(video._id);
    }

    const onClick = () => {
        setSelectedVideo(video);
    }
  
    const toggleForm= () =>{
        setShowForm(!showForm);
    }
    return (
    <>

    <Row className="row-border session-row position-relative" onClick={toggleForm}>
        <Col xs={2} className="py-2">
            {moment(date).format('DD MMM YYYY')}
        </Col>
        <div className="col-12 col-md-9 py-2 d-flex" >
            <Col md={10} >
            <b>{title}</b>
            </Col>
            <Col md={2} className='pe-4'>
            {session && session.title}
            </Col>
        </div>
        <div className='col-12 col-md-1 text-end pt-2'>
            <span className={showForm ? 'chevron-down align-top' : 'chevron-up align-top'}></span>
        </div>
    </Row>
        {showForm && 
            <Row>
                <Col>
                    <VideoForm video={video} newVideo={true} setShowForm={setShowForm}/>
                </Col>
            </Row>
        }
    </>
    )
    
}

VideoItem.propTypes = {
    video: PropTypes.object.isRequired,
}

export default VideoItem