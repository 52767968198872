import React, {useContext,useEffect, useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';

import img1 from '../../img/carousel/pal.png';
import img2 from '../../img/carousel/ara.png';
import img3 from '../../img/carousel/etihad.png';

const DashboardCarousel = () => {

//   const serviceContext = useContext(ServiceContext);
//   const {getServices,services, deleteService} = serviceContext;
//   const [activeService,setActiveService] = useState(null);
  
  const [showCarousel,setShowCarousel] = useState(true);

  useEffect(() =>{

  // eslint-disable-next-line
  },[])

  useEffect(() =>{

    // setup carousel


  },[])


  return (
    <>
        <div className='pt-2 col-12 col-sm-10 col-lg-6 offset-sm-2 offset-lg-0 col-xl-6 mb-5' >


        <Carousel className='dashboard-carousel pt-4 px-2 pb-5'>
            <Carousel.Item className='pb-2'> 
                <div className='row no-gutters'>
                <div className='col-7'>
                    <img src={img1} />
                </div>
                <div className='col-5 position-relative'>
                    <div className='dashboard-carousel_copy'>

                    <h3>PAI Partners</h3>
                    <p>
                    Interested in running an investor event? See how we transformed PAI Partners AGM through award-winning film.
                    </p>
                    </div>
                </div>
                </div>
            </Carousel.Item>
            <Carousel.Item> 
                <div className='row no-gutters'>
                <div className='col-7'>
                    <img src={img2} />
                </div>
                <div className='col-5 position-relative'>
                    <div className='dashboard-carousel_copy'>

                    <h3>Ara</h3>
                    <p>
                    Interested in a brand refresh? See how we built Ara’s new brand capturing a leadership position in decarbonisation.
                    </p>
                    </div>
                </div>
                </div>
            </Carousel.Item>
            <Carousel.Item> 
                <div className='row no-gutters'>
                <div className='col-7'>
                    <img src={img3} />
                </div>
                <div className='col-5 position-relative'>
                    <div className='dashboard-carousel_copy'>

                    <h3>Eithad</h3>
                    <p>
                    Are you prepared for a crisis event? See how we created simulated crisis environments to test Etihad’s leadership and communications teams.
                    </p>
                    </div>
                </div>
                </div>
            </Carousel.Item>
     
        </Carousel>
            




        </div>
    </>
  );
}

export default DashboardCarousel