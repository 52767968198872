import React, {useContext,useEffect} from 'react';
import SessionContext from '../../context/session/sessionContext';
import STATUS from '../../utils/status';

const SessionSidebar = () => {
    
    const sessionContext = useContext(SessionContext);
    const {sessions, getSessions, loading, sessionStatuses, feedbackStatuses, setStatus, clearStatus,currentStatus} = sessionContext;

    useEffect(() =>{
        if(sessions === null || !sessions.length){
            getSessions();
        }
    },[sessionContext,sessions,getSessions])

    const onClick = (e, count, status) => {
        e.preventDefault();
        setStatus(status);
    }

    const showAll = e => {
        e.preventDefault();
        clearStatus();
    }

    if(sessions !== null && sessions.length === 0 && !loading){
        return (
            <p>No Sessions</p>
        )
    }

    return(
        <div className='pt-2'>

            <a className={`d-block mb-1 px-3 py-1 position-relative session-sidebar-link ${currentStatus === 'all'? ' session-sidebar-link--active':''}`} href="#" onClick={e=> showAll(e)}> 
                <span className='status-menu'>All</span>
            </a>

            {(sessionStatuses !==null && sessions !==null) ? (

                sessionStatuses.map(status => {
                    let count = sessions.filter(session => session.status === status ).length;
                    return(
                        <a className={`d-block px-3 session-sidebar-link  ${currentStatus === status ? ' session-sidebar-link--active':''}`}  href="#" key={status} onClick={e=> onClick(e,count,status)}> 
                            <div className='position-relative'>
                                <small className='status-menu'>
                                    {STATUS[status]}
                                </small>
                                <span className='position-absolute end-0'>
                                    {count > 0 && count}
                                </span>
                            </div>
                        </a>
                        )
                    }
                )
                ):
                <div>Loading</div>
            }
            <div className='mt-2 mb-1'>Feedback:</div>
            {(feedbackStatuses !==null && sessions !==null) ? (

                feedbackStatuses.map(status => {
                    let count = sessions.filter(session => session.status === status ).length;
                    return(
                    <a className={`d-block px-3 py-11 session-sidebar-link  ${currentStatus === status ? ' session-sidebar-link--active':''}`}  href="#" key={status} onClick={e=> onClick(e,count,status)}> 
                        <div className='position-relative'>
                            <small className='status-menu'>
                                {STATUS[status]}
                            </small>
                            <span className='position-absolute end-0'>
                                {count > 0 && count}
                            </span>
                        </div>
                    </a>)
                    }
                )    
                ):
                <div>Loading</div>
            }
        </div>
    )
}

export default SessionSidebar