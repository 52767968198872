import React,{useContext, useEffect, useState} from 'react';
import { Container, Button, Form,Row, Col} from "react-bootstrap";
import {useParams, useNavigate } from "react-router-dom";
import AlertContext from '../../context/alert/alertContext';


import {requestRecoveryEmail} from '../../utils/recoveryHelper';

const RecoveryEmailForm = ({setEmailSuccess}) =>{


    const alertContext = useContext(AlertContext);

    const {setAlert} = alertContext;


    const [email, setEmail] = useState('');

    const onChange = e => setEmail( e.target.value);

    const onSubmit = e =>{
        e.preventDefault();
        if(email === '' ){
            setAlert('Please enter your Email address','danger');
        }else if(!validateEmail(email)){
            setAlert('Please enter a valid Email address.','danger');
        }
        else{

         //   requestRecoveryEmail(email);
            setEmailSuccess(true);
        }
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-2">
                        <Form.Label> Enter your Email Address</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3">                                
                        <Form.Label  htmlFor='email' hidden>Email Address</Form.Label>
                        <Form.Control type='text' name='email' values={email} onChange={onChange} className="login-input p-2" placeholder='Email'/>
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group >
                <Button variant="primary" type="submit" className="w-100 btn btn-yellow login-button">Log in</Button>
            </Form.Group>
        </Form>
    )
}



export default RecoveryEmailForm;