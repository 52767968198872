import axios from 'axios';
import setAuthToken from './utils/setAuthToken';
import AuthState from './context/auth/AuthState';
import SessionState from './context/session/SessionState';
import ActivityState from './context/activity/ActivityState';
import AlertState from './context/alert/AlertState';

import UserState from './context/user/UserState';
import OrganisationState from './context/organisation/OrganisationState';
import NotificationState from './context/notification/NotificationState';
import FileState from './context/file/FileState';
import VideoState from './context/video/VideoState';
import TeamState from './context/team/TeamState';
import TemplateState from './context/template/TemplateState';

import ServiceState from './context/service/ServiceState';

import BookingState from './context/booking/BookingState';
import BookingSessionState from './context/bookingSession/BookingSessionState';

import 'bootstrap/dist/css/bootstrap.min.css';
import './app.scss';
import './styles/typography.scss';
import './styles/print.scss';

import PortalRoutes from './components/layout/PortalRoutes';

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-K5RNCHJ6',
    dataLayerName: 'dataLayer'
}

if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1"){
  //window.dataLayer = window.dataLayer || [];
  TagManager.initialize(tagManagerArgs);
}

if(localStorage.token){
  setAuthToken(localStorage.token);
}

axios.defaults.baseURL = 'https://portal-server-mlwoy.kinsta.app';
//axios.defaults.baseURL = 'http://localhost:5000';

const App = () => 

<AuthState>
  <SessionState>
    <ActivityState>
      <AlertState>
        <UserState>
          <OrganisationState>
            <NotificationState>
              <FileState>
                <VideoState>
                  <TeamState>
                    <TemplateState>
                      <ServiceState>
                        <BookingState>
                          <BookingSessionState>
                            <PortalRoutes/>                        
                          </BookingSessionState>
                        </BookingState>
                      </ServiceState>
                    </TemplateState>
                  </TeamState>
                </VideoState>
              </FileState>
            </NotificationState>
          </OrganisationState>
        </UserState>
      </AlertState>
    </ActivityState>
  </SessionState>
</AuthState>

export default App;
