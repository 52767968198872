import React, {useContext,useState,useEffect} from 'react';
import SessionContext from '../../context/session/sessionContext';
import AlertContext from '../../context/alert/alertContext';
import {Button,Form,Row, Col} from "react-bootstrap";

const SessionApproveForm = ({isFeedback}) => {

    const alertContext = useContext(AlertContext);
    const {setAlert} = alertContext;
    const sessionContext = useContext(SessionContext);

    const {current, updateSessionAndEmail, error} = sessionContext;

    const [session, setSession] = useState({
        status: ''
     })

     const [submitted,setSubmitted] = useState(false);

     useEffect( () =>{
        if(current){
            setSession({
                ...current,
                status: isFeedback ? 'feedbackApproved':'approved'
            });
        }
    },[current,isFeedback])

    useEffect( () =>{
        let msg = isFeedback ? 'Feedback Approved':'Session Approved';
        if(submitted && !error){           
            setAlert(msg, 'success');
        }
        
    },[submitted])

    const onSubmit = e =>{
        e.preventDefault();
        updateSessionAndEmail(session);
        setSubmitted(true);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col className='d-flex justify-content-end'>
                    <Button variant="primary" type="submit" className='btn btn-yellow'>
                        {isFeedback ? 'Approve Feedback':'Approve Session'}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default SessionApproveForm;