import React, {useContext, useEffect, useState} from 'react';
import { Button, Form,Row, Col} from "react-bootstrap";
import {useParams, useNavigate, Link } from "react-router-dom";
import BookingContext from '../../context/booking/bookingContext';
import moment from "moment";

import Multiselect from 'multiselect-react-dropdown';
import UserContext from '../../context/user/userContext';
import SessionContext from '../../context/session/sessionContext';

const BookingForm = ({isAdmin, newBooking}) => {

    const params = useParams();

    const bookingContext = useContext(BookingContext);

    const {currentBooking, bookings, getBooking, addBooking, updateBooking} = bookingContext;


   
    const userContext = useContext(UserContext);
    const sessionContext = useContext(SessionContext);    

    const [delegatesList, setDelegatesList] = useState([]);
    const {getUsers, users} = userContext;


    const [bookingData, setBookingData] = useState({
        title:'',
        sessionSlotsLimit: 3,
        delegateSelectionLimit: 3,
        delegateLowerSelectionLimit: 1,
        startDate: Date.now(),
        locked: false,
        delegates: []
    });

    const {title, sessionSlotsLimit, delegateSelectionLimit, startDate, locked, delegates, delegateLowerSelectionLimit} = bookingData || {};


    useEffect( () =>{

        if(users===null || !users.length){
            getUsers();
        }

        if(newBooking) return;
 
        getBooking(params.id);

        // eslint-disable-next-line
    },[])

    useEffect(() =>{
        if(!newBooking){

            if(!currentBooking) return;

            setBookingData(
                {
                    ...currentBooking,
                    startDate: moment(currentBooking.startDate).format("YYYY-MM-DD")
                }
            );
        }
        // eslint-disable-next-line
    },[currentBooking])

    useEffect( () =>{

        if(users && users.length >0){
         
            let list = users.filter(user => user.role===4);
            list.forEach(el=>{
                el.fullName= `${el.name} ${el.lastName} - ${el.email}`;
             })
            setDelegatesList(list);    
            //console.log(delegatesList);
        }

    },[users])

    useEffect( () =>{

        if(delegates && delegates.length){
          let delegates2 = delegates.map(el => ({ ...el, fullName: `${el.name} ${el.lastName} - ${el.email}`}));
          setBookingData({...bookingData, delegates:delegates2 });
        }
          
      },[currentBooking])
    

    const onSubmit = e =>{
        e.preventDefault();
        if(newBooking){
            addBooking(bookingData);
        }
        else{
            updateBooking(bookingData);
        }
       //setSubmitted(true);
    }

    const onChange = e => {
        e.preventDefault();
        setBookingData({...bookingData, [e.target.name] :e.target.value });
    }

    const onCheckboxChange = (e) =>{
        setBookingData({...bookingData, [e.target.name] : e.target.checked });
    }


    const onDelegatesSelect = (delegates)=>{
        setBookingData({...bookingData, delegates: delegates });
    }


    return (
        <>
            <Row className='pb-1 mb-5 border-bottom'>
                <div className='col-12 col-md-6'> 
                    <h2>{title ? title : 'Booking form'}</h2>
                </div>
                <div className='col-12 col-md-6 text-end align-bottom'>
                    <Link to={`/bookings/booking/${params.id}`} className="btn btn-primary d-inline-block my-1">
                        Back
                    </Link>    
                </div>
            </Row>


            <Row>
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Title" name="title" value ={title} onChange={onChange}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Maximum delegates per session</Form.Label>
                                <Form.Control type="number" placeholder="3" name="sessionSlotsLimit" value ={sessionSlotsLimit} onChange={onChange}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Min Delegate selections</Form.Label>
                                <Form.Control type="number" placeholder="3" name="delegateLowerSelectionLimit" value ={delegateLowerSelectionLimit} onChange={onChange}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Max Delegate selections</Form.Label>
                                <Form.Control type="number" placeholder="3" name="delegateSelectionLimit" value ={delegateSelectionLimit} onChange={onChange}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Start date</Form.Label>
                                <Form.Control  type="date" name="startDate" value ={startDate} onChange={onChange} />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={12}>
                                    <Form.Group  className="mb-3">
                                        <Form.Label>Delegates</Form.Label>
                                        <Multiselect
                                            options={delegatesList} // Options to display in the dropdown
                                            selectedValues={delegates} // Preselected value to persist in dropdown
                                            onSelect={onDelegatesSelect} // Function will trigger on select event
                                            onRemove={onDelegatesSelect} // Function will trigger on remove event
                                            displayValue="fullName" // Property name to display in the dropdown options // full name + email                                            
                                        />
                                    </Form.Group>
                                </Col>

                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Locked</Form.Label> 
                                    <Form.Check 
                                        type="switch"
                                        name="locked"
                                        checked={locked ?? false}
                                        value={locked ?? false}
                                        onChange = {onCheckboxChange}
                                    />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row className='my-4'>
                        <Col className='d-flex justify-content-end'>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>

            <Row>
                <Col className='d-flex justify-content-end'>
                    {params.id ?
                    <Link to={`/bookings/booking/${params.id}`} className="btn btn-primary  my-1">
                        Back
                    </Link>    
                    :
                    <Link to={`/bookings`} className="btn btn-primary  my-1">
                        Back
                    </Link>   
                    }                        
                </Col>
            </Row>
        
        </>
    )

}

export default BookingForm