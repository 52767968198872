import React,{useState} from 'react';
import PropTypes from 'prop-types';
import {Col} from "react-bootstrap";
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import OrganisationForm from './OrganisationForm';

const OrganisationItem = ({organisation, isAdmin, deleteOrganisation}) => {

    const {title, description,logo} = organisation || null;
    const [showForm, setShowForm] = useState(false);

    const toggleForm= () =>{
        setShowForm(!showForm);
    }
    const closeForm = () =>{
        setShowForm(false);
    }

    const onDelete = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        deleteOrganisation(organisation._id);
    }

    return (
        <Col xs="12" className={`pt-2 row-border ${showForm? '': ' organisation-inner--hover'}`}>
            <div className='p-2 justify-content-between d-flex row cursor-pointer' onClick={toggleForm}>
                <div className='col-12 col-md-4'>
                    {title}
                </div>
                <div className='col-12 col-md-6'>
                    <div>{description}</div>
                </div>
                <div className='col-12 col-md-2 text-end'>
                <button className='btn btn-del px-2 m-2 d-inline-block' onClick={(e) => { if (window.confirm('Are you sure you wish to delete this organisation?')) onDelete(e)}} data-tooltip-id="del-tooltip" data-tooltip-content="Delete Organisation"> Delete User</button>
                        <Tooltip id="del-tooltip" />
                    <span className={showForm ? 'chevron-down' : 'chevron-up'}></span>
                </div>
            </div>
            {showForm && (
                <div>
                    <OrganisationForm organisation={organisation} closeForm={closeForm}/>
                </div>)
            }
        </Col>
    )
}


OrganisationItem.propTypes = {
    organisation: PropTypes.object.isRequired,
}

export default OrganisationItem